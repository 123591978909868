import * as React from "react";
import { REDIRECT_URI_POSTFIX, SILENT_REDIRECT_URI_POSTFIX } from "./Constants";
import { IdentityConfigLoader } from "./IdentityConfigLoader";
import { IChildrenProps, OidcAuthenticator } from "./OidcAuthenticator";
import _ from "lodash";
import { routes } from "../routes/routes";

type AuthenticationContextType = IChildrenProps;

export const AuthenticationContext = React.createContext<AuthenticationContextType>(
  {} as AuthenticationContextType
);

interface IProps {
  children: React.ReactNode;
}

/**
 * Handles loading of auth config and creating an authenticator with this config.
 * The provides the auth functionality via a context provider.
 * @param props Just children.
 */
export function IdentityAuthProvider(props: IProps) {
  setLinkThatSigninCallbackShouldRedirectToOnValidLogin();

  return (
    <IdentityConfigLoader>
      {({ config, responseType, scope }) => (
        <OidcAuthenticator
          config={config}
          responseType={responseType}
          scope={scope}
          redirectPostfix={REDIRECT_URI_POSTFIX}
          silentRedirectPostfix={SILENT_REDIRECT_URI_POSTFIX}
        >
          {(authProps) => (
            <AuthenticationContext.Provider value={authProps}>
              {props.children}
            </AuthenticationContext.Provider>
          )}
        </OidcAuthenticator>
      )}
    </IdentityConfigLoader>
  );
}

function setLinkThatSigninCallbackShouldRedirectToOnValidLogin() {
  if (signinRedirectCallbackHandled()) {
    localStorage.removeItem("signinRedirectLink");
    return;
  }

  if (hrefIsValidExternalEditRoute()) {
    localStorage.setItem("signinRedirectLink", window.location.href);
  } else {
    localStorage.setItem("signinRedirectLink", routes.userProfile);
  }
}

function signinRedirectCallbackHandled() {
  const signinRedirectLink = localStorage.getItem("signinRedirectLink");
  return signinRedirectLink === "signedIn";
}

function hrefIsValidExternalEditRoute() {
  return true;
  /*const returnUrl = getReturnUrlParam();
  const pathName = window.location.pathname;
  return (pathName.startsWith(EXTERNAL_EDIT_ROUTE) 
      || pathName.startsWith(routes.userRegistration)
      || pathName.startsWith(routes.userProfile))
&& !_.isEmpty(returnUrl);*/
}

export function navigateToReturnUrlIfExists() {
  const returnUrl = getReturnUrlParam();
  if (!_.isEmpty(returnUrl) && !_.isNull(returnUrl)) window.location.assign(returnUrl);
}

export function getReturnUrlParam() {
  const params = new URLSearchParams(window.location.search);
  const url = params.get("returnUrl");
  return url;
}
