import React from "react";
import { BoldTypography } from "../../components/BoldTypography";
import { Box, makeStyles } from "@material-ui/core";
import { useTranslate } from "../../utils/customHooks/useTranslate";
import { SubjectsDropdown } from "../../components/Dropdowns/SubjectsDropdown";
import { InterestsDropdown } from "../../components/Dropdowns/InterestsDropdown";
import { ContentWrapper } from "../../components/ContentWrapper";
import { EXTENDED_NORMAL_WIDTH, MOBILE_WIDTH } from "../../utils/layoutHelper";
import { ColumnFlexBox } from "../../components/Boxes/ColumnFlexBox";
import { useRegistrationContext } from "../../utils/customHooks/useRegistrationContext";
export function RegistrationSubjects() {
  const { formik } = useRegistrationContext();
  const t = useTranslate();
  const classes = useStyles();

  return (
    <ContentWrapper className={classes.wrapper}>
      <ColumnFlexBox width={"100%"}>
        <BoldTypography variant={"body2"}>{t("subjectsOfInterest")}</BoldTypography>
        <Box mt={1} mb={2}>
          <SubjectsDropdown
            TextFieldProps={{ margin: "none", variant: "outlined" }}
            name={formik.propertyNames.subjects}
          />
        </Box>
      </ColumnFlexBox>
      <ColumnFlexBox width={"100%"}>
        <BoldTypography variant={"body2"}>{t("areasOfInterest")}</BoldTypography>
        <Box mt={1} mb={2}>
          <InterestsDropdown
            TextFieldProps={{ margin: "none", variant: "outlined" }}
            name={formik.propertyNames.interests}
          />
        </Box>
      </ColumnFlexBox>
    </ContentWrapper>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: EXTENDED_NORMAL_WIDTH + "px",
    [theme.breakpoints.down("xs")]: {
      width: MOBILE_WIDTH + "px",
    },
  },
}));
