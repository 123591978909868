import { makeStyles } from "@material-ui/core";
import React from "react";
import { ColumnFlexBox, ColumnFlexBoxProps } from "./Boxes/ColumnFlexBox";
import clsx from "clsx";
import { IncludeWhen } from "../utils/generics";
import { Formik, FormikConfig, FormikProps } from "formik";

export type ContentWrapperFlexBoxProps = Omit<ColumnFlexBoxProps, "onSubmit">;

export type ContentWrapperProps<
  HasFormik extends boolean | undefined,
  FormikValues extends object
> = ContentWrapperFlexBoxProps & {
  hasFormik?: HasFormik;
} & IncludeWhen<
    HasFormik,
    true,
    {
      FormikProps?: Partial<FormikProps<FormikValues>>;
      onSubmit?: FormikConfig<FormikValues>["onSubmit"];
    } & Omit<FormikConfig<FormikValues>, "onSubmit">
  >;

export function ContentWrapper<Editable extends boolean | undefined, FormikValues extends object>(
  props: ContentWrapperProps<Editable, FormikValues>
) {
  const classes = useStyles();
  const { hasFormik, initialValues, children, FormikProps, onSubmit, ...columnFlexBoxProps } =
    props as unknown as ContentWrapperProps<true, FormikValues>;

  return (
    <ColumnFlexBox
      alignItems="center"
      {...columnFlexBoxProps}
      className={clsx(classes.content, props.className)}
    >
      {hasFormik ? (
        <Formik {...FormikProps} initialValues={initialValues} onSubmit={onSubmit || emptyFn}>
          {(formik) =>
            typeof props.children === "function"
              ? (props.children as Function)(formik)
              : props.children
          }
        </Formik>
      ) : (
        props.children
      )}
    </ColumnFlexBox>
  );
}

function emptyFn() {}

//#region styles
const useStyles = makeStyles((theme) => ({
  content: {
    width: "670px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "670px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));
//#endregion
