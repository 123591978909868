import * as React from "react";
import FileSaver from "file-saver";
import { AuthenticationContext } from "../auth";
import { UIStateContext } from "./UIContext";
import { useSnackbar } from "notistack";

export interface IHttpContextValue {
  getJSON: (url: string) => Promise<any>;
  getFile: (url: string, fileName: string) => Promise<any>;
  postJSON: (url: string, data: any) => Promise<any>;
  putJSON: (url: string, data: any) => Promise<any>;
  deleteJSON: (url: string) => Promise<any>;
}

export const HttpContext = React.createContext<IHttpContextValue>({} as any);

interface IHttpProviderProps {
  children?: React.ReactNode;
}

interface IHttp400Response {
  response: Response;
  json: any;
}

export type PostHelperResponse = undefined | IHttp400Response;

export function HttpProvider(props: IHttpProviderProps) {
  const auth = React.useContext(AuthenticationContext);
  const { currentCulture } = React.useContext(UIStateContext);
  const { enqueueSnackbar } = useSnackbar();

  const lang = currentCulture;

  function getAuthorizationHeader(): Promise<{
    id_token: string;
    access_token: string;
  }> {
    return auth
      ? auth.getToken()
      : Promise.resolve({ id_token: "", access_token: "" });
  }

  function createCommonHeaders(): any {
    return {
      "Accept-Language": lang,
    };
  }

  function createBearerHeader(token: string): any {
    return {
      Authorization: `Bearer ${token}`,
    };
  }

  async function getJSON(url: string) {
    const token = await getAuthorizationHeader();
    const response = await fetch(url, {
      headers: {
        ...createCommonHeaders(),
        ...createBearerHeader(token.id_token),
        "X-Accesstoken": token.access_token,
      },
    });

    if (!response.ok) {
      let result = await response.json();
      enqueueSnackbar(result.error, { variant: "error" });
      throw Error(response.statusText);
    } else {
      if (
        (response.headers.get("Content-Type") || "").indexOf(
          "application/json"
        ) === 0
      ) {
        return await response.json();
      }
    }
    return undefined;
  }

  async function deleteJSON(url: string) {
    const token = await getAuthorizationHeader();
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        ...createBearerHeader(token.id_token),
        "X-Accesstoken": token.access_token,
      },
    });

    let result;

    if (
      (response.headers.get("Content-Type") || "").indexOf(
        "application/json"
      ) === 0
    ) {
      result = await (await response).json();
    }

    if (response.ok) {
      return result;
    }
    enqueueSnackbar(result.statusText, { variant: "error" });
    throw new Error(result);
  }

  async function getFile(url: string, fileName: string) {
    const token = await getAuthorizationHeader();
    const response = await fetch(url, {
      headers: {
        ...createCommonHeaders(),
        ...createBearerHeader(token.id_token),
        "X-Accesstoken": token.access_token,
        Pragma: "no-cache",
        "Cache-Control": "no-store",
      },
    });
    if (response.status !== 200) {
      throw await response.json();
    }
    const blob = await response.blob();
    FileSaver.saveAs(blob, fileName);
  }

  function postJSON(url: string, data: any) {
    return postHelper("POST", url, data);
  }

  function putJSON(url: string, data: any) {
    return postHelper("PUT", url, data);
  }

  async function postHelper(
    verb: "PUT" | "POST",
    url: string,
    data: any
  ): Promise<PostHelperResponse> {
    const token = await getAuthorizationHeader();
    const response = await fetch(url, {
      method: verb,
      headers: {
        "Content-Type": "application/json",
        ...createCommonHeaders(),
        ...createBearerHeader(token.id_token),
        "X-Accesstoken": token.access_token,
      },
      body: JSON.stringify(data),
    });

    let result;

    if (
      (response.headers.get("Content-Type") || "").indexOf(
        "application/json"
      ) === 0
    ) {
      result = await (await response).json();
    }

    if (response.ok) {
      return result;
    }

    if (result.error) {
      enqueueSnackbar(result.error.join(", "), {
        variant: "error",
      });
    } else if (!result) {
      enqueueSnackbar("Unbekannter Fehler", {
        variant: "error",
      });
    }

    // eslint-disable-next-line
    throw { response, json: result };
  }

  return (
    <HttpContext.Provider
      value={{
        getJSON,
        postJSON,
        putJSON,
        getFile,
        deleteJSON,
      }}
    >
      {props.children}
    </HttpContext.Provider>
  );
}
