import React from "react";
import clsx from "clsx";
import { BoxProps, makeStyles } from "@material-ui/core";
import _ from "lodash";
import { FlexBox } from "./Boxes/FlexBox";

export type ContentBoxProps = BoxProps & {
  responsiveBackgroundColor?: boolean;
};

export function ContentBox(props: ContentBoxProps) {
  const classes = useStyles();

  const responsiveBackgroundColor = _.defaultTo(props.responsiveBackgroundColor, true);

  return (
    <FlexBox
      p={2}
      borderRadius={"8px"}
      width={"100%"}
      boxSizing={"border-box"}
      flexDirection={"column"}
      {...props}
      className={clsx(
        responsiveBackgroundColor ? classes.responsiveBackgroundColor : "",
        props.className
      )}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  responsiveBackgroundColor: {
    background: theme.palette.background.default,

    [theme.breakpoints.down("xs")]: {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));
