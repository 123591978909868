import { MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import React, { ComponentProps, useRef, useState } from "react";

type ButtonProps = Omit<
  ComponentProps<typeof Button>,
  "aria-controls" | "ref" | "onClick" | "title"
>;
type MenuProps = Omit<
  ComponentProps<typeof Menu>,
  "id" | "anchorEl" | "keepMounted" | "open" | "onClose"
>;

interface Props {
  buttonProps?: ButtonProps;
  menuProps?: MenuProps;
  title: React.ReactNode;
  items: React.ReactNode[];
}

const ButtonWithMenu = (props: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <Button
        aria-controls={buttonRef.current?.id}
        ref={buttonRef}
        onClick={() => setShowMenu(true)}
        {...props.buttonProps}
      >
        {props.title}
      </Button>
      <Menu
        id={buttonRef.current?.id}
        anchorEl={buttonRef.current}
        keepMounted
        open={showMenu}
        onClose={() => setShowMenu(false)}
        {...props.menuProps}
      >
        {props.items.map((item) => (
          <MenuItem
            onClick={() => {
              setShowMenu(false);
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ButtonWithMenu;
