import React from "react";
import { makeStyles } from "@material-ui/core";
import { ColumnFlexBox, ColumnFlexBoxProps } from "../../../components/Boxes/ColumnFlexBox";
import { ResponsesInstituteResponse } from "../../../api";
import { BoldTypography } from "../../../components/BoldTypography";

type InstituteWidgetTitleProps = ColumnFlexBoxProps & {
  institute: ResponsesInstituteResponse;
};

export function InstituteWidgetTitle(props: InstituteWidgetTitleProps) {
  const classes = useStyles();
  return (
    <ColumnFlexBox {...props}>
      <BoldTypography variant={"h3"} className={classes.title}>
        {props.institute.fullname}
      </BoldTypography>
    </ColumnFlexBox>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    overflowWrap: "break-word",
  },
}));
