import React from "react";
import { Box, BoxProps, Typography } from "@material-ui/core";
import { ResponsesInstituteResponse } from "../../../api";

type MainAddressProps = BoxProps & {
  institute: ResponsesInstituteResponse;
};

export function InstituteWidgetMainAddress(props: MainAddressProps) {
  return (
    <Box {...props}>
      <Typography>{props.institute.mainAddress?.street}</Typography>
      <Typography>
        {props.institute.mainAddress?.zip} {props.institute.mainAddress?.city}
      </Typography>
    </Box>
  );
}
