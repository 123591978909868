import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslate } from "../../../utils/customHooks/useTranslate";
import { AuthenticationContext } from "../../../auth";

export function UserPassword() {
  const t = useTranslate();
  const { authority } = React.useContext(AuthenticationContext);

  const changePwdUrl = new URL("Identity/Account/Manage/ChangePassword", authority);
  changePwdUrl.searchParams.append("returnUrl", window.location.href);

  return (
    <>
      {/* <PlainFieldText label={t("password")} value={PASSWORD_PLACEHOLDER} />*/}
      <a href={changePwdUrl.href}>
        <Typography>{t("changePassword")}</Typography>
      </a>
    </>
  );
}
