import { TranslationId } from "../../../utils/translationIds";
import { GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponseSettingsResponseEntity } from "../../../api";
import { useTranslate } from "../../../utils/customHooks/useTranslate";
import { Box, BoxProps } from "@material-ui/core";
import { BoldTypography } from "../../../components/BoldTypography";
import { CustomChip } from "../../../components/CustomChip";
import React from "react";
import {ColumnFlexBox} from "../../../components/Boxes/ColumnFlexBox";

type MultiSelectionSettingDisplayProps = Omit<BoxProps, "children"> & {
  translationKey: TranslationId;
  settingsResponseEntity:
    | GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponseSettingsResponseEntity[]
    | undefined
    | null;
};

export function MultiSelectionSettingDisplay(props: MultiSelectionSettingDisplayProps) {
  const t = useTranslate();

  return (
    <ColumnFlexBox {...props} mb={2}>
      <BoldTypography variant={"body2"}>{t(props.translationKey)}</BoldTypography>
      {props.settingsResponseEntity && (
        <Box mb={2}>
          {props.settingsResponseEntity.map((s) => (
            <CustomChip key={s.id} defaultValue={s.id} label={s.description} />
          ))}
        </Box>
      )}
    </ColumnFlexBox>
  );
}
