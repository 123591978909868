import React from "react";
import { PageWrapper } from "../../../components/PageWrapper";
import { ActionType, AddressForm } from "../../../features/profile/address/AddressForm";
import { useTranslate } from "../../../utils/customHooks/useTranslate";

export function EditAddressPage() {
  const t = useTranslate();

  return (
    <PageWrapper title={t("updateAddress")} hideBackButton>
      <AddressForm type={ActionType.Edit} />
    </PageWrapper>
  );
}
