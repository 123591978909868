import React from "react";
import { Option } from "../../../components";
import { TranslationId } from "../../../utils/translationIds";
import { useFormikContext } from "formik";
import { useTranslate } from "../../../utils/customHooks/useTranslate";
import { usePumaContextApi } from "../../../api";
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";
import { Box, TextField } from "@material-ui/core";

interface FunctionsAtSchool {
  generalFunctions: Option<number>[];
  subjectCoordinatorFunctions: Option<number>[];
  studentTeacherFunction: Option<number>;
}

export const FunctionsAtSchoolDropdown: React.FC<{
  options: Option<number>[];
  formikValueKey: keyof FunctionsAtSchool;
  label: TranslationId;
}> = ({ options, formikValueKey, label }) => {
  const formik = useFormikContext<FunctionsAtSchool>();
  const t = useTranslate();
  const pumaApi = usePumaContextApi();

  const formikValue = formik.values[formikValueKey];

  const isMultiple = (value: Option<number> | Option<number>[]): value is Option<number>[] =>
    Array.isArray(value);

  return (
    <Box my={2}>
      <Autocomplete
        options={options}
        value={formikValue}
        onChange={async (_, value, reason) => {
          const currentState = Array.isArray(formikValue) ? [...formikValue] : [{ ...formikValue }];
          const newState = Array.isArray(value) ? [...value] : [{ ...value }];

          formik.setFieldValue(formikValueKey, value);

          if (reason === "select-option") {
            const { value: newValue } = newState.find(
              (v) => !currentState.some(({ key }) => key === v.key)
            )!;
            await pumaApi.portalUsers.portalUsersAddTraitCreate(newValue);
            if (!isMultiple(formikValue) && currentState.some((it) => it.value)) {
              await pumaApi.portalUsers.portalUsersRemoveTraitsDelete({
                traitIds: currentState.filter((it) => it.value).map((it) => it.value),
              });
            }
          } else {
            const removedValues = currentState.filter(
              (v) => !newState.some(({ key }) => key === v.key)
            )!;
            await pumaApi.portalUsers.portalUsersRemoveTraitsDelete({
              traitIds: removedValues.map((x) => x.value),
            });
          }
        }}
        multiple={isMultiple(formikValue)}
        renderInput={(params) => renderInput(params, t(label))}
        getOptionLabel={(it) => it.displayValue}
        getOptionSelected={(value) =>
          formik.values.generalFunctions.some(({ key }) => key === value.key)
        }
      />
    </Box>
  );

  function renderInput(params: AutocompleteRenderInputParams, label: string | null) {
    return <TextField {...params} label={label} variant="outlined" />;
  }
};
