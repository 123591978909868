import React from "react";
import { TraitGroup, TraitsDropdown, TraitsDropdownProps } from "./TraitsDropdown";
import { useTranslate } from "../../utils/customHooks/useTranslate";

type SubjectsDropdownProps = Omit<TraitsDropdownProps, "label" | "traitGroups">;

export function SubjectsDropdown(props: SubjectsDropdownProps) {
  const t = useTranslate();
  return (
    <TraitsDropdown
      {...props}
      label={t("subjectsOfInterest")}
      multiple
      traitGroup={TraitGroup.Subjects}
      fullWidth
      disableClearable
    />
  );
}
