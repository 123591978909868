import React from "react";
import { useTranslate } from "../../utils/customHooks/useTranslate";
import { ActionType, AddressForm } from "../profile/address/AddressForm";
import { PageWrapper } from "../../components/PageWrapper";
import { navigateToReturnUrlIfExists } from "../../auth";
import { Button } from "../../components/Button";

export function ExternalModifyAddressPage() {
  const t = useTranslate();
  return (
    <PageWrapper title={t("updateAddress")}>
      <>
        <AddressForm type={ActionType.Edit} onSuccess={navigateToReturnUrlIfExists} />
        <Button
          buttonText={t("cancel")}
          variant="text"
          onClick={() => navigateToReturnUrlIfExists()}
          style={{ width: "auto" }}
        />
      </>
    </PageWrapper>
  );
}
