import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { FlexBox, FlexBoxProps } from "../../../../components/Boxes/FlexBox";
import { BoldTypography } from "../../../../components/BoldTypography";
import { ColumnFlexBox } from "../../../../components/Boxes/ColumnFlexBox";
import { EXTENDED_NORMAL_WIDTH, MOBILE_WIDTH } from "../../../../utils/layoutHelper";
import { useTranslate } from "../../../../utils/customHooks/useTranslate";
import { CustomIcon } from "../../../../components/icons/CustomIcon";

interface Props extends FlexBoxProps {}

export function NoAssignedSchoolError(props: Props) {
  const t = useTranslate();
  const classes = useStyles();

  return (
    <FlexBox {...props} className={clsx(classes.mainWrapper, props.className)}>
      <Box className={classes.warningNormal}>
        <CustomIcon name="Icon_Warning" height={50} width={50} />
      </Box>
      <ColumnFlexBox className={classes.textWrapper}>
        <BoldTypography variant="h2" color="error" align="center">
          {t("noSchoolLinkedYet")}
        </BoldTypography>
        <Typography color="error" align="center">
          {t("pleaseLinkSchoolToProfileForAccessOfTheSchoolContent")}
        </Typography>
        <BoldTypography color="error" align="center">
          {t("addSchoolWithSchoolCodeAndCredentials")}
        </BoldTypography>
      </ColumnFlexBox>
      <Box className={classes.warningNormal}>
        <CustomIcon name="Icon_Warning" height={50} width={50} />
      </Box>
    </FlexBox>
  );
}

//#region styles
const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    gap: theme.spacing(2),
  },
  textWrapper: {
    width: EXTENDED_NORMAL_WIDTH + "px",
    gap: theme.spacing(),
    [theme.breakpoints.down("xs")]: {
      width: MOBILE_WIDTH + "px",
    },
  },
  warningNormal: {
    fontSize: "60px",
    color: theme.palette.error.dark,
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

//#endregion
