import React from "react";
import { Route, RouteComponentProps, RouteProps } from "react-router-dom";
import { Loading } from "../components";
import { AuthenticationContext } from "./IdentityAuthProvider";

export function PrivateRoute({ component, render, ...rest }: RouteProps) {
  const { isAuthenticated, login } = React.useContext(AuthenticationContext);
  const createRender = (Component?: any, renderMe?: any) => (props: RouteComponentProps) => {
    if (isAuthenticated) {
      if (Component != null) {
        return <Component {...props} />;
      } else if (renderMe != null) {
        return renderMe(props);
      }
    } else {
      login();
      return <Loading msg="for route" />;
    }
  };
  return <Route {...rest} render={createRender(component, render)} />;
}
