import * as React from "react";

interface IServices {
  alert: (msg: string) => void;
}

interface IServiceProviderProps {
  children: React.ReactNode;
  value: IServices;
}

const ServiceContext = React.createContext<IServices>({} as IServices);

export function ServiceProvider(props: IServiceProviderProps) {
  const { children, value } = props;
  return (
    <ServiceContext.Provider value={value}>{children}</ServiceContext.Provider>
  );
}

export { ServiceContext };
