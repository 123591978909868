import React, { useMemo } from "react";
import { ColumnFlexBox, ColumnFlexBoxProps } from "../../../components/Boxes/ColumnFlexBox";
import { MOBILE_WIDTH } from "../../../utils/layoutHelper";
import { MyOrdersButton } from "./MyOrdersButton";
import { MySettingsAndNewsletterButton } from "./MySettingsAndNewsletterButton";
import { MyWishlistButton } from "./MyWishlistButton";
import { assertUnreachable } from "../../../utils";

export enum NavigationButton {
  Orders,
  Wishlist,
  SettingsAndNewsletter,
}

type NavigationButtonsProps = ColumnFlexBoxProps & {
  types: NavigationButton[];
};

export function NavigationButtons(props: NavigationButtonsProps) {
  const buttons = useMemo(() => props.types.map(mapNavigationButton), [props.types]);
  return (
    <ColumnFlexBox
      {...props}
      makeStyles={(theme) => ({
        width: MOBILE_WIDTH + "px",
        columnGap: theme.spacing(2),
        gap: theme.spacing(2),
      })}
    >
      {buttons}
    </ColumnFlexBox>
  );
}

function mapNavigationButton(type: NavigationButton) {
  switch (type) {
    case NavigationButton.Orders:
      return <MyOrdersButton />;
    case NavigationButton.SettingsAndNewsletter:
      return <MySettingsAndNewsletterButton />;
    case NavigationButton.Wishlist:
      return <MyWishlistButton />;
    default:
      assertUnreachable(type);
  }
}
