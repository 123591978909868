import React from "react";
import { ContentWrapper, ContentWrapperFlexBoxProps } from "../../../components/ContentWrapper";
import { Typography } from "@material-ui/core";
import { FlexBox } from "../../../components/Boxes/FlexBox";
import { CustomIcon } from "../../../components/icons/CustomIcon";
import { useTranslate } from "../../../utils/customHooks/useTranslate";
import { ContentBox } from "../../../components/ContentBox";
import { useCustomHistory } from "../../../utils/customHooks/useCustomHistory";
import { routes } from "../../../routes/routes";
import { GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponseSettingsResponseEntity } from "../../../api";
import { MultiSelectionSettingDisplay } from "./MultiSelectionSettingDisplay";
import { SettingDisplay } from "./SettingDisplay";
import { PortalUserContext } from "../../PortalUserProvider";
import { UserType } from "../../../utils/beEnumMapping";

type MySettingsDisplayProps = Omit<ContentWrapperFlexBoxProps, "children"> & {
  subjects:
    | GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponseSettingsResponseEntity[]
    | undefined
    | null;
  generalFunctions:
    | GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponseSettingsResponseEntity[]
    | undefined
    | null;
  subjectCoordinatorFunctions:
    | GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponseSettingsResponseEntity[]
    | undefined
    | null;
  studentTeacherFunction:
    | GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponseSettingsResponseEntity
    | undefined;
  categories:
    | GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponseSettingsResponseEntity[]
    | undefined
    | null;
  occupationTitle:
    | GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponseSettingsResponseEntity
    | undefined
    | null;
};

export function MySettingsDisplay(props: MySettingsDisplayProps) {
  const t = useTranslate();
  const history = useCustomHistory();
  const { portalUser } = React.useContext(PortalUserContext);

  return (
    <ContentWrapper {...props}>
      <FlexBox justifyContent="space-between" mb={2} width={"100%"}>
        <Typography variant={"h3"}>{t("mySubjectsAndInterests")}</Typography>
        <CustomIcon
          name="Icon_Bearbeiten"
          onClick={() => history.navigate(routes.userProfileSettingsAndNewslettersEdit)}
        />
      </FlexBox>
      <ContentBox>
        {portalUser.type === UserType.Teacher && (
          <>
            <SettingDisplay translationKey={"occupationTitle"} setting={props.occupationTitle} />
            <MultiSelectionSettingDisplay
              translationKey={"generalFunctionsAtTheSchool"}
              settingsResponseEntity={props.generalFunctions}
            />
            <MultiSelectionSettingDisplay
              translationKey={"subjectCoordinator"}
              settingsResponseEntity={props.subjectCoordinatorFunctions}
            />
            <SettingDisplay
              translationKey={"studentTeacher"}
              setting={props.studentTeacherFunction}
            />
          </>
        )}
        {portalUser.type !== UserType.Student && (
          <>
            <MultiSelectionSettingDisplay
              translationKey={"subjectsOfInterest"}
              settingsResponseEntity={props.subjects}
            />
            <MultiSelectionSettingDisplay
              translationKey={"areasOfInterest"}
              settingsResponseEntity={props.categories}
            />
          </>
        )}
      </ContentBox>
    </ContentWrapper>
  );
}
