export enum UserType {
  Teacher,
  KindergartenTeacher,
  Student,
  Other,
}

export enum EducationalInstituteType {
  Kindergarten = 101,
  AdultEducation = 115,
}

export enum CustomerTypes {
  KindergartenEducationCode = "13",
  AdultEducationCode = "126",
}

export enum EducationalInstituteCountry {
  Austria,
  Germany,
  Switzerland,
  OtherEU,
  NotEU,
}
