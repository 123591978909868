import React from "react";
import { useAsync } from "react-async-hook";
import { usePumaContextApi } from "../api";
import { Loading } from "../components";
import { AuthenticationContext, AuthError } from ".";

export interface IAuthorization {
  cultures: string[];
}
export const AuthorizationContext = React.createContext<IAuthorization>({
  cultures: [],
});

interface IProps {
  children: React.ReactElement;
}

export function AuthorizationProvider(props: IProps) {
  const api = usePumaContextApi();
  const { login, user } = React.useContext(AuthenticationContext);

  const userInfo = useAsync(() => api.iam.iamUsersVerifyList(), []);

  if (userInfo.loading) {
    return <Loading msg={user?.profile.name || ""} />;
  } else if (userInfo.error) {
    window.console.error(userInfo.error);
    return (
      <AuthError
        title="Benutzer hat keine Berechtigung für Verona."
        details="Es kann sein, dass Ihr Benutzer noch nicht aktiviert, oder deaktiviert ist. Bitte melden Sie sich beim Service Desk."
        onRetry={() => login()}
      />
    );
  } else if (userInfo.result != null) {
    return (
      <AuthorizationContext.Provider value={{ cultures: userInfo.result.data?.cultures || [] }}>
        {props.children}
      </AuthorizationContext.Provider>
    );
  } else {
    return <strong>Fehler beim laden der Authorisierungs Infos.</strong>;
  }
}
