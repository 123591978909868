import _ from "lodash";
import { mapPropertyNamesToValuesDeep } from "./generics";

export function getTypedObjectKeys<objectInterface, keys = keyof objectInterface>(
  object: objectInterface
): keys[] {
  //@ts-ignore
  const keys: keys[] = Object.keys(object);
  return keys;
}

export function getPropertyNames<T extends object>(
  obj: T,
  objectName?: Extract<keyof T, string>
): mapPropertyNamesToValuesDeep<T> {
  const response: mapPropertyNamesToValuesDeep<T> = _.cloneDeep(
    obj
  ) as mapPropertyNamesToValuesDeep<T>;
  const keys = getTypedObjectKeys(response);
  keys.forEach((key) => {
    const propertyName = objectName === undefined ? key : `${objectName}.${key}`;
    if (!_.isPlainObject(obj[key])) {
      //@ts-ignore
      response[key] = propertyName;
      return;
    }
    //@ts-ignore
    response[key] = {
      objectName: propertyName,
      //@ts-ignore
      ...getPropertyNames(response[key], propertyName),
    };
  });
  return response;
}

export function shallowMap<base extends object, T extends base>(
  resultTemplate: base,
  input: T
): base {
  let result: base = Object.create(resultTemplate);
  const keys = getTypedObjectKeys(resultTemplate);
  keys.forEach((key) => (result[key] = input[key]));
  return result;
}

export function hasValues(obj: object | undefined) {
  return Object.values(obj || {}).some((v: any) => {
    return !_.isNil(v) && (!_.isEmpty(v) || _.isNumber(v) || _.isBoolean(v));
  });
}

export type nameof<T, Q extends keyof T> = Q;
