import {Box, makeStyles} from "@material-ui/core";
import React from "react";
import {AuthenticationContext, getReturnUrlParam} from "../../auth";
import {Button} from "../../components/Button";
import {ContentWrapper} from "../../components/ContentWrapper";
import {CustomIcon} from "../../components/icons/CustomIcon";
import {OutlinedButton} from "../../components/OutlinedButton";
import {PageWrapper} from "../../components/PageWrapper";
import {UserBasicInfoWidget} from "../../features/profile/basicInfo/UserBasicInfoWidget";
import {routes} from "../../routes/routes";
import {useCustomHistory} from "../../utils/customHooks/useCustomHistory";
import {useTranslate} from "../../utils/customHooks/useTranslate";
import {EXTENDED_NORMAL_WIDTH, MOBILE_WIDTH} from "../../utils/layoutHelper";
import {useProfileHelper} from "../../features/profile/profileTypes/useProfileHelper";
import {PortalInfos} from "../../components/PortalInfos";

const PROFILE_RETURN_URL_KEY = "profileReturnUrl";

export function ProfileHomePage() {
    const t = useTranslate();
    const classes = useStyles();

    const {content} = useProfileHelper();
    const history = useCustomHistory();

    const {logout} = React.useContext(AuthenticationContext);

    function getSessionProfileReturnUrl() {
        return window.sessionStorage.getItem(PROFILE_RETURN_URL_KEY)
    }

    function setSessionProfileReturnUrl(url: string) {
        window.sessionStorage.setItem(PROFILE_RETURN_URL_KEY, url)
    }

    function removeSessionProfileReturnUrl() {
        window.sessionStorage.removeItem(PROFILE_RETURN_URL_KEY);
    }

    if (getReturnUrlParam() != null) {
        setSessionProfileReturnUrl(getReturnUrlParam()!);
    }

    const hasReturnUrl = getSessionProfileReturnUrl() != null;

    function backToPortal() {
        const returnUrl = getSessionProfileReturnUrl();
        if (returnUrl != null) {
            removeSessionProfileReturnUrl();
            window.location.assign(returnUrl);
        }
    }

    return (
        <PageWrapper title={t("myProfile")} hideBackButton>
            { !hasReturnUrl && <PortalInfos /> }
            <ContentWrapper className={classes.basicInfoWrapper}>
                <Box mt={2} width={"100%"}>
                    <UserBasicInfoWidget mb={2}/>
                    <OutlinedButton
                        buttonText={t("dataAndAddresses")}
                        onClick={() => history.navigate(routes.userProfileData)}
                    />
                </Box>
                {content}
                <Box className={classes.buttonGroupWrapper}>
                    {hasReturnUrl
                        ? <Button buttonText={t("backToPortal")} onClick={backToPortal}/>
                        : <Button buttonText={t("logout")} onClick={logout}/>}
                </Box>
            </ContentWrapper>
            <Box className={classes.icon}>
                <CustomIcon name="Icon_Profil_2" height={200} width={200}/>
            </Box>
        </PageWrapper>
    );
}

//#region styles
const useStyles = makeStyles((theme) => ({
    basicInfoWrapper: {
        width: EXTENDED_NORMAL_WIDTH + "px",
        [theme.breakpoints.down("xs")]: {
            width: MOBILE_WIDTH + "px",
        },
    },
    buttonGroupWrapper: {},

    icon: {
        position: "absolute",
        bottom: 0,
        left: `calc(50% + ${EXTENDED_NORMAL_WIDTH / 2 + 150}px)`,
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    divider: {
        width: "100%",
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
}));
//#endregion
