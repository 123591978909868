import React from "react";
import { Chip, ChipProps, makeStyles } from "@material-ui/core";
import clsx from "clsx";

export function CustomChip(props: ChipProps) {
  const classes = useStyles();
  return <Chip {...props} className={clsx(classes.chip, props.className)} />;
}

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("xs")]: {
      backgroundColor: theme.palette.background.default,
    },
  },
}));
