// TODO: create a script to fetch from database
const translationIds = {
  pleaseSelectGender: "Bitte das Geschlecht auswählen.",
  unsubscribeFromNewsletterNow: "Jetzt vom Newsletter abmelden",
  newsletter: "Newsletter",
  myProfile: "Mein Profil",
  myProfileSubtitlePart1: "Ein einziger Benutzername mit Passwort zur",
  myProfileSubtitlePart2: "digitalen Welt von VERITAS (veritas.at, scook, VERA).",
  myProfilePortalSubtitleVeritas: "Einkaufen und informieren",
  myProfilePortalSubtitleScook: "E-Books und Materialien nutzen",
  myProfilePortalSubtitleVera: "Schularbeiten erstellen",
  schoolCodeInvalid: "Schulkennzahl ist ungültig",
  approvalCodeInvalid: "Berechtigungscode ist ungültig",
  verifySchool: "Schule verifizieren",
  firma: "Firma (optional)",
  zuHanden: "zu Handen (optional)",
  addition: "Zusatz",
  client: "Kund*in", // TODO: Add into translations
  pedagogue: "Pädagog*in", // TODO: Add into translations
  thisInformationCanBeChangedInTheProfileAtAnyTime:
    "Diese Informationen können jederzeit im eigenen Profil angepasst werden.", // TODO: Add into translations
  other: "Andere", // TODO: Add into translations
  linkedEducationalInstitutions: "Verknüpfte Bildungseinrichtungen", // TODO: Add into translations
  linkedSchools: "Verknüpfte Schulen", // TODO: Add into translations
  searchInstitution: "Einrichtung suchen", // TODO: Add into translations
  pleaseChoose: "Bitte auswählen", // TODO: Add into translations
  forEducatorsAtEducationalInstitutesWithoutSchoolCode:
    "Für PädagogInnen an Kindergärten und anderen Bildungseinrichtungen ohne Schulkennzahl", // TODO: Add into translations
  selectEducationalInstitution: "Bildungseinrichtung auswählen", // TODO: Add into translations
  schoolAlreadyAdded: "Schule bereits hinzugefügt",
  instituteHasNoValidApprovalCode:
    "Die Schule hat keinen gültigen Berechtigungsnachweis. Bitte wenden Sie sich an die VERITAS Kundenberatung.", // TODO: Add into translations
  youCanAlwaysCompleteYourProfile: "Sie können Ihr Profil jederzeit vervollständigen.", // TODO: Add into translations
  yourProfileIsIncomplete:
    "Ihr Profil ist noch nicht vollständig. Dadurch kann es passieren, dass nicht relevante Inhalte angezeigt werden.", // TODO: Add into translations
  completeProfileLater: "Profil später vervollständigen", // TODO: Add into translations
  addAddress: "Adresse hinzufügen", // TODO: Add into translations
  youReceiveInfoViaPost:
    "Erhalten Sie zu Ihren Profildaten passende informationen (z.B. Veranstaltunseinladungen oder Kataloge).", // TODO: Add into translations
  iWouldLikeToGetPostFromVeritas: "Ich möchte postalische Zusendungen von VERITAS erhalten.", // TODO: Add into translations
  youReceiveInfoViaEmails:
    "Erhalten Sie zu Ihren Profildaten passende Informationen per E-Mail (z.B. Informationen über Produkte, Aktionen, Services, Kundenbefragungen, Veranstaltungen und Webinare, Informationen zu VERITAS-Partnerverlagen und VERITAS-Plattformen). Die Einwilligung kann jederzeit widerrufen werden.", // TODO: Add into translations
  iWouldLikeToGetEmailsFromVeritas: "Ich möchte E-Mails von VERITAS erhalten.", // TODO: Add into translations
  yourProfileWasSuccessfullyCreated: "Ihr Profil wurde erfolgreich angelegt.", // TODO: Add into translations
  madeIt: "Geschafft!", // TODO: Add into translations
  weCanGiveYouInformationWhenYouGiveUsExperienceAndFunctions:
    "Mit der Angabe Ihrer Unterrichtserfahrung und Funktionen an der Schule können wir für Sie passende Informationen bereitstellen.", // TODO: Add into translations
  teachingExperienceAndFunctionsAtSchool: "Unterrichtserfahrung und Funktionen an der Schule", // TODO: Add into translations
  weCanGiveYouInformationWhenYouGiveUsSubjects:
    "Mit der Angabe Ihrer Fächer und Interessensgebiete können wir für Sie passende Informationen bereitstellen.", // TODO: Add into translations
  selectSubjects: "Fächer und Interessensgebiete auswählen", // TODO: Add into translations
  completeNow: "Jetzt Schule hinzufügen", // TODO: Add into translations
  completeLater: "Schule später hinzufügen", // TODO: Add into translations
  addSchoolNow: "Jetzt Schule hinzufügen", // TODO: Add into translations
  schoolsCanBeAddedAtAnyTime: "Schulen können jederzeit hinzugefügt werden!", // TODO: Add into translations
  inOrderToUseLinkSchool:
    "Um unsere Produkte und Services für Lehrer*innen nutzen zu können, muss eine Schule mit Ihrem Profil verbunden werden.",
  // TODO: Add into translations
  dontYouWantToLinkSchool: "Keine Schule mit dem Veritas-Benutzer verknüpfen?", // TODO: Add into translations
  connectSchoolInfoHeadline: "Wenn Sie eine Schule mit Ihrem Benutzer verknüpfen, können Sie", // TODO: Add into translations
  connectSchoolInfoBulletPoint1:
    "E-Books aller über die Schulbuchaktion bestellten VERITAS-Bücher nutzen,", // TODO: Add into translations
  connectSchoolInfoBulletPoint2:
    "E-Books und interaktive Übungen auf scook.at gratis kennenlernen,", // TODO: Add into translations
  connectSchoolInfoBulletPoint3:
    "Lösungen einblenden und Materialien zur exklusiven Nutzung für Lehrer:innen einsehen,", // TODO: Add into translations
  connectSchoolInfoBulletPoint4:
    "auf veritas.at Produkte zur exklusiven Nutzung für Lehrer:innen erwerben (Schularbeiten, …).", // TODO: Add into translations
  myAddresses: "Meine Adressen", // TODO: Add into translations
  iWouldLikeToReceivePostNewsletter: "Ich möchte postalische Zusendungen von VERITAS erhalten", // TODO: Add into translations
  weSendAWeeklyEmailNewsletter:
    "Erhalten Sie zu Ihren Profildaten passende Informationen per E-Mail (z.B. Informationen über Produkte, Aktionen, Services, Kundenbefragungen, Veranstaltungen und Webinare, Informationen zu VERITAS-Partnerverlagen und VERITAS-Plattformen).", // TODO: Add into translations
  weSendPostNewsletter:
    "Erhalten Sie zu Ihren Profildaten passende Informationen (z.B. Veranstaltungseinladungen oder Kataloge).", // TODO: Add into translations
  iWouldLikeToReceiveEmailNewsletter: "Ich möchte E-Mails von VERITAS erhalten", // TODO: Add into translations
  editSettings: "Einstellungen bearbeiten", // TODO: Add into translations
  areasOfInterest: "Interessengebiete", // TODO: Add into translations
  subjectsOfInterest: "Interessante Fächer", // TODO: Add into translations
  subjectCoordinationInTheSubject: "Fachkoordination in dem Fach", // TODO: Add into translations
  subjectCoordinator: "Fachkoordinator:in", // TODO: Add into translations
  studentTeacher: "Junglehrer:in", // TODO: Add into translations
  functionsAtTheSchool: "Funktionen an der Schule", // TODO: Add into translations
  generalFunctionsAtTheSchool: "Allgemeine Funktion an der Schule", // TODO: Add into translations
  notSelected: "nicht ausgewählt", // TODO: Add into translations
  moreThanThreeYears: "Mehr als 3 Jahre", // TODO: Add into translations
  teachingExperience: "Unterrichtserfahrung", // TODO: Add into translations
  myExperienceAndRoles: "Meine Erfahrung & Rollen", // TODO: Add into translations
  mySubjectsAndInterests: "Meine Fächer & Interessen", // TODO: Add into translations
  mySettingsAndNewsletter: "Meine Einstellungen & Newsletter",
  removeTypeOfSchool: "Remove type of school",
  delete: "Löschen",
  testCopy: "Test copy",
  teachersHandCopiesOrdered: "Teachers hand copies ordered",
  teachersHandCopiesReleased: "Teachers hand copies released",
  schoolBookCrowd: "School book manage",
  sbAction: "SB-Action",
  cancelReason: "Abbrechungsgrund",
  ifYouDeleteSchuleCategorySchoolTypesAndSchoolCodeWillAlsoBeDeleted:
    "If you delete Schule category, School types and School code will also be deleted. Are you sure you want to delete category Schule?",
  editInstitute: "Institut bearbeiten",
  teacherApprovalCodes: "Lehrernachweiscode",
  newApprovalCode: "Neuer Lehrernachweiscode",
  approvalCodeDeactivation: "Lehrernachweiscode deaktivieren",
  deactivate: "Deaktivieren",
  schoolCode: "Schulkennzahl",
  schoolType: "School type",
  instituteType: "Institute type",
  emailAddressInternal: "Email address internal",
  organization: "Organization",
  name: "Name",
  newInstitute: "New institute",
  addPerson: "Person hinzufügen",
  editApprovalCode: "Lehrernachweiscode bearbeiten",
  code: "Code",
  resetFilters: "Reset filters",
  instituteCategory: "Institutstyp",
  genderDiverse: "Divers",
  blcKdn: "BLC-Kdn",
  all: "All",
  people: "Personen",
  authorizationCode: "Berechtigungscode",
  schoolNumber: "Schulnummer",
  addressNumber: "Adressnummer",
  referenceNumbers: "Referenznummern",
  bookType: "Buchtyp",
  series: "Reihe",
  noSubscriptions: "Keine Abonnements",
  pleaseEnterValidDate: "Bitte gültiges Datum eingeben",
  startDate: "Startdatum",
  endDate: "Enddatum",
  additionalInformation: "Weitere Informationen",
  search: "Suche",
  street: "Straße",
  country: "Land",
  addressState: "Bundesland",
  buildingNumber: "Hausnummer",
  open: "Offen",
  rejected: "Abgelehnt",
  consented: "Zugestimmt",
  value: "Wert",
  updateCommunication: "Kommunikation aktualisieren",
  addCommunication: "Kommunikation hinzufügen",
  communicationType: "Kommunikationstyp",
  pending: "Steht aus",
  subscribed: "Abonniert",
  unsubscribed: "Abgemeldet",
  portalUser: "Portal user",
  portalUsers: "Portal users",
  addAnotherSchool: "Weitere Schule hinzufügen",
  communication: "Communication",
  newCommunication: "Neue Kommunikation",
  institutes: "Institutions",
  "nav.people": "Personen",
  academicDegree: "Akademischer Titel",
  additionalAcademicDegree: "Nachgestellter Titel",
  address: "Adresse",
  addresses: "Adressen",
  deactivationComment: "Deactivation comment",
  deactivationReason: "Deactivation reason",
  emailAddress: "E-Mail Adresse",
  emailAddressParent: "E-Mail Adresse der Eltern",
  filter: "Filter",
  firstName: "Vorname",
  gender: "Gender",
  homepage: "Homepage",
  occupationTitle: "Berufstitel",
  lastName: "Nachname",
  mailingConsent: "Mailing consent",
  newSubscription: "New subscription",
  orderInformation: "Order information",
  personType: "Person type",
  personTypes: "Person types",
  phone: "Telefon",
  purchases: "Purchases",
  showPurchases: "Show purchases",
  showSbaPurchases: "Show SBA purchases",
  showSubscriptions: "Show subscriptions",
  subscriptions: "Subscriptions",
  subscriptionsFilter: "Subscriptions filter",
  updateAddress: "Adresse bearbeiten",
  updateEmail: "Update email",
  updateInstitute: "Institutszuordnung bearbeiten",
  updateMailingConsent: "Update mailing consent",
  updatePhone: "Update phone",
  updateState: "Update state",
  userIsActive: "User is active",
  create: "Create",
  newPerson: "New person",
  fromYear: "From year",
  toYear: "To year",
  article: "Article",
  titleDescription: "Title description",
  title: "Title",
  onlyValidSubscriptions: "Only valid subscriptions",
  filterVerb: "Filter",
  abort: "Abort",
  actionFrom: "Action from",
  actionTo: "Action to",
  update: "Update",
  addSubscription: "Add subscription",
  addInstitute: "Bildungseinrichtung hinzufügen",
  institute: "Institute",
  teacherVerificationProvided: "Teacher verification provided",
  mainAssignment: "Main assignment",
  subscriptionType: "Subscription type",
  validFrom: "Valid from",
  validTo: "Valid to",
  add: "Hinzufügen",
  description: "Description",
  state: "Status",
  addressType: "Address type",
  copiedToClipboard: "Copied to clipboard",
  cancelTeacherApproval: "Cancel teacher approval",
  reason: "Reason",
  freeText: "Free text",
  cancelApproval: "Cancel approval",
  editPerson: "Edit person",
  traits: "Eigenschaften",
  propertyGroup: "Property group",
  propertyNumber: "Property number",
  typeGroup: "Type group",
  subType: "Subtype",
  department: "Department",
  subGroup: "Subgroup",
  zip: "PLZ",
  genderMale: "Männlich",
  genderFemale: "Weiblich",
  throwError: "Throw error",
  person: "Person",
  employee: "Employee",
  updateProperties: "Eigenschaften aktualisieren",
  properties: "Eigenschaften",
  customerNumber: "Customer number",
  city: "Stadt",
  createdAfter: "Created after",
  updatedAfter: "Updated after",
  number: "Number",
  mainAddress: "Hauptadresse",
  secondaryAddress: "Secondary address",
  addPersonType: "Personentyp hinzufügen",
  approvalType: "Approval type",
  yes: "Ja",
  no: "Nein",
  remove: "Entfernen",
  edit: "Bearbeiten",
  addProperty: "Eigenschaft hinzufügen",
  year: "Jahr",
  titleNumber: "Titelnummer",
  sumProceeds: "Einnahmen Summe",
  sumSold: "Verkaufte Summe",
  sumFree: "Summe frei",
  schoolYear: "Schuljahr",
  noPurchases: "Keine Einkäufe",
  active: "Aktiv",
  inactive: "Inaktiv",
  noEmailAddress: "Keine Email Adresse",
  noPhone: "Kein Telefon",
  onlyOneMainInstituteAllowedExplanation: "onlyOneMainInstituteAllowedExplanation",
  welcome: "Herzlich willkommen!",
  whichSalutationShouldWeUse: "Welche Anrede sollen wir verwenden?",
  next: "Weiter",
  noSchoolSelectedButton: "ICH UNTERRICHTE AN KEINER SCHULE MIT SCHULKENNZAHL",
  noSalutation: "Keine Anrede",
  mr: "Herr",
  mrs: "Frau",
  whatHappensToData: "Was passiert mit den Daten?",
  whatHappensToDataExplanation:
    "Mithilfe Ihrer Profildaten können wir Angebote und Services auf www.veritas.at personalisieren und für Sie passende Informationen zur Verfügung stellen.",
  veritasPrivacyPolicy: "VERITAS Datenschutzerklärung",
  isRequired: "ist ein Pflichtfeld",
  invalid: "ungültig",
  fistNameEqualsLastname: "Vorname muss sich von Nachnamen unterscheiden",
  requestProofFromSchool: "Nachweis bei Schule anfragen",
  completeProfile: "Profil vervollständigen",
  connectInstituteToVeritasAccount: "Bildungseinrichtung mit VERITAS Account verbinden",
  connectSchoolToVeritasAccount: "Schule mit Ihrem VERITAS-Benutzer verknüpfen",
  proofOfAuthorization: "Berechtigungscode",
  authorizationCodeRule:
    "Der VERITAS-Berechtigungscode ist ein 6- oder 7-stelliger Code, den Sie in Ihrer Direktion erhalten.",
  authorizationCodeRule2: "Er ist für alle Kolleg:innen IHRER Schule gültig.",
  authorizationCodeRule3: "Er muss nur ein einziges Mal eingegeben werden.",
  authorizationCodeRule4:
    "Sie können den Code auch telefonisch bei der VERITAS-Kundenberatung unter +43 732 776451-2280 anfordern.",
  save: "Speichern",
  alreadyLinkedSchools: "Bereits verknüpfte Schulen",
  mainSchool: "Stammschule",
  dataAndAddresses: "Daten und adressen",
  myOrders: "Meine Bestellungen",
  myNotepad: "Mein Merkzettel",
  settingsAndNewsletter: "Einstellungen und Newsletter",
  logout: "Abmelden",
  noSchoolLinkedYet: "Noch keine Schule verknüpft!",
  pleaseLinkSchoolToProfileForAccessOfTheSchoolContent:
    "Um direkten und unkomplizierten Zugriff auf alle für die Schule relevanten Inhalte zu bekommen, bitte die Schule mit dem Profil verknüpfen",
  addSchoolWithSchoolCodeAndCredentials:
    "Jetzt die eigene Schule mit Schulkennzahl und Berechtigungscode hinzufügen:",
  userName: "Benutzername",
  student: "Schüler*in",
  teacher: "Lehrer*in",
  addSchool: "Schule hinzufügen",
  addSchoolBenefitsTitle: "Folgende Vorteile warten auf Sie",
  addSchoolBenefitsTeacherProfileTitle:
    "Wenn Sie eine Schule mit Berechtigungscode hinzugefügt haben, können Sie",
  addSchoolBenefit: "E-Books aller über die Schulbuchaktion bestellten VERITAS-Bücher nutzen,",
  addSchoolBenefit2: "E-Books und interaktive Übungen auf scook.at gratis kennenlernen,",
  addSchoolBenefit3:
    "Lösungen einblenden und Materialien zur exklusiven Nutzung für Lehrer:innen einsehen,",
  addSchoolBenefit4:
    "auf veritas.at Produkte zur exklusiven Nutzung für Lehrer:innen erwerben (Schularbeiten, …).",
  profile: "Profil",
  linkedSchool: "Verknüpfte Schule",
  failedToGetTheUser: "Fehler beim Abrufen des Benutzers",
  anErrorOccurredWhileGettingUser: "Beim Abrufen des Benutzers ist ein Fehler aufgetreten",
  autoSaveChanges: "*Änderungen werden automatisch gespeichert",
  back: "Zurück",
  password: "Passwort",
  myDataAndAddress: "Meine Daten und Adressen",
  personalData: "Persönliche Daten",
  privateAddresses: "Private Adressen",
  thisSchoolIsNotVerified: "Diese Schule wurde noch nicht verifiziert.",
  authorizationCodeMissing: "Berechtigungscode fehlt.",
  provideProofOfEligibility: "Berechtigungscode erbringen",
  editData: "Daten bearbeiten",
  changePassword: "Passwort ändern",
  changeEmail: "E-Mail ändern",
  changeUserName: "Benutzernamen ändern",
  streetAndBuildingNumber: "Straße und Hausnummer",
  place: "Ort",
  placeName: "Ortsname",
  phoneNumber: "Telefonnummer",
  recipient: "Empfänger*in",
  areYouSureThatYouWantToProceedWithDeletion: "Möchten Sie wirklich mit dem Löschen fortfahren?",
  iAmDirectorAtSchool: "Icn bin DirektorIn an der Schule",
  iRunThisInstitute: "Ich leite diese Einrichtung",
  typeOfEducationalInstitution: "Art der Bildungseinrichtung",
  educationalInstitution: "Bildungseinrichtung",
  addEducationalInstitution: "Bildungseinrichtung hinzufügen",
  getTheRightInfoForYourEducationalInstitution:
    "Für Ihre Bildungseinrichtung passende Informationen erhalten",
  addAnotherEducationalInstitution: "Weitere Bildungseinrichtung hinzufügen",
  alreadyLinkedEducationalInstitution: "Bereits verknüpfte Bildungseinrichtung",
  austria: "Österreich",
  germany: "Deutschland",
  switzerland: "Schweiz",
  otherEU: "Andere EU Länder",
  notEU: "Nicht EU Länder",
  kindergarten: "Kindergarten",
  adultEducation: "Erwachsenenbildung",
  schoolCodeInfoSubtitle:
    "Dank der Schulkennzahl werden Schulen identifiziert und alle aktuellen Daten automatisch übermittelt.",
  schoolCodeInfoText:
    "Österreichsche Schulen haben eine allgemein gültige Schulkennzahl. Schulen in Südtirol oder andere Bildungseinrichtungen bekommen eine eigenen Schulkennzahl von VERITAS zugeschickt.",
  schoolProofOfAuthorizationInfoSubtitle:
    "Mit dem Berechtigungscode zeigen Sie, dass Sie Lehrer*in an der genannten Schule sind.",
  schoolProofOfAuthorizationInfoText:
    "So erhalten Sie direkten Zugang zu allen Materialien der Schule. Den Code bekommen Sie bei der Direktion Ihrer Schule.",
  backToPortal: "Zurück zum Portal",
  cancel: "Abbrechen",
} as const;

export default translationIds;

export type TranslationId = keyof typeof translationIds;
