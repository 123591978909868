import {
  Button,
  makeStyles,
  Paper,
  Typography,
  Divider,
} from "@material-ui/core";
import { ErrorOutline as ErrorIcon } from "@material-ui/icons";
import React from "react";
import { Center } from "../components/Center";

interface IProps {
  title?: string;
  details?: string;
  onRetry: () => void;
}

const useStyles = makeStyles((theme) => ({
  errorPaper: {
    maxWidth: "80%",
    padding: theme.spacing(2),
  },
  headline: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(0.25),
  },
  text: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    marginBottom: 0,
  },
}));

export function AuthError(props: IProps) {
  const classes = useStyles();

  return (
    <Center>
      <Paper className={classes.errorPaper} variant="outlined">
        <div>
          {props.title && (
            <Typography
              variant="body1"
              className={classes.headline}
              style={{
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
              }}
            >
              <ErrorIcon />
              &nbsp; {props.title}
            </Typography>
          )}
          {props.details && (
            <Typography variant="body2" className={classes.text}>
              {props.details}
            </Typography>
          )}
          <Divider />
          <p className={classes.actions}>
            <Button onClick={() => props.onRetry()}>Nochmal probieren</Button>
            &nbsp;
            <Button
              variant="contained"
              color="primary"
              component="a"
              href="https://veritasverlag.atlassian.net/servicedesk/customer/portal/1/group/63"
              target="_blank"
            >
              ServiceDesk kontaktieren
            </Button>
          </p>
        </div>
      </Paper>
    </Center>
  );
}
