import React from "react";
import { useProfileHelper } from "./useProfileHelper";
import _ from "lodash";
import { FullWidthDivider } from "../../../components/FullWidthDivider";
import { NORMAL_WIDTH } from "../../../utils/layoutHelper";
import { AssignedSchools } from "./TeacherProfilePage/AssignedSchools";
import { NoAssignedSchools } from "./TeacherProfilePage/NoAssignedSchools";
import { NavigationButton, NavigationButtons } from "../buttons/NavigationButtons";
import { Box, makeStyles } from "@material-ui/core";
import { ContentBox } from "../../../components/ContentBox";
import { BoldTypography } from "../../../components/BoldTypography";
import clsx from "clsx";
import { useTranslate } from "../../../utils/customHooks/useTranslate";
import { ListElement } from "../../../components";

export function TeacherProfilePage() {
  const { portalUser } = useProfileHelper();
  const t = useTranslate();
  const classes = useStyles();

  return (
    <>
      <FullWidthDivider mt={3} mb={3} width={NORMAL_WIDTH * 1.5} hideOnMobile />
      {!_.isEmpty(portalUser.institutes) ? <AssignedSchools /> : <NoAssignedSchools />}
      <ContentBox className={clsx(classes.marginTop)}>
        <BoldTypography>{t("addSchoolBenefitsTeacherProfileTitle")}</BoldTypography>
        <Box component={"ul"} sx={{ px: 2.5 }}>
          <ListElement text={t("addSchoolBenefit")} />
          <ListElement text={t("addSchoolBenefit2")} />
          <ListElement text={t("addSchoolBenefit3")} />
          <ListElement text={t("addSchoolBenefit4")} />
        </Box>
      </ContentBox>
      <FullWidthDivider mt={3} mb={3} width={NORMAL_WIDTH * 1.5} hideOnMobile />
      <NavigationButtons
        types={[
          NavigationButton.Orders,
          NavigationButton.Wishlist,
          NavigationButton.SettingsAndNewsletter,
        ]}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));
