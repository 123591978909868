import {useTranslate} from "../utils/customHooks/useTranslate";
import {CircularProgress, makeStyles} from "@material-ui/core";
import {EXTENDED_NORMAL_WIDTH, MOBILE_WIDTH} from "../utils/layoutHelper";
import {useCustomHistory} from "../utils/customHooks/useCustomHistory";
import {ColumnFlexBox} from "./Boxes/ColumnFlexBox";
import {FlexBox} from "./Boxes/FlexBox";
import {OutlinedButton} from "./OutlinedButton";
import React from "react";
import {Button} from "./Button";

interface NavigationButtonsProps {
    isSubmitting?: boolean;
    disabled?:boolean;
    onSave?: (() => void) | (() => Promise<void>);
    backRoute?: string;
}

export function NavigationButtons(props: NavigationButtonsProps={isSubmitting: false}) {


    const t = useTranslate();
    const history = useCustomHistory();

    const classes = useStyles();

    return <ColumnFlexBox className={classes.buttonsWrapper} mt={2} justifyContent="space-between">
        {
            props.isSubmitting ?
                <FlexBox width={"100%"} justifyContent="center">
                    <CircularProgress/>
                </FlexBox>
                :
                <>
                    {props.onSave ?
                        <Button buttonText={t("save")} 
                                onClick={props.onSave}
                                disabled={props.disabled || props.isSubmitting}/> :
                        <Button buttonText={t("save")} type="submit"
                                disabled={props.disabled || props.isSubmitting}/>
                    }
                    <OutlinedButton
                        buttonText={t("back")}
                        onClick= {props.backRoute? ()=> history.navigate(props.backRoute??"") :() => history.goBack()}
                    />
                </>
        }
    </ColumnFlexBox>

}

const useStyles = makeStyles((theme) => ({
    buttonsWrapper: {
        width: EXTENDED_NORMAL_WIDTH + "px",
        [theme.breakpoints.down("xs")]: {
            width: MOBILE_WIDTH + "px",
        },
        gap: theme.spacing(),
    },
}));