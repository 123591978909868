import MomentUtils from "@date-io/moment";
import { grey } from "@material-ui/core/colors";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ConfirmProvider } from "material-ui-confirm";
import moment from "moment";
import "moment/locale/de";
import { SnackbarProvider } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import { AuthorizationProvider, IdentityAuthProvider } from "./auth";
import { AsyncIntlProvider } from "./components";
import { HttpProvider, ServiceProvider, UIStateProvider } from "./contexts";
import { PortalUserProvider } from "./features/PortalUserProvider";
import { UserProfileRoutes } from "./features/profile/UserProfileRoutes";
import { UserRegistrationRoutes } from "./features/registration/UserRegistrationRoutes";
import { Layout } from "./layouts";
import { TestingView } from "./TestingViewMenu";
import { UserType } from "./utils/beEnumMapping";
import { ViewHandler } from "./features/ViewHandler";
import { TestingModeMenus } from "./TestingModeMenus";
import { routes, unauthorizedRoutes } from "./routes/routes";
import { UnsubscribeEmail } from "./features/externalEdits/UnsubscribeEmail";

const theme = createTheme({
  typography: {
    fontSize: 12.25,
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    h1: {
      fontSize: "24px",
      fontWeight: 500,
    },
    h2: {
      fontSize: "21px",
      fontWeight: 500,
    },
    h3: {
      fontSize: "16px",
      fontWeight: 500,
    },
    allVariants: {
      //color: grey[700],
      color: "#303439",
    },
  },
  palette: {
    background: {
      //default: grey[100],
      default: "#f2f4f4",
    },
    secondary: {
      main: "#172B4D",
      contrastText: "#fff",
    },
    primary: {
      //main: "#ffbb1f",
      main: "#ffd500",
      contrastText: "#333334",
    },
    text: {
      secondary: grey[700],
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        fontSize: 17,
      },
    },
    MuiTableCell: {
      head: {
        whiteSpace: "nowrap",
      },
    },
    MuiButton: {
      contained: {
        boxShadow: "none",
        fontWeight: 600,
        // textTransform: "none",
      },
      // outlined: {
      //   textTransform: "none",
      // },
      // text: {
      //   textTransform: "none",
      // },
    },
    MuiDrawer: {
      paper: {
        bottom: 0,
        top: 0,
        height: "auto",
        borderRadius: 4,
        margin: 16,
      },
    },
    MuiStepConnector: {
      lineHorizontal: { display: "none" },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "8px",
      },
    },
  },
  shape: {
    borderRadius: 2,
  },
  spacing: 8,
});

const services = {
  alert: window.alert,
};

function App() {
  moment.locale(navigator.language);

  const [viewState, setViewState] = useState<TestingView>(TestingView.Inactive);
  const [userType, setUserType] = useState<UserType | null>(null);

  const [isTestingMode, setIsTestingMode] = useState(
    viewState !== TestingView.Inactive || userType !== null
  );

  useEffect(() => {
    setIsTestingMode(viewState !== TestingView.Inactive || userType !== null);
  }, [setIsTestingMode, viewState, userType]);

  const isLocalHost = window.location.host === "localhost:4300";

  const authorizedRoutes = useMemo(() => [...Object.values(routes), "/"], []);
  return (
    <MuiThemeProvider theme={theme}>
      <UIStateProvider>
        {({ currentCulture }) => (
          <AsyncIntlProvider>
            <Router>
              <Switch>
                <Route exact path={unauthorizedRoutes.unsubscribe}>
                  <UnsubscribeEmail />
                </Route>
                <Route exact path={authorizedRoutes}>
                  <IdentityAuthProvider>
                    <SnackbarProvider maxSnack={3}>
                      <HttpProvider>
                        <AuthorizationProvider>
                          <ConfirmProvider>
                            <ServiceProvider value={services}>
                              <MuiPickersUtilsProvider utils={MomentUtils} locale={currentCulture}>
                                <TestingUserTypeContext.Provider
                                  value={{ testingUserType: userType }}
                                >
                                  {isLocalHost && (
                                    <TestingModeMenus
                                      viewState={viewState}
                                      setViewState={(vs) => setViewState(vs)}
                                      userType={userType}
                                      setUserType={(ut) => setUserType(ut)}
                                    />
                                  )}
                                  <ViewHandler viewState={viewState} isTestingMode={isTestingMode}>
                                    <Layout>
                                      <UserRegistrationRoutes />
                                      <PortalUserProvider userType={userType}>
                                        <UserProfileRoutes />
                                      </PortalUserProvider>
                                    </Layout>
                                  </ViewHandler>
                                </TestingUserTypeContext.Provider>
                              </MuiPickersUtilsProvider>
                            </ServiceProvider>
                          </ConfirmProvider>
                        </AuthorizationProvider>
                      </HttpProvider>
                    </SnackbarProvider>
                  </IdentityAuthProvider>
                </Route>
              </Switch>
            </Router>
          </AsyncIntlProvider>
        )}
      </UIStateProvider>
    </MuiThemeProvider>
  );
}

export const TestingUserTypeContext = React.createContext<{ testingUserType: UserType | null }>({
  testingUserType: null,
});

export default App;
