import React from "react";
import FormikAutocompleteAsync, {
  FormikAutocompleteAsyncProps,
} from "../Formik/FormikAutocompleteAsync";
import { GetTraitDataGetTraitDataResponse, usePumaContextApi } from "../../api";
import { Option } from "../Formik";
import { makeStyles } from "@material-ui/core";
import _ from "lodash";

export enum TraitGroup {
  Functions = "AttFnc",
  Subjects = "AttSbj",
}

export enum TraitData {
  SubjectCoordinator = 4078,
}

export type TraitsDropdownProps = Omit<
  FormikAutocompleteAsyncProps<GetTraitDataGetTraitDataResponse, number, true, true, false>,
  "options" | "dataFetcher" | "optionsMapper"
> & {
  traitGroup?: TraitGroup;
  parent?: TraitData;
  sendRequestOnChange?: boolean;
};

export function TraitsDropdown(props: TraitsDropdownProps) {
  const pumaApi = usePumaContextApi();
  const classes = useStyles();

  return (
    <FormikAutocompleteAsync
      {...props}
      TextFieldProps={{
        variant: "outlined",
        className: classes.textfieldBackground,
        ...props.TextFieldProps,
      }}
      dataFetcher={async () => {
        return (
          (
            await pumaApi.autocomplete.autocompleteTraitsList({
              TraitGroupName: props.traitGroup,
              ParentId: props.parent,
            })
          ).data || []
        );
      }}
      fullWidth
      multiple
      disableClearable
      onRemove={async (removedValue, reason) => {
        if (props.sendRequestOnChange !== true) return false;

        await pumaApi.portalUsers.portalUsersRemoveTraitsDelete({
          traitIds: _.isArray(removedValue)
            ? removedValue.map((x) => x.value)
            : [(removedValue as Option<number>).value],
        });
        return true;
      }}
      onChange={async (_, value, reason, x) => {
        if (props.sendRequestOnChange !== true) return;
        if (reason === "select-option") {
          await pumaApi.portalUsers.portalUsersAddTraitCreate(x?.option.value!);
        }
      }}
      optionsMapper={mapTraitToOption}
    />
  );
}

function mapTraitToOption(traitDto: GetTraitDataGetTraitDataResponse): Option<number> {
  return {
    key: traitDto.id!,
    value: traitDto.id!,
    displayValue: traitDto.description!,
  };
}

const useStyles = makeStyles((theme) => ({
  textfieldBackground: {
    [theme.breakpoints.down("xs")]: {
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
}));
