import React, { ReactNode } from "react";
import { InputLabel, makeStyles } from "@material-ui/core";

export interface CustomLabelProps {
  label?: ReactNode;
  showLabelAsRequired?: boolean;
  error?: boolean;
}

export function CustomLabel(props: CustomLabelProps) {
  const classes = useStyles();

  return props.label ? (
    <InputLabel className={classes.label} error={props.error}>
      {props.label} {props.showLabelAsRequired ? " *" : ""}
    </InputLabel>
  ) : null;
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: theme.typography.fontWeightBold,
    width: "100%",
  },
}));
