import React from "react";
import { default as MUIStepper } from "@material-ui/core/Stepper";
import { makeStyles } from "@material-ui/core";
import { ColumnFlexBox } from "../Boxes/ColumnFlexBox";
import clsx from "clsx";

interface StepModel {
  id: number;
}

interface Props {
  activeStep: StepModel;
  steps: Array<StepModel>;
}

export function Stepper(props: Props) {
  const classes = useStyles();
  const activeStepIndex = props.steps.findIndex((x) => x.id === props.activeStep.id);
  return (
    <ColumnFlexBox alignItems="center">
      <MUIStepper activeStep={activeStepIndex} nonLinear className={classes.wrapper}>
        {props.steps.map((step, index) => (
          <div
            key={step.id}
            className={clsx(
              classes.stepIcon,
              index <= activeStepIndex ? classes.stepIconCompleted : ""
            )}
          />
        ))}
      </MUIStepper>
    </ColumnFlexBox>
  );
}

//#region styles
const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      gap: theme.spacing(),
      padding: 0,
      backgroundColor: "transparent",
    },
    stepIcon: {
      height: "18px",
      width: "18px",
      borderRadius: "50%",
      backgroundColor: theme.palette.grey[400],
    },

    stepIconCompleted: {
      backgroundColor: theme.palette.grey[800],
    },
  };
});
//#endregion
