import * as React from "react";
import * as ReactDOM from "react-dom";
import "react-placeholder/lib/reactPlaceholder.css";

import App from "./App";

declare global {
  interface Window {
    apiUrl: string;
  }
}

ReactDOM.render(<App />, document.getElementById("root"));

window.console.log(
  "  ______ _______ _______ _______ __ \n" +
    " |   __ \\   |   |   |   |   _   |  |\n" +
    " |    __/   |   |       |       |__|\n" +
    " |___|  |_______|__|_|__|___|___|__|"
);
