import React from "react";
import { RouteType, useCustomHistory } from "../utils/customHooks/useCustomHistory";
import { CustomIcon } from "./icons/CustomIcon";

interface Props {
  editRoute: RouteType;
}

export function EditIconButton(props: Props) {
  const history = useCustomHistory();

  return (
    <CustomIcon
      name="Icon_Bearbeiten"
      onClick={() => history.navigate(props.editRoute.route, props.editRoute.params)}
    />
  );
}
