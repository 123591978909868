import { Checkbox, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { ColumnFlexBox } from "../../components/Boxes/ColumnFlexBox";
import { RowFlexBox } from "../../components/Boxes/RowFlexBox";
import { ContentWrapper } from "../../components/ContentWrapper";
import { CustomIcon } from "../../components/icons/CustomIcon";
import { getPropertyNames } from "../../utils";
import { useRegistrationContext } from "../../utils/customHooks/useRegistrationContext";
import { useStateRef } from "../../utils/customHooks/useStateRef";
import { useTranslate } from "../../utils/customHooks/useTranslate";
import { EXTENDED_NORMAL_WIDTH, MOBILE_WIDTH } from "../../utils/layoutHelper";

export function RegistrationMailingConsents() {
  const { formik } = useRegistrationContext();
  const t = useTranslate();
  const classes = useStyles();
  const checkboxRef = useStateRef<HTMLButtonElement>();

  const names = getPropertyNames(formik.initialValues);
  return (
    <React.Fragment>
      <CustomIcon name={"Profil_vollständig"} height={250} width={250} />
      <ContentWrapper className={classes.wrapper}>
        <ColumnFlexBox>
          <RowFlexBox alignItems={"center"}>
            <Checkbox
              ref={checkboxRef}
              defaultChecked={formik.values.emailSubscription}
              onChange={(e) =>
                formik.setFieldValue(names.emailSubscription, e.currentTarget.checked)
              }
            />
            <Typography variant={"body2"}>{t("iWouldLikeToGetEmailsFromVeritas")}</Typography>
          </RowFlexBox>
          <RowFlexBox alignItems={"center"} paddingLeft={`${checkboxRef.current?.offsetWidth}px`}>
            <Typography variant={"caption"}>{t("youReceiveInfoViaEmails")}</Typography>
          </RowFlexBox>
        </ColumnFlexBox>
      </ContentWrapper>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: EXTENDED_NORMAL_WIDTH + "px",
    [theme.breakpoints.down("xs")]: {
      width: MOBILE_WIDTH + "px",
    },
  },
}));
