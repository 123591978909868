import React from "react";
import { useCustomHistory } from "../../../utils/customHooks/useCustomHistory";
import { routes } from "../../../routes/routes";
import { OutlinedButton } from "../../../components/OutlinedButton";
import { CustomIcon } from "../../../components/icons/CustomIcon";
import { useTranslate } from "../../../utils/customHooks/useTranslate";

type MySettingsAndNewsletterButtonProps = {};

export function MySettingsAndNewsletterButton(props: MySettingsAndNewsletterButtonProps) {
  const t = useTranslate();
  const history = useCustomHistory();
  return (
    <OutlinedButton
      buttonText={t("settingsAndNewsletter")}
      icon={<CustomIcon name="Icon_Newsletter" />}
      onClick={() => history.navigate(routes.userProfileSettingsAndNewsletters)}
    />
  );
}
