import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";

interface AppBodyProps {
  children: React.ReactNode;
}

const LayoutBody = (props: AppBodyProps) => {
  const classes = useStyles();

  return <div className={classes.appBody}>{props.children}</div>;
};

//#region styles
const useStyles = makeStyles((theme) => ({
  appBody: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 100%",
    width: "100%",
    justifyContent: "center",
    padding: theme.spacing(3),
    boxSizing: "border-box",

    [theme.breakpoints.down("xs")]: {
      padding: 0,
      justifyContent: "flex-start",
    },

    "& > div": {
      width: "100%",
    },
    backgroundColor: theme.palette.background.default,
  },
}));
//#endregion

export default LayoutBody;
