import React from "react";
import { NavigationButton, NavigationButtons } from "../buttons/NavigationButtons";


export function OtherProfilePage() {
  return (
    <NavigationButtons
      mt={2}
      types={[
        NavigationButton.Orders,
        NavigationButton.Wishlist,
        NavigationButton.SettingsAndNewsletter,
      ]}
    />
  );
}
