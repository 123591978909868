import React from "react";
import { FlexBox, FlexBoxProps } from "./Boxes/FlexBox";
import { Button, makeStyles, Typography, TypographyProps } from "@material-ui/core";
import { CustomIcon } from "./icons/CustomIcon";
import clsx from "clsx";

export type PlusTextButtonProps = {
  text: string;
  TypographyProps?: TypographyProps;
} & FlexBoxProps;

export function PlusTextButton(props: PlusTextButtonProps) {
  const { text, TypographyProps, ...boxProps } = props;
  const classes = useStyles();
  return (
    <FlexBox {...boxProps}>
      <Button color={"default"} className={classes.button}>
        <CustomIcon width={12} height={12} name={"Icon_Plus"} onClick={props.onClick} />
        <Typography {...TypographyProps} className={clsx(classes.text, TypographyProps?.className)}>
          {text}
        </Typography>
      </Button>
    </FlexBox>
  );
}

const useStyles = makeStyles((theme) => ({
  text: {
    textDecoration: "underline",
    textTransform: "initial",
    paddingLeft: theme.spacing(0.5),
  },
  button: { width: "fit-content" },
}));
