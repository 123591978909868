import * as React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import { ColumnFlexBox } from "./Boxes/ColumnFlexBox";
import { BoldTypography } from "./BoldTypography";
import { useTranslate } from "../utils/customHooks/useTranslate";
import { TranslationId } from "../utils/translationIds";

export interface InfoBoxData {
  title?: TranslationId;
  text?: TranslationId;
  link?: { url: string; text: string };
}

export function InfoBox(props: InfoBoxData) {
  const t = useTranslate();
  return (
    <ColumnFlexBox alignItems="center">
      <Box mb={1} sx={{ fontWeight: 600 }}>
        <BoldTypography>{props.title && t(props.title)}</BoldTypography>
      </Box>
      <Typography align="center" variant={"body2"}>
        {props.text && t(props.text)}
      </Typography>
      {props.link ? (
        <Box mt={4}>
          <Link underline="always" href={props.link.url} color="textSecondary" target={"_blank"}>
            {props.link.text}
          </Link>
        </Box>
      ) : null}
    </ColumnFlexBox>
  );
}
