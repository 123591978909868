import React from "react";
import { Box, BoxProps, makeStyles, Theme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles/withStyles/withStyles";
import clsx from "clsx";

export type FlexBoxProps<MakeStylesProps = undefined> = Omit<BoxProps, "display"> & {
  makeStyles?: <T extends object>(theme: Theme, props?: T) => CSSProperties;
  overlay?: React.ReactNode;
};

export function FlexBox(props: FlexBoxProps) {
  const styles = props.makeStyles
    ? makeStyles((theme) => ({
        customStyle: props.makeStyles!(theme),
      }))().customStyle
    : "";

  return <Box {...props} display={"flex"} className={clsx(styles, props.className)} />;
}
