import * as React from "react";
import { UserRegistrationPage } from "../../pages/UserRegistrationPage";
import { AdminRoute } from "../../routes/AdminRoute";
import { routes } from "../../routes/routes";

export function UserRegistrationRoutes() {
  return (
    <React.Fragment>
      <AdminRoute exact path={routes.userRegistration} component={UserRegistrationPage} />
    </React.Fragment>
  );
}
