import React from "react";
import { useTranslate } from "../../utils/customHooks/useTranslate";
import { ColumnFlexBox } from "../../components/Boxes/ColumnFlexBox";
import { BoldTypography } from "../../components/BoldTypography";
import { Box } from "@material-ui/core";
import { FunctionsDropdown } from "../../components/Dropdowns/FunctionsDropdown";
import { useRegistrationContext } from "../../utils/customHooks/useRegistrationContext";
import { TraitData } from "../../components/Dropdowns/TraitsDropdown";
import { CoordinatedSubjectsDropdown } from "../../components/Dropdowns/CoordinatedSubjectsDropdown";

export function RegistrationExperienceAndRoles() {
  const { formik } = useRegistrationContext();
  const t = useTranslate();

  return (
    <ColumnFlexBox width={"100%"}>
      <Box mt={1} mb={2}>
        <FunctionsDropdown name={formik.propertyNames.functionsAtSchool} />
        {formik.values.functionsAtSchool.includes(TraitData.SubjectCoordinator) && (
          <>
            <BoldTypography variant={"body2"}>
              {t("subjectCoordinationInTheSubject")}
            </BoldTypography>
            <CoordinatedSubjectsDropdown name={formik.propertyNames.coordinatedSubjects} />
          </>
        )}
      </Box>
    </ColumnFlexBox>
  );
}
