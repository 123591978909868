import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslate } from "../../../../utils/customHooks/useTranslate";
import { SchoolWidget } from "../../../institutes/SchoolWidget/SchoolWidget";
import { useProfileHelper } from "../useProfileHelper";
import { usePumaContextApi } from "../../../../api";
import { AddSchoolButton } from "./AddSchoolButton";

export function AssignedSchools() {
  const api = usePumaContextApi();
  const { reload, portalUser } = useProfileHelper();
  const classes = useStyles();
  const t = useTranslate();
  return (
    <>
      <Typography className={classes.verifiedSchoolsTitle} variant={"h3"}>
        {t("linkedSchools")}
      </Typography>

      {portalUser.institutes?.map((x) => (
        <SchoolWidget
          mt={1}
          mb={1}
          institute={x}
          isMainAssignment={x.mainAssignment === true}
          setAsMainAssignment={async () => {
            await api.portalUsers.portalUsersInstitutesUpdate(x.id!, {
              isMainInstitute: true,
            });
            reload();
          }}
          isVerified={x.authorizationCodeProvided === true}
          onValidVerificationCode={async (code) => {
            await api.portalUsers.portalUsersInstitutesUpdate(x.id!, {
              teacherVerification: code,
            });
            reload();
          }}
          showMainAssignmentRadio={portalUser.institutes!.length > 1}
          onRemoveClick={async () => {
            await api.portalUsers.portalUsersInstitutesDelete(x.id!);
            reload();
          }}
        />
      ))}
      <AddSchoolButton
        mt={1}
        makeStyles={(theme) => ({
          [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(3),
          },
        })}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  verifiedSchoolsTitle: {
    alignSelf: "flex-start",
    [theme.breakpoints.down("xs")]: {
      alignSelf: "center",
      marginTop: theme.spacing(3),
    },
  },
  addSchoolButton: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(3),
    },
  },
  addSchoolButtonText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
