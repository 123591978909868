import { Formik } from "formik";
import React, { useMemo, useState } from "react";
import { IUserRegistrationFormValues } from "../features/registration/form/UserRegistrationFormHelper";
import { UserRegistrationDetails } from "../features/registration/UserRegistrationDetails";
import { useTranslate } from "../utils/customHooks/useTranslate";
import {
  RegistrationHandler,
  useRegistrationHandler,
} from "../utils/customHooks/useRegistrationHandler";
import {
  InterruptRegistrationPage,
  InterruptRegistrationPageType,
} from "../features/registration/InterruptRegistrationPage";
import { ObjectSchema } from "yup";
import { RegistrationStep } from "../features/registration/enum/RegistrationStep";
import { UserType } from "../utils/beEnumMapping";
import { PersonalDataValidationObject } from "../utils/validations";

//@ts-ignore
export const RegistrationStepsContext = React.createContext<RegistrationHandler>();

export const initialRegistrationFormValues: Omit<IUserRegistrationFormValues, "userType"> = {
  firstName: "",
  lastName: "",
  academicDegree: null,
  additionalAcademicDegree: null,
  salutation: "",
  interests: [],
  subjects: [],
  functionsAtSchool: [],
  coordinatedSubjects: [],
  emailSubscription: false,
  schools: [],
  educationalInstitutions: [],
  mainInstituteId: null,
  occupationTitleId: null,
};

export function UserRegistrationPage() {
  const registrationHandler = useRegistrationHandler();
  const validationSchema = useValidationSchema();

  const [interruptPage, setInterruptPage] = useState({
    show: false,
    pageType: InterruptRegistrationPageType.Default,
    onFinishLater: () => {},
    onFinishNow: () => {},
  });

  const initialValues: IUserRegistrationFormValues = {
    ...initialRegistrationFormValues,
    userType: registrationHandler.userType ?? UserType.Other,
    interests: registrationHandler.preselectedInterests,
  };
  return registrationHandler.loading ? null : (
    <RegistrationStepsContext.Provider value={registrationHandler}>
      <Formik
        validateOnBlur={false}
        validateOnMount={false}
        validateOnChange={false}
        initialValues={initialValues}
        onSubmit={async (form) => {
          if (registrationHandler.activeStep.type === RegistrationStep.BasicInfo) {
            form.firstName = form.firstName.trim();
            form.lastName = form.lastName.trim();
          }

          if (
            registrationHandler.activeStep.type === RegistrationStep.AddSchools &&
            form.schools.length === 0 &&
            !interruptPage.show
          ) {
            setInterruptPage({
              show: true,
              onFinishNow: () => setInterruptPage((prev) => ({ ...prev, show: false })),
              onFinishLater: () => {
                setInterruptPage((prev) => ({ ...prev, show: false }));
                registrationHandler.activeStep.continueToNextStep();
              },
              pageType: InterruptRegistrationPageType.NoSchoolVerified,
            });
            return;
          }

          const finishRegistration =
            registrationHandler.activeStep.isLastStep || interruptPage.show;

          if (finishRegistration) {
            await registrationHandler.finishRegistration(form);
          } else {
            registrationHandler.activeStep.continueToNextStep();
          }
        }}
        validationSchema={validationSchema}
      >
        {interruptPage.show ? (
          <InterruptRegistrationPage
            pageType={interruptPage.pageType}
            onFinishNow={interruptPage.onFinishNow}
            onFinishLater={interruptPage.onFinishLater}
            isSubmitting={registrationHandler.isFinalizingRegistration}
          />
        ) : (
          <UserRegistrationDetails
            showInterruptPage={setInterruptPage}
            isSubmitting={registrationHandler.isFinalizingRegistration}
          />
        )}
      </Formik>
    </RegistrationStepsContext.Provider>
  );
}

function useValidationSchema(): ObjectSchema<Partial<IUserRegistrationFormValues>> {
  const t = useTranslate();
  return useMemo(
    () => PersonalDataValidationObject(t),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}
