import { CircularProgress, makeStyles, Paper, Theme } from "@material-ui/core";
import React, { useState } from "react";
import { ColumnFlexBox } from "./Boxes/ColumnFlexBox";
import { PageWrapperTitle, PageWrapperTitleProps } from "./PageWrapperTitle";
import clsx from "clsx";
import _ from "lodash";
import { Center } from "./Center";

export type PageWrapperProps = Omit<PageWrapperTitleProps, "onRefChange"> & {
  children: React.ReactNode;
  hideBackButton?: boolean;
};

type PageWrapperLoaderContextType = React.Dispatch<React.SetStateAction<boolean>>;

export const PageWrapperLoaderContext = React.createContext<PageWrapperLoaderContextType>(
  () => false
);

export const PageWrapper = (props: PageWrapperProps) => {
  const [titleHeight, setTitleHeight] = useState<number | undefined>(undefined);
  const [showLoader] = useState(false);
  const classes = useStyles({ titleHeight, showLoader });

  return (
    <Paper
      {...props}
      className={clsx(classes.bodyWrapper, classes.stylesOnLoading, props.className)}
    >
      {/*<PageWrapperLoaderContext.Provider value={setShowLoader}>*/}
      <PageWrapperLoaderContext.Provider value={() => false}>
        <PageWrapperTitle
          {...props}
          onRefChange={(ref) => !_.isNil(ref) && setTitleHeight(ref.current?.clientHeight)}
        />
        <ColumnFlexBox alignItems={"center"} className={classes.wrapper}>
          {props.children}
        </ColumnFlexBox>
        {showLoader && (
          <Center style={{ zIndex: 100000000 }}>
            <CircularProgress />
          </Center>
        )}
      </PageWrapperLoaderContext.Provider>
    </Paper>
  );
};

//#region styles
const useStyles = makeStyles<Theme, { titleHeight: number | undefined; showLoader: boolean }>(
  (theme) => ({
    wrapper: {
      padding: theme.spacing(3),
      paddingTop: 0,
      [theme.breakpoints.down("xs")]: {
        paddingTop: (props) => `${(props.titleHeight || 0) + theme.spacing(3)}px`,
      },
      // alignSelf: "center",
    },
    bodyWrapper: {
      fontFamily: theme.typography.fontFamily,
      boxSizing: "border-box",
      backgroundColor: theme.palette.background.paper,
      // display: "flex",
      // flexDirection: "column",
      borderRadius: theme.spacing(),
      position: "relative",
      boxShadow: "4px 4px 10px rgba(0,0,0,.1)",
      padding: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        backgroundColor: theme.palette.background.default,
        borderRadius: 0,
        margin: 0,
        padding: 0,
        boxShadow: "none",
      },
    },
    stylesOnLoading: {
      opacity: (props) => (props.showLoader ? 0.8 : "inherit"),
      pointerEvents: (props) => (props.showLoader ? "none" : "inherit"),
    },
  })
);

//#endregion
