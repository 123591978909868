import React from "react";
import { Typography, TypographyProps } from "@material-ui/core";
import { useTranslate } from "../../../utils/customHooks/useTranslate";
import { PortalUserContext } from "../../PortalUserProvider";

interface Props extends TypographyProps {
  hideLabel?: boolean;
}

export function UserName(props: Props) {
  const t = useTranslate();
  const { portalUser } = React.useContext(PortalUserContext);

  const userName = portalUser.userName || "";
  return <Typography>{props.hideLabel ? userName : t("userName") + ": " + userName}</Typography>;
}
