import React, { useCallback, useEffect } from "react";
import { usePumaContextApi } from "../api";
import { navigateToReturnUrlIfExists } from "../auth";
import { useCustomHistory } from "../utils/customHooks/useCustomHistory";
import { useAsync } from "react-async-hook";
import { TestingView } from "../TestingViewMenu";
import { routes } from "../routes/routes";

type ViewProviderProps = {
  viewState: TestingView;
  children: React.ReactNode;
  isTestingMode: boolean;
};

export const ViewHandlerContext = React.createContext<{
  isRegistered: boolean;
  isTestingMode: boolean;
  reload: () => Promise<void>;
}>({
  isRegistered: false,
  isTestingMode: false,
  reload: async () => {},
});

export function ViewHandler(props: ViewProviderProps) {
  const api = usePumaContextApi();

  const isRegisteredRequest = useAsync(
    () => api.portalUsers.portalUsersIsRegisteredList(),
    []
  );

  const isUserRegistered =
    props.viewState === TestingView.Inactive
      ? isRegisteredRequest.result?.data
      : props.viewState === TestingView.Profile
      ? true
      : props.viewState === TestingView.Registration
      ? false
      : undefined;

  const redirectCallback = useUrlRedirector(isUserRegistered);

  useEffect(() => {
    !isRegisteredRequest.loading && redirectCallback();
  }, [isRegisteredRequest.loading, props.viewState, redirectCallback]);

  return isRegisteredRequest.loading ? null : (
    <ViewHandlerContext.Provider
      value={{
        isRegistered: isUserRegistered!,
        isTestingMode: props.isTestingMode,
        reload: async () => {
          await isRegisteredRequest.execute();
        },
      }}
    >
      {props.children}
    </ViewHandlerContext.Provider>
  );
}

function useUrlRedirector(isUserRegistered: boolean | null | undefined) {
  const history = useCustomHistory();
  const isRegistrationRoute = history.isCurrentRoute(routes.userRegistration);

  return useCallback(() => {
    if (isRegistrationRoute) {
      if (isUserRegistered) { 
        navigateToReturnUrlIfExists();
        history.navigate(routes.userProfile);
      }
      return;
    }
    if (!isUserRegistered) history.navigate(routes.userRegistration);
  }, [isUserRegistered, history, isRegistrationRoute]);
}
