import * as React from "react";
import { Loading } from "../components";
import { useCommonApi } from "../api";

export interface IAuthConfiguration {
  authority: string;
  clientId: string;
  redirectUri: string;
  silentRedirectUri: string;
  clientSecret: string;
}

/**
 * Structure of the auth settings inside the appsettings.json
 */
interface IAppSettings {
  authority: string;
  clientId: string;
  redirectUri: string;
  silentRedirectUri: string;
  responseType: string;
  scope: string;
  clientSecret: string;
}

interface IChildrenProps {
  config: IAuthConfiguration;
  responseType: string;
  scope: string;
}

interface IProps {
  children: (props: IChildrenProps) => React.ReactElement;
}

/**
 * Loads the Auth configuration. That's it.
 * Loading the config and applying the config is split into two separate components.
 * This is the loading component.
 * @param props Just the children ;)
 */
export function IdentityConfigLoader(props: IProps) {
  const [settings, setSettings] = React.useState<IAppSettings>();
  const [config, setConfig] = React.useState<IAuthConfiguration>();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const api = useCommonApi();

  function hasError() {
    return errorMessage != null && errorMessage !== "";
  }

  /**
   * Creates config from appsettings.
   * @param ss Loaded appsettings
   */
  function mapAppSettingsToConfiguration(ss: IAppSettings): IAuthConfiguration {
    return {
      authority: ss.authority,
      clientId: ss.clientId,
      redirectUri: ss.redirectUri,
      silentRedirectUri: ss.silentRedirectUri,
      clientSecret: ss.clientSecret,
    };
  }

  // Load config from server...
  React.useEffect(() => {
    api.config
      .configList()
      .then((j) => {
        setSettings(j.data);
        setConfig(mapAppSettingsToConfiguration(j.data));
      })
      .catch((e) => {
        setErrorMessage(e.message);
      });
  }, [api.config]);

  if (hasError()) {
    return (
      <p>
        <span>Fehler beim Laden der OIDC Config:</span>
        <strong>{errorMessage}</strong>
      </p>
    );
  }
  if (config != null) {
    return props.children({
      config,
      responseType: settings?.responseType || "",
      scope: settings?.scope || "",
    });
  }
  return <Loading msg="config" />;
}
