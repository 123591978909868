import React, { useState } from "react";
import { PageWrapper } from "../components/PageWrapper";
import _ from "lodash";
import { makeStyles } from "@material-ui/core";
import { ResponsesInstituteResponse, usePumaContextApi } from "../api";
import { useCustomHistory } from "../utils/customHooks/useCustomHistory";
import { PortalUserContext } from "../features/PortalUserProvider";
import { useTranslate } from "../utils/customHooks/useTranslate";
import { EducationalInstituteForm } from "../features/institutes/instituteAssignment/educationalInstitution/EducationalInstituteForm";
import { EXTENDED_NORMAL_WIDTH } from "../utils/layoutHelper";
import { ColumnFlexBox } from "../components/Boxes/ColumnFlexBox";
import { NavigationButtons } from "../components/NavigationButtons";

export function AddInstitutePage() {
  const api = usePumaContextApi();
  const history = useCustomHistory();
  const { reload, portalUser, preselectedEducationalInstituteType } =
    React.useContext(PortalUserContext);

  const t = useTranslate();
  const classes = useStyles();

  const [institute, setInstitute] = useState<ResponsesInstituteResponse | null>(null);

  return (
    <PageWrapper title={t("connectInstituteToVeritasAccount")}>
      <ColumnFlexBox className={classes.pageWrapper}>
        <EducationalInstituteForm
          onInstituteSelect={(institute) => {
            setInstitute(institute);
          }}
          refreshFormWhenTrue={false}
          alreadySelectedInstituteIds={portalUser.institutes?.map((x) => x.id!) || []}
          alreadySelectedEducationalInstituteType={preselectedEducationalInstituteType}
        />
        <NavigationButtons
          onSave={async () => {
            await api.portalUsers.portalUsersInstitutesCreate({
              instituteId: institute?.id!,
            });
            reload();
            history.goBack();
          }}
          disabled={_.isNil(institute)}
        />
      </ColumnFlexBox>
    </PageWrapper>
  );
}

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(2),
    borderRadius: 8,
    width: "250px",
    alignSelf: "center",
  },
  pageWrapper: {
    width: EXTENDED_NORMAL_WIDTH + "px",
  },
}));
