import React, { useMemo, useState } from "react";
import { InfoIconType } from "../../../../components/Formik/FieldInfoIcon";
import { useTranslate } from "../../../../utils/customHooks/useTranslate";
import { PumaContextApi, ResponsesInstituteResponse, usePumaContextApi } from "../../../../api";
import { useAsyncQuerier } from "../../../../utils/customHooks/useAsyncQuerier";
import { GetQueryType } from "../../../../utils/generics";
import _ from "lodash";
import {
  Box,
  CircularProgress,
  makeStyles,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from "@material-ui/core";
import { RowFlexBox } from "../../../../components/Boxes/RowFlexBox";
import { useStateRef } from "../../../../utils/customHooks/useStateRef";
import { ListElement } from "../../../../components";

type SchoolTeacherApprovalFieldProps = TextFieldProps & {
  institute: ResponsesInstituteResponse;
  onInputChange: (isValidApprovalCode: boolean, code: string | null) => void;
  labelled?: boolean;
};

type Query = GetQueryType<
  PumaContextApi["institutes"]["institutesValidateTeacherApprovalCodeList"],
  0
>;

export function SchoolTeacherApprovalField(props: SchoolTeacherApprovalFieldProps) {
  const [proofOfAuthInfoIcon, setProofOfAuthInfoIcon] = useState<InfoIconType>(InfoIconType.Info);
  const api = usePumaContextApi();
  const t = useTranslate();
  const theme = useTheme();

  const query = useAsyncQuerier({
    asyncFunction: async (query: Query) => {
      if (_.isEmpty(query.TeacherApprovalCode)) {
        setProofOfAuthInfoIcon(InfoIconType.Info);
        props.onInputChange(false, null);
        return;
      }
      const result = (await api.institutes.institutesValidateTeacherApprovalCodeList(query)).data;
      setProofOfAuthInfoIcon(result === true ? InfoIconType.Approved : InfoIconType.Error);
      props.onInputChange(result === true, query.TeacherApprovalCode!);
    },
    initialQuery: { TeacherApprovalCode: "", InstituteId: props.institute.id! },
    debounce: 500,
    options: {
      executeOnMount: false,
    },
  });

  const classes = useStyles();
  const formHelperTextRef = useStateRef<HTMLParagraphElement>();
  const placeholder = useMemo(
    () =>
      !props.institute.hasValidTeacherApprovalCode
        ? t("instituteHasNoValidApprovalCode")
        : "123-abc-456",
    [t, props.institute.hasValidTeacherApprovalCode]
  );

  const textFieldProps: TextFieldProps = {
    onChange: (e) => query.reload({ TeacherApprovalCode: e.currentTarget.value }),
    disabled: !props.institute.hasValidTeacherApprovalCode,
    placeholder: placeholder,
    InputProps: {
      endAdornment: <>{query.loading ? <CircularProgress color="inherit" size={20} /> : null}</>,
    },
    fullWidth: true,
    helperText:
      (!props.institute.hasValidTeacherApprovalCode && t("instituteHasNoValidApprovalCode")) ||
      (proofOfAuthInfoIcon === InfoIconType.Error && t("approvalCodeInvalid")),
    FormHelperTextProps: {
      className: classes.helperText,
      ref: formHelperTextRef,
    },
    variant: "outlined",
  };
  return (
    <>
      <RowFlexBox mt={2} alignItems={"center"}>
        <Typography>VERITAS-Berechtigungscode eingeben:</Typography>
      </RowFlexBox>
      <RowFlexBox mt={2} alignItems={"center"}>
        <TextField {...props} {...textFieldProps} placeholder={"xxxxxx"} />
      </RowFlexBox>
      <RowFlexBox alignItems={"center"}>
        <Box component={"ul"} sx={{ px: 2.5 }}>
          <ListElement sx={{ color: theme.palette.error.main }} text={t("authorizationCodeRule")} />
          <ListElement
            sx={{ color: theme.palette.error.main }}
            text={t("authorizationCodeRule2")}
          />
          <ListElement
            sx={{ color: theme.palette.error.main }}
            text={t("authorizationCodeRule3")}
          />
          <ListElement
            sx={{ color: theme.palette.error.main }}
            text={t("authorizationCodeRule4")}
          />
        </Box>
      </RowFlexBox>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  helperText: {
    marginLeft: 0,
    color: theme.palette.error.main,
  },
}));
