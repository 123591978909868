import React from "react";
import { ContentBox } from "../../../components/ContentBox";
import { ContentWrapper, ContentWrapperFlexBoxProps } from "../../../components/ContentWrapper";
import { Checkbox, Typography } from "@material-ui/core";
import { useTranslate } from "../../../utils/customHooks/useTranslate";
import { RowFlexBox } from "../../../components/Boxes/RowFlexBox";
import { useStateRef } from "../../../utils/customHooks/useStateRef";
import { CustomIcon } from "../../../components/icons/CustomIcon";
import { ColumnFlexBox } from "../../../components/Boxes/ColumnFlexBox";
import { usePumaContextApi } from "../../../api";
import { MailingConsentTarget } from "./PostNewsletter";
import { PortalUserContext } from "../../PortalUserProvider";

type EmailNewsletterProps = ContentWrapperFlexBoxProps & {
  isSubscribed: boolean;
};

export function EmailNewsletter(props: EmailNewsletterProps) {
  const t = useTranslate();
  const pumaApi = usePumaContextApi();
  const reload = React.useContext(PortalUserContext).reload;
  
  const checkboxRef = useStateRef<HTMLButtonElement>();

  return (
    <ContentWrapper {...props}>
      <ContentBox flexDirection={"row"} alignItems={"center"}>
        <ColumnFlexBox>
          <RowFlexBox alignItems={"center"}>
            <Checkbox
              ref={checkboxRef}
              defaultChecked={props.isSubscribed}
              onChange={async (e) => {
                await pumaApi.portalUsers.portalUsersMailingConsentUpdate({
                  Target: MailingConsentTarget.Email,
                  Subscribed: e.currentTarget.checked,
                });
                reload();
              }}
            />
            <Typography variant={"body2"}>{t("iWouldLikeToReceiveEmailNewsletter")}</Typography>
          </RowFlexBox>
          <RowFlexBox alignItems={"center"} paddingLeft={`${checkboxRef.current?.offsetWidth}px`}>
            <Typography variant={"caption"}>{t("weSendAWeeklyEmailNewsletter")}</Typography>
          </RowFlexBox>
        </ColumnFlexBox>
        <CustomIcon name="Newsletter" height={40} width={40} />
      </ContentBox>
    </ContentWrapper>
  );
}
