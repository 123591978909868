import { User, UserManager } from 'oidc-client'
import * as React from 'react'
import { Center } from '../components'
import { DelayedRender, Loading } from '../components'
import { IAuthConfiguration } from './IdentityConfigLoader'
import {debounce} from "@material-ui/core";

interface IGetTokenResponse {
  id_token: string
  access_token: string
}

/**
 * Props of the context children.
 */
export interface IChildrenProps {
  authority: string
  isAuthenticated: boolean
  user: User
  login: () => void
  logout: () => void
  silentSignin: () => void
  getUser: () => User | null
  getUserAsync: () => Promise<User | null>
  getToken: () => Promise<IGetTokenResponse>
  portalUserId: string
}

interface IProps {
  children: (_: IChildrenProps) => React.ReactElement
  config: IAuthConfiguration
  redirectPostfix: string
  silentRedirectPostfix: string
  responseType: string
  scope: string
}

/**
 * Provides authentication functionality to all children.
 * Also triggers the login.
 * @param props
 */
export function OidcAuthenticator(props: IProps) {
  let needToInit = true
  if ((window as any)['__userManager__'] != null) {
    needToInit = false
  }
  const userManager = ((window as any)['__userManager__'] ||
      new UserManager({
        authority: props.config.authority,
        client_id: props.config.clientId,
        redirect_uri: generateUri(props.config.redirectUri, props.redirectPostfix),
        silent_redirect_uri: generateUri(props.config.silentRedirectUri, props.silentRedirectPostfix),
        post_logout_redirect_uri: props.config.redirectUri,
        response_type: props.responseType,
        scope: props.scope,
        client_secret: props.config.clientSecret,
        automaticSilentRenew: true,
        validateSubOnSilentRenew: true,
        monitorSession: true,
        // checkSessionInterval: 1000,
        extraQueryParams: {
          returnUrl: "https://localhost:4300/profile/user/data/address/add",
        },
      })) as UserManager
  ;(window as any)['__userManager__'] = userManager

  const [errorMessage] = React.useState<string | null>(null)
  const [user, setUser] = React.useState<User | null>(null)

  if (needToInit) {
    window.console.log('Need to init UserManager')
    
    userManager.events.addSilentRenewError(error => {
      console.log('Silentrenew Error', error)
    })

    userManager.events.addUserLoaded(usr => {
      setUser(usr)
    })
    
    userManager.events.addUserSignedOut(async () => {
      await userManager.removeUser();
      await login();
    })
    
    userManager.events.addAccessTokenExpiring(debounce(() => {
      console.log('AccessToken is expiring. Renewing...')
      userManager
          .signinSilent()
          .then(user => {
            setUser(user);
            console.log('Silentrenew in AccessTokenExpiring event successful');
          })
          .catch((error: Error) => {
            console.error(error)
          })
    }, 1000));
    
  }

  function isAuthenticated() {
    return user != null
  }

  function hasError() {
    return errorMessage != null && errorMessage !== ''
  }

  async function login() {
    userManager.signinRedirect()
  }

  async function logout(): Promise<void> {
    const { id_token } = await getToken()
    return userManager.signoutRedirect({
      id_token_hint: id_token,
      post_logout_redirect_uri: props.config.redirectUri,
    })
  }

  function silentSignin(): void {
    userManager
        .signinSilent()
        .then(user => setUser(user))
        .catch(error => console.log('function silentSignin', error))
  }

  function getUser(): User | null {
    return user
  }

  async function getUserAsync(): Promise<User | null> {
    return userManager.getUser()
  }

  React.useEffect(() => {
    if (window.location.href.includes(props.redirectPostfix)) {
      userManager.signinCallback().then(usr => {
        setUser(usr)
        window.location.href = props.config.redirectUri
      })
    } else {
      userManager.getUser().then(usr => {
        if (!usr) {
          console.log("Login needed...");
          login()
        } else {
          setUser(usr)
        }
      })
    }
    // eslint-disable-next-line
  }, [])

  async function getToken() {
    if (!user || user.expired) {
      let temp = await userManager.signinSilent()
      setUser(temp)
    }
    if (user) {
      return { id_token: user.id_token, access_token: user.access_token }
    }
    return { id_token: 'user.id_token', access_token: 'user.access_token' }
  }

  // Render
  if (isAuthenticated()) {
    return (
        <DelayedRender delay={100}>
          {props.children({
            authority: props.config.authority,
            isAuthenticated: isAuthenticated(),
            user: user!,
            login: () => login(),
            logout: () => logout(),
            silentSignin: () => silentSignin(),
            getUser: () => getUser(),
            getUserAsync: () => getUserAsync(),
            getToken: () => getToken(),
            portalUserId: user!.profile.surrogate_id || user!.profile.sub,
            ...props,
          })}
        </DelayedRender>
    )
  }

  if (hasError()) {
    return <Center>{errorMessage}</Center>
  }

  return <Loading msg={''} />
}

function generateUri(uri: string, postfix: string) {
  if (postfix === undefined || postfix === null) return uri

  let completeUri = uri
  if (!completeUri.endsWith('/') && !postfix.startsWith('/')) {
    completeUri = completeUri.concat('/')
  }
  return completeUri.concat(postfix)
}
