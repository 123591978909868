import * as React from "react";
import { Route } from "react-router-dom";
import { AddSchoolPage } from "../../pages/AddSchoolPage";
import { AddAddressPage } from "../../pages/profile/dataAndAddress/AddAddressPage";
import { EditAddressPage } from "../../pages/profile/dataAndAddress/EditAddressPage";
import { PersonalDataAndAddressesPage } from "../../pages/profile/dataAndAddress/PersonalDataAndAddressesPage";
import { EditPersonalDataPage } from "../../pages/profile/dataAndAddress/EditPersonalDataPage";
import { ProfileHomePage } from "../../pages/profile/ProfileHomePage";
import { routes } from "../../routes/routes";
import { SettingsAndNewslettersPage } from "./settingsAndNewsletters/SettingsAndNewslettersPage";
import { SettingsAndNewslettersEditPage } from "./settingsAndNewsletters/SettingsAndNewslettersEditPage";
import { AddInstitutePage } from "../../pages/AddInstitutePage";
import { ExternalAddAddressPage } from "../externalEdits/ExternalAddAddressPage";
import { ExternalModifyMailingConsentPage } from "../externalEdits/ExternalModifyMailingConsentPage";
import { ExternalModifyAddressPage } from "../externalEdits/ExternalModifyAddressPage";
import { ExternalVerifyInstitutePage } from "../externalEdits/ExternalVerifyInstitutePage";

export function UserProfileRoutes() {
  return (
    <>
      <Route exact path={[routes.userProfile, "/"]} component={ProfileHomePage} />
      <Route exact path={routes.userProfileData} component={PersonalDataAndAddressesPage} />
      <Route exact path={routes.userProfilePersonalDataEdit} component={EditPersonalDataPage} />
      <Route exact path={routes.userProfileAddAddress} component={AddAddressPage} />
      <Route exact path={routes.userProfileEditAddress} component={EditAddressPage} />
      <Route
        exact
        path={routes.userProfileSettingsAndNewsletters}
        component={SettingsAndNewslettersPage}
      />
      <Route
        exact
        path={routes.userProfileSettingsAndNewslettersEdit}
        component={SettingsAndNewslettersEditPage}
      />
      <Route exact path={routes.userProfileAddSchool} component={AddSchoolPage} />
      <Route exact path={routes.userProfileAddInstitute} component={AddInstitutePage} />
      <ExternalEditRoutes />
    </>
  );
}

function ExternalEditRoutes() {
  return (
    <>
      <Route
        exact
        path={[routes.externalEditModifyAddress, routes.externalEditModifyAddressAlt]}
        component={ExternalModifyAddressPage}
      />
      <Route exact path={routes.externalEditAddAddress} component={ExternalAddAddressPage} />
      <Route
        exact
        path={routes.externalEditModifyMailingConsent}
        component={ExternalModifyMailingConsentPage}
      />
      <Route
        exact
        path={[routes.externalEditVerifyInstitute, routes.externalEditVerifyInstituteAlt]}
        component={ExternalVerifyInstitutePage}
      />
    </>
  );
}
