import React, { useEffect } from "react";
import { TraitGroup, TraitsDropdownProps } from "./TraitsDropdown";
import { useTranslate } from "../../utils/customHooks/useTranslate";
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";
import { GetTraitDataGetTraitDataResponse, usePumaContextApi } from "../../api";
import { useRegistrationContext } from "../../utils/customHooks/useRegistrationContext";
import { Option } from "../Formik";
import { Box, TextField } from "@material-ui/core";
import { useAsyncQuerier } from "../../utils/customHooks/useAsyncQuerier";
import { BoldTypography } from "../BoldTypography";
import { ColumnFlexBox } from "../Boxes/ColumnFlexBox";
import { OccupationTitlesDropdown } from "./OccupationTitlesDropdown";
import { UserType } from "../../utils/beEnumMapping";

type FunctionsDropdownProps = Omit<TraitsDropdownProps, "traitGroups" | "label"> & {
  label?: string;
};

export enum FunctionAtSchoolSubGroupEnum {
  GeneralFunction = 1,
  StudentTeacher = 2,
  SubjectCoordinator = 3,
}

export function FunctionsDropdown(props: FunctionsDropdownProps) {
  const t = useTranslate();
  const pumaApi = usePumaContextApi();
  const { formik } = useRegistrationContext();
  const isTeacher = formik.values.userType === UserType.Teacher;

  const [allFunctionsOnSchool, setAllFunctionsOnSchool] = React.useState<
    GetTraitDataGetTraitDataResponse[]
  >([]);

  const [generalFunctionOptions, setGeneralFunctionOptions] = React.useState<Option<number>[]>([]);
  const [selectedGeneralFunctions, setSelectedGeneralFunctions] = React.useState<Option<number>[]>(
    []
  );

  const [subjectCoordinatorOptions, setSubjectCoordinatorOptions] = React.useState<
    Option<number>[]
  >([]);
  const [selectedSubjectCoordinators, setSelectedSubjectCoordinators] = React.useState<
    Option<number>[]
  >([]);

  const [studentTeacherOptions, setStudentTeacherOptions] = React.useState<Option<number>[]>([]);
  const [selectedStudentTeacherFunctions, setSelectedStudentTeacherFunctions] =
    React.useState<Option<number>>();

  const dataRequest = useAsyncQuerier({
    asyncFunction: getFunctionsAtSchool,
    options: {
      onSuccess: (r, options) => {
        setAllFunctionsOnSchool(r);
      },
    },
    initialQuery: {},
  });

  useEffect(() => {
    setGeneralFunctionOptions(
      allFunctionsOnSchool
        .filter((it) => it.traitSubGroupId === FunctionAtSchoolSubGroupEnum.GeneralFunction)
        .map((it) => mapTraitToOption(it))
    );

    setSubjectCoordinatorOptions(
      allFunctionsOnSchool
        .filter((it) => it.traitSubGroupId === FunctionAtSchoolSubGroupEnum.SubjectCoordinator)
        .map((it) => mapTraitToOption(it))
    );

    setStudentTeacherOptions(
      allFunctionsOnSchool
        .filter((it) => it.traitSubGroupId === FunctionAtSchoolSubGroupEnum.StudentTeacher)
        .map((it) => mapTraitToOption(it))
    );
  }, [allFunctionsOnSchool]);

  useEffect(() => {
    setSelectedGeneralFunctions(
      generalFunctionOptions.filter((it) =>
        formik.values.functionsAtSchool.includes(it.key as number)
      )
    );
  }, [generalFunctionOptions, formik.values.functionsAtSchool]);

  useEffect(() => {
    setSelectedSubjectCoordinators(
      subjectCoordinatorOptions.filter((it) =>
        formik.values.functionsAtSchool.includes(it.key as number)
      )
    );
  }, [subjectCoordinatorOptions, formik.values.functionsAtSchool]);

  useEffect(() => {
    setSelectedStudentTeacherFunctions(
      studentTeacherOptions.find((it) => formik.values.functionsAtSchool.includes(it.key as number))
    );
  }, [studentTeacherOptions, formik.values.functionsAtSchool]);

  function updateFormikFunctions(
    selectedGenerallFunctions: Option<number>[],
    selectedSubjectCoordinators: Option<number>[],
    selectedStudentTeacherFunctions: Option<number> | undefined | null
  ) {
    formik.setFieldValue(
      "functionsAtSchool",
      Array.from(
        new Set([
          ...selectedGenerallFunctions,
          ...selectedSubjectCoordinators,
          ...(selectedStudentTeacherFunctions ? [selectedStudentTeacherFunctions] : [])
        ]).values()
      ).map((it) => it.value)
    );
  }

  return (
    <>
      {isTeacher && (
        <ColumnFlexBox width={"100%"}>
          <BoldTypography variant={"body2"}>{t("occupationTitle")}</BoldTypography>
          <Box mt={1} mb={2}>
            <OccupationTitlesDropdown
              TextFieldProps={{ margin: "none", variant: "outlined" }}
              name={formik.propertyNames.occupationTitleId}
            />
          </Box>
        </ColumnFlexBox>
      )}
      <ColumnFlexBox width={"100%"}>
        <BoldTypography variant={"body2"}>{t("functionsAtTheSchool")}</BoldTypography>
        <Box mt={1} mb={2}>
          <Autocomplete
            options={generalFunctionOptions}
            value={selectedGeneralFunctions}
            onChange={(_, value) => {
              setSelectedGeneralFunctions(value);
              updateFormikFunctions(
                value,
                selectedSubjectCoordinators,
                selectedStudentTeacherFunctions
              );
            }}
            multiple={true}
            renderInput={(params) => renderInput(params, t("functionsAtTheSchool"))}
            getOptionLabel={(it) => it.displayValue}
            loading={dataRequest.loading}
          />
        </Box>
      </ColumnFlexBox>
      <ColumnFlexBox width={"100%"}>
        <BoldTypography variant={"body2"}>{t("subjectCoordinator")}</BoldTypography>
        <Box mt={1} mb={2}>
          <Autocomplete
            options={subjectCoordinatorOptions}
            value={selectedSubjectCoordinators}
            onChange={(_, value) => {
              setSelectedSubjectCoordinators(value);
              updateFormikFunctions(
                selectedGeneralFunctions,
                value,
                selectedStudentTeacherFunctions
              );
            }}
            multiple
            renderInput={(params) => renderInput(params, t("subjectCoordinator"))}
            getOptionLabel={(it) => it.displayValue}
            loading={dataRequest.loading}
          />
        </Box>
      </ColumnFlexBox>
      <ColumnFlexBox width={"100%"}>
        <BoldTypography variant={"body2"}>{t("studentTeacher")}</BoldTypography>
        <Box mt={1} mb={2}>
          <Autocomplete
            options={studentTeacherOptions}
            value={selectedStudentTeacherFunctions}
            onChange={(_, value) => {
              setSelectedStudentTeacherFunctions(value ?? undefined);
              updateFormikFunctions(
                selectedGeneralFunctions,
                selectedSubjectCoordinators,
                value
              );
            }}
            renderInput={(params) => renderInput(params, t("studentTeacher"))}
            getOptionLabel={(it) => it.displayValue}
            loading={dataRequest.loading}
          />
        </Box>
      </ColumnFlexBox>
    </>
  );

  function renderInput(params: AutocompleteRenderInputParams, label: string) {
    return (
      <TextField
        {...params}
        label={label}
        error={hasError()}
        helperText={hasError() ? errorText() : undefined}
        variant="outlined"
      />
    );
  }

  async function getFunctionsAtSchool(query: object | undefined, ...args: any[]) {
    return (
      (
        await pumaApi.autocomplete.autocompleteTraitsList({
          TraitGroupName: TraitGroup.Functions,
          ParentId: props.parent,
        })
      ).data ?? []
    );
  }

  function errorText() {
    return formik.errors[formik.propertyNames.functionsAtSchool];
  }

  function hasError() {
    return errorText() != null;
  }

  function mapTraitToOption(traitDto: GetTraitDataGetTraitDataResponse): Option<number> {
    return {
      key: traitDto.id!,
      value: traitDto.id!,
      displayValue: traitDto.description!,
    };
  }
}
