import React from "react";
import { ColumnFlexBox } from "../../../components/Boxes/ColumnFlexBox";
import { UserEmail } from "./UserEmail";
import { UserName } from "./UserName";
import { UserTypeIcon } from "./UserTypeIcon";
import { UserTypeText } from "./UserTypeText";
import { RowFlexBox } from "../../../components/Boxes/RowFlexBox";
import { UserFullName } from "./UserFullName";

export function UserBasicInfoWidgetNormal() {
  return (
    <RowFlexBox justifyContent={"space-between"}>
      <ColumnFlexBox>
        <UserFullName />
        <UserName />
        <UserEmail />
      </ColumnFlexBox>
      <ColumnFlexBox alignItems="center" width={"fit-content"}>
        <UserTypeIcon />
        <UserTypeText align="center" />
      </ColumnFlexBox>
    </RowFlexBox>
  );
}
