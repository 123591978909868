import {Box, makeStyles, Theme} from "@material-ui/core";
import React from "react";
import {VerifiedSchoolIcon} from "./VerifiedSchoolIcon";
import {NotVerifiedSchoolIcon} from "./NotVerifiedSchoolIcon";
import {MainAssignmentRadioButton} from "./MainAssignmentRadioButton";
import {BoldTypography} from "../../../components/BoldTypography";
import {RowFlexBox, RowFlexBoxProps} from "../../../components/Boxes/RowFlexBox";
import {ColumnFlexBox} from "../../../components/Boxes/ColumnFlexBox";
import {useIsMobileScreen} from "../../../utils/customHooks/useIsMobileScreen";
import {ResponsesInstituteResponse} from "../../../api";
import {SchoolTeacherApprovalField} from "../instituteAssignment/instituteWithSchoolCode/SchoolTeacherApprovalField";
import {DeleteIconButton} from "../../../components/DeleteIconButton";
import {useTranslate} from "../../../utils/customHooks/useTranslate";
import {InstituteWidgetWrapper} from "../shared/InstituteWidgetWrapper";
import {InstituteWidgetTitle} from "../shared/InstituteWidgetTitle";
import {InstituteWidgetMainAddress} from "../shared/InstituteWidgetMainAddress";
import {IncludeWhen} from "../../../utils/generics";
import clsx from "clsx";

type Props<ShowMainAssignmentRadio extends boolean | undefined> = RowFlexBoxProps & {
  institute: ResponsesInstituteResponse;
  isVerified: boolean;
  onValidVerificationCode?: (code: string) => void;
  onRemoveClick?: () => void;
  additionalInfo?: React.ReactNode;
  showMainAssignmentRadio?: ShowMainAssignmentRadio;
} & IncludeWhen<
    ShowMainAssignmentRadio,
    true,
    {
      isMainAssignment: boolean;
      setAsMainAssignment: () => void;
    }
  >;

export function SchoolWidget<ShowMainAssignmentRadio extends boolean | undefined>(
  props: Props<ShowMainAssignmentRadio>
) {
  const isMobile = useIsMobileScreen();
  const classes = useStyles(props);
  const t = useTranslate();

  return (
    //@ts-ignore
    <RowFlexBox width={"fill-available"} {...props}>
      <InstituteWidgetWrapper className={classes.isVerifiedColor}>
        <RowFlexBox justifyContent={"space-between"}>
          <ColumnFlexBox>
            <InstituteWidgetTitle institute={props.institute} mb={0.5} />

            {props.institute.mainAddress && (
              <InstituteWidgetMainAddress institute={props.institute} mt={0.5} />
            )}
            {props.showMainAssignmentRadio && (
              <MainAssignmentRadioButton
                mt={1}
                isMainAssignment={(props as unknown as Props<true>).isMainAssignment}
                setAsMainAssignment={(props as unknown as Props<true>).setAsMainAssignment}
              />
            )}
          </ColumnFlexBox>
          {props.isVerified ? <VerifiedSchoolIcon /> : <NotVerifiedSchoolIcon />}
        </RowFlexBox>

        {!props.isVerified && (
          <Box mt={1}>
            {isMobile && (
              <BoldTypography color={"error"}>{t("authorizationCodeMissing")}.</BoldTypography>
            )}
            <SchoolTeacherApprovalField
              className={clsx(classes.textfieldBackground, classes.marginBottom)}
              institute={props.institute}
              onInputChange={(isValidApprovalCode, code) => {
                if (!isValidApprovalCode) return;

                props.onValidVerificationCode && props.onValidVerificationCode(code!);
              }}
            />
          </Box>
        )}
      </InstituteWidgetWrapper>
      {props.onRemoveClick && (
        <ColumnFlexBox justifyContent={"end"} ml={1.5}>
          <DeleteIconButton onRemove={props.onRemoveClick} />
        </ColumnFlexBox>
      )}
    </RowFlexBox>
  );
}

//#region styles
const useStyles = makeStyles<Theme, { isVerified: boolean }>((theme) => ({
  isVerifiedColor: {
    borderColor: (props) => (!props.isVerified ? theme.palette.error.dark : undefined),
  },
  underline: {
    textDecoration: "underline",
  },
  title: {
    overflowWrap: "break-word",
  },
  textfieldBackground: {
    marginRight: theme.spacing(1),
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.background.paper,
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.background.default,
      },
    },
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  radioButton: {
    padding: 0,
    marginRight: theme.spacing(0.5),
  },
}));
//#endregion
