import React from "react";
import FormikAutocompleteAsync, {
  FormikAutocompleteAsyncProps,
} from "../Formik/FormikAutocompleteAsync";
import { KeyValuePair2Int32String, usePumaContextApi } from "../../api";
import { makeStyles } from "@material-ui/core";
import { useTranslate } from "../../utils/customHooks/useTranslate";
import { mapKvpNumberStringToOption } from "../../utils/mappers";

type OccupationTitlesDropdownProps = Omit<
  FormikAutocompleteAsyncProps<KeyValuePair2Int32String, number, true, true, false>,
  "options" | "dataFetcher" | "optionsMapper" | "label"
> & {
  sendRequestOnChange?: boolean;
};

export function OccupationTitlesDropdown(props: OccupationTitlesDropdownProps) {
  const pumaApi = usePumaContextApi();
  const classes = useStyles();
  const t = useTranslate();

  return (
    <FormikAutocompleteAsync
      {...props}
      TextFieldProps={{
        variant: "outlined",
        className: classes.textfieldBackground,
        ...props.TextFieldProps,
      }}
      dataFetcher={async () => {
        return (await pumaApi.autocomplete.autocompleteOccupationTitlesList()).data || [];
      }}
      fullWidth
      onChange={async (_, value, reason, x) => {
        if (props.sendRequestOnChange !== true) return;
        // if (reason === "select-option") {
        await pumaApi.portalUsers.portalUsersUpdateOccupationTitleUpdate({
          occupationTitleId: x?.option.value!,
        });
      }}
      label={t("occupationTitle")}
      optionsMapper={mapKvpNumberStringToOption}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  textfieldBackground: {
    [theme.breakpoints.down("xs")]: {
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
}));
