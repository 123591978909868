import React from "react";
import { ContentWrapper, ContentWrapperFlexBoxProps } from "../../../components/ContentWrapper";
import { ContentBox } from "../../../components/ContentBox";
import { ColumnFlexBox } from "../../../components/Boxes/ColumnFlexBox";
import { RowFlexBox } from "../../../components/Boxes/RowFlexBox";
import { Box, Checkbox, makeStyles, Typography } from "@material-ui/core";
import { CustomIcon } from "../../../components/icons/CustomIcon";
import { useTranslate } from "../../../utils/customHooks/useTranslate";
import { PortalUserContext } from "../../PortalUserProvider";
import { usePumaContextApi } from "../../../api";
import { useCustomHistory } from "../../../utils/customHooks/useCustomHistory";
import { routes } from "../../../routes/routes";

export enum MailingConsentTarget {
  Email,
  Post,
}

type PostNewsletterProps = Omit<ContentWrapperFlexBoxProps, "children"> & {
  isSubscribed: boolean;
};

export function PostNewsletter(props: PostNewsletterProps) {
  const t = useTranslate();
  const pumaApi = usePumaContextApi();
  const reload = React.useContext(PortalUserContext).reload;
  const history = useCustomHistory();
  const classes = useStyles();

  return (
    <ContentWrapper {...props}>
      <ContentBox flexDirection={"row"} alignItems={"center"} pb={0}>
        <ColumnFlexBox>
          <RowFlexBox alignItems={"center"}>
            <Checkbox
              defaultChecked={props.isSubscribed}
              onChange={async (e) => {
                await pumaApi.portalUsers.portalUsersMailingConsentUpdate({
                  Target: MailingConsentTarget.Post,
                  Subscribed: e.currentTarget.checked,
                });
                reload();
              }}
            />
            <Typography variant={"body2"}>{t("iWouldLikeToReceivePostNewsletter")}</Typography>
          </RowFlexBox>
          <RowFlexBox alignItems={"top"}>
            <Box pr={1}>
              <CustomIcon name="Post" height={80} width={80} />
            </Box>
            <ColumnFlexBox>
              <Typography variant={"caption"}>{t("weSendPostNewsletter")}</Typography>
              <Box mt={1}>
                <Typography
                  variant={"body2"}
                  className={classes.link}
                  onClick={() => history.navigate(routes.userProfileData)}
                >
                  {t("myAddresses")}
                </Typography>
              </Box>
            </ColumnFlexBox>
          </RowFlexBox>
        </ColumnFlexBox>
      </ContentBox>
    </ContentWrapper>
  );
}

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer", // it was grey when not empty so had to override for consistency
    },
  },
}));
