import React from "react";
import { useProfileHelper } from "./useProfileHelper";
import { useTranslate } from "../../../utils/customHooks/useTranslate";
import { useCustomHistory } from "../../../utils/customHooks/useCustomHistory";
import _ from "lodash";
import { PlusTextButton } from "../../../components/PlusTextButton";
import { routes } from "../../../routes/routes";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { EducationalInstituteWidget } from "../../institutes/EducationalInstituteWidget/EducationalInstituteWidget";
import { usePumaContextApi } from "../../../api";
import { NavigationButton, NavigationButtons } from "../buttons/NavigationButtons";

export function KindergartenTeacherProfilePage() {
  const { reload, portalUser } = useProfileHelper();
  const api = usePumaContextApi();
  const t = useTranslate();
  const history = useCustomHistory();
  const classes = useStyles();
  return (
    <>
      {!_.isNil(portalUser.institutes) && portalUser.institutes?.length > 0 ? (
        <>
          <Typography variant={"h3"} className={classes.linkedSchoolsText}>
            {t("linkedEducationalInstitutions")}
          </Typography>
          {portalUser.institutes.map((x) => (
            <EducationalInstituteWidget
              mt={2}
              institute={x}
              onRemoveClick={async () => {
                await api.portalUsers.portalUsersInstitutesDelete(x.id!);
                reload();
              }}
            />
          ))}
          <PlusTextButton
            alignSelf={"flex-start"}
            mt={2}
            flexDirection="column"
            alignItems={"center"}
            text={t("addAnotherEducationalInstitution")}
            onClick={() => history.navigate(routes.userProfileAddInstitute)}
          />
        </>
      ) : (
        <>
          <Box className={classes.noSchoolsButton} width={"100%"} mt={2} alignItems={"center"}>
            <PlusTextButton
              flexDirection="column"
              alignItems={"center"}
              text={t("addInstitute")}
              onClick={() => history.navigate(routes.userProfileAddInstitute)}
            />
          </Box>
        </>
      )}
      <NavigationButtons
        mt={2}
        types={[
          NavigationButton.Orders,
          NavigationButton.Wishlist,
          NavigationButton.SettingsAndNewsletter,
        ]}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  noSchoolsButton: {
    border: `2px ${theme.palette.divider} dashed`,
    padding: `${theme.spacing(2)}px 0`,
  },
  linkedSchoolsText: {
    marginTop: theme.spacing(2),
    alignSelf: "flex-start",
  },
}));
