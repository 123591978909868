import {Box, makeStyles, Typography} from "@material-ui/core";
import React from "react";
import {FlexBox} from "../../../components/Boxes/FlexBox";
import {ButtonWithAddIcon} from "../../../components/ButtonWithAddIcon";
import {ContentWrapper} from "../../../components/ContentWrapper";
import {FullWidthDivider} from "../../../components/FullWidthDivider";
import {CustomIcon} from "../../../components/icons/CustomIcon";
import {PageWrapper} from "../../../components/PageWrapper";
import {PortalUserContext} from "../../../features/PortalUserProvider";
import {UserPersonalDataWidget} from "../../../features/profile/basicInfo/UserPersonalDataWidget";
import {UserPrivateAddressWidget} from "../../../features/profile/basicInfo/UserPrivateAddressWidget";
import {routes} from "../../../routes/routes";
import {useCustomHistory} from "../../../utils/customHooks/useCustomHistory";
import {useTranslate} from "../../../utils/customHooks/useTranslate";
import {EXTENDED_NORMAL_WIDTH, MOBILE_WIDTH} from "../../../utils/layoutHelper";
import {Button} from "../../../components/Button";

export function PersonalDataAndAddressesPage() {
    const t = useTranslate();
    const classes = useStyles();
    const {portalUser} = React.useContext(PortalUserContext);
    const history = useCustomHistory();

    return (
        <PageWrapper title={t("myDataAndAddress")} hideBackButton>
            <ContentWrapper>
                <Box className={classes.wrapper} mt={2}>
                    <FlexBox justifyContent="space-between" mb={2}>
                        <Typography variant={"h3"}>{t("personalData")}</Typography>
                        <CustomIcon
                            name="Icon_Bearbeiten"
                            onClick={() => history.navigate(routes.userProfilePersonalDataEdit)}
                        />
                    </FlexBox>
                    <UserPersonalDataWidget/>
                </Box>
                <FullWidthDivider hideOnMobile mt={2}/>
                <Box className={classes.wrapper} mt={2}>
                    <Typography variant={"h3"}>{t("privateAddresses")}</Typography>
                    {portalUser.addresses?.map((address) => (
                        <Box mt={2} key={address.id}>
                            <UserPrivateAddressWidget address={address}/>
                        </Box>
                    ))}
                    <Box mt={2}>
                        <ButtonWithAddIcon
                            onClick={() => history.navigate(routes.userProfileAddAddress)}
                            buttonText={t("addAddress")}
                        />
                    </Box>
                </Box>
                <Box className={classes.buttonWrapper} mt={2}>
                    <Button buttonText={t("back")} onClick={() => history.navigate(routes.userProfile)}/>
                </Box>
            </ContentWrapper>
        </PageWrapper>
    );
}

//#region styles
const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: EXTENDED_NORMAL_WIDTH + "px",
        [theme.breakpoints.down("xs")]: {
            width: MOBILE_WIDTH + "px",
        },
    },
    buttonWrapper: {
        width: MOBILE_WIDTH + "px",
    },
}));
//#endregion
