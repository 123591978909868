import React from "react";
import { CustomIcon } from "../../components/icons/CustomIcon";
import { useTranslate } from "../../utils/customHooks/useTranslate";
import { Box, Typography } from "@material-ui/core";

type UserRegistrationIncompleteWarningProps = {};

export function UserRegistrationIncompleteWarning(props: UserRegistrationIncompleteWarningProps) {
  const t = useTranslate();
  return (
    <>
      <CustomIcon name={"Störer_Profil"} width={300} height={300} />
      <Typography variant={"body1"}>{t("yourProfileIsIncomplete")}</Typography>
      <Box mt={1}>
        <Typography variant={"h3"}>{t("youCanAlwaysCompleteYourProfile")}</Typography>
      </Box>
    </>
  );
}
