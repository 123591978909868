import React from "react";
export function Center({ children, style }: any) {
  const newstyle = {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...style
  } as React.CSSProperties;
  return <div style={newstyle}>{children}</div>;
}
