import React from "react";
import {PageWrapper} from "../../../components/PageWrapper";
import {ActionType, AddressForm} from "../../../features/profile/address/AddressForm";
import {useTranslate} from "../../../utils/customHooks/useTranslate";

export function AddAddressPage() {
    const t = useTranslate();

    return (
        <PageWrapper title={t("addAddress")} hideBackButton>
            <AddressForm type={ActionType.Add}/>
        </PageWrapper>
    );
}
