import * as Yup from "yup";
import { TranslationFn } from "./customHooks/useTranslate";

const validateNameRegex = RegExp(
  "^[a-zA-Z'´À-ÖØ-Ýß-öù-ýÿŒ-ž˘-˙˝]{2,}([- ][a-zA-Z'´À-ÖØ-Ýß-öù-ýÿŒ-ž˘-˙˝]{2,})*$"
);

function removeConsecutiveSpaces(it: string) {
  return it.replace(/\s{2,}/g, " ");
}

export const PersonalDataValidationObject = (t: TranslationFn) =>
  Yup.object({
    salutation: Yup.string().required(t("pleaseSelectGender")),
    firstName: Yup.string()
      .trim()
      .transform(removeConsecutiveSpaces)
      .required(`${t("firstName")} ${t("isRequired")}`)
      .matches(validateNameRegex, `${t("firstName")} ${t("invalid")}`)
      .test("fistNameEqualsLastname", `${t("fistNameEqualsLastname")}`, function (value) {
        const lastName = this.resolve(Yup.ref("lastName")).toLowerCase();
        return value.toLowerCase() !== lastName;
      }),
    lastName: Yup.string()
      .trim()
      .transform(removeConsecutiveSpaces)
      .required(`${t("lastName")} ${t("isRequired")}`)
      .matches(validateNameRegex, `${t("lastName")} ${t("invalid")}`),
  });
