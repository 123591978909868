import React, { useState } from "react";
import { useTranslate } from "../utils/customHooks/useTranslate";
import { CustomIcon } from "./icons/CustomIcon";
import { useConfirm } from "material-ui-confirm";
import { Button, Dialog, makeStyles, Typography } from "@material-ui/core";
import { ColumnFlexBox } from "./Boxes/ColumnFlexBox";
import { RowFlexBox } from "./Boxes/RowFlexBox";

interface Props {
  onRemove: () => void;
}

export function DeleteIconButton(props: Props) {
  const t = useTranslate();
  const confirmPopup = useConfirm();
  const classes = useStyles();
  const [show, setShow] = useState(false);
  return (
    <>
      <CustomIcon
        name="Icon_Loeschen"
        onClick={async () => {
          // setShow(true);
          try {
            await confirmPopup({
              confirmationText: t("yes"),
              cancellationText: t("no"),
              title: t("delete") + "?",
              content: t("areYouSureThatYouWantToProceedWithDeletion"),
              confirmationButtonProps: {
                variant: "contained",
                className: classes.confirmationButton,
              },
              cancellationButtonProps: { variant: "outlined" },
              dialogProps: {
                // disableBackdropClick: true,
                disableEscapeKeyDown: true,
                className: classes.dialog,
                // open: true, // Typescript requires it but is redundant because it's being set independently in the library
              },
            });

            props.onRemove();
          } catch (e) {
            return;
          }
        }}
      />
      {show && (
        <Dialog open={show} fullWidth>
          <ColumnFlexBox justifyContent={"space-between"}>
            <Typography variant={"h3"}>{t("delete")}?</Typography>
            <Typography>{t("areYouSureThatYouWantToProceedWithDeletion")}</Typography>
            <RowFlexBox justifyContent={"flex-end"}>
              <Button onClick={() => setShow(false)} variant={"outlined"}>
                {t("no")}
              </Button>
              <Button
                onClick={() => {
                  props.onRemove();
                  setShow(false);
                }}
                className={classes.confirmationButton}
              >
                {t("yes")}
              </Button>
            </RowFlexBox>
          </ColumnFlexBox>
        </Dialog>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  confirmationButton: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.error.main,
    },
  },
  dialog: {
    fontFamily: theme.typography.fontFamily,
  },
}));
