import React, { useContext, useMemo } from "react";
import { NavigationButton, NavigationButtons } from "../buttons/NavigationButtons";
import { PortalUserContext } from "../../PortalUserProvider";

export function StudentProfilePage() {
  const { portalUser } = useContext(PortalUserContext);

  const navigationButtonTypes = useMemo(
    () =>
      portalUser.isFourteenOrOlder
        ? [
            NavigationButton.Orders,
            NavigationButton.Wishlist,
            NavigationButton.SettingsAndNewsletter,
          ]
        : [NavigationButton.SettingsAndNewsletter],
    [portalUser.isFourteenOrOlder]
  );
  return (
    <>
      <NavigationButtons mt={3} types={navigationButtonTypes} />
    </>
  );
}
