import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { FlexBox } from "./Boxes/FlexBox";
import { CustomIcon } from "./icons/CustomIcon";

interface AppPageProps {
  iconName: string;
  onClick: () => void;
  additionalText?: string;
}

export const PageNavigationButton = (props: AppPageProps) => {
  const classes = useStyles();

  return (
    <FlexBox alignItems="center" {...props}>
      <CustomIcon name={props.iconName} height={14} width={14} onClick={props.onClick} />
      {props.additionalText && (
        <Typography variant="h3" className={classes.additionalText}>
          {props.additionalText}
        </Typography>
      )}
    </FlexBox>
  );
};

//#region styles
const useStyles = makeStyles((theme) => ({
  additionalText: {
    cursor: "pointer",
    textDecoration: "underline",
    textTransform: "none",
    marginLeft: theme.spacing(),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));
//#endregion
