import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import {
  GetPortalUserByIdGetPortalUserByIdQueryResponseAddressResponse,
  usePumaContextApi,
} from "../../../api";
import { ColumnFlexBox } from "../../../components/Boxes/ColumnFlexBox";
import { FlexBox } from "../../../components/Boxes/FlexBox";
import { DeleteIconButton } from "../../../components/DeleteIconButton";
import { EditIconButton } from "../../../components/EditIconButon";
import { RadioButton } from "../../../components/RadioButton";
import { routes } from "../../../routes/routes";
import { useCustomHistory } from "../../../utils/customHooks/useCustomHistory";
import { useTranslate } from "../../../utils/customHooks/useTranslate";
import { PortalUserContext } from "../../PortalUserProvider";
import { AddressRecipient } from "./AddressRecipient";

interface Props {
  address: GetPortalUserByIdGetPortalUserByIdQueryResponseAddressResponse;
}

export function UserPrivateAddressWidget(props: Props) {
  const api = usePumaContextApi();
  const classes = useStyles();
  const t = useTranslate();
  const history = useCustomHistory();
  const { reload, portalUser } = React.useContext(PortalUserContext);

  let buildingNumber = props.address.buildingNumber;

  return (
    <FlexBox justifyContent="space-between">
      <Box className={classes.wrapper} padding={2}>
        <AddressRecipient address={props.address} variant={"body1"} />
        <Box mt={1}>
          <Typography>
            {props.address.street} {buildingNumber}
            {props.address.buildingNumberAddition ? '/' + props.address.buildingNumberAddition : null}
          </Typography>
          <Typography>
            {props.address.zip} {props.address.city}
          </Typography>
        </Box>
        {portalUser.addresses && portalUser.addresses.length > 1 ? (
          <Box mt={2}>
            <RadioButton
              isChecked={props.address.addressType === 1}
              label={t("mainAddress")}
              onClick={(isChecked) => {
                if (isChecked) {
                  setUserAddressAsMain();
                }
              }}
            />
          </Box>
        ) : null}
      </Box>
      <ColumnFlexBox ml={2} justifyContent="space-between" paddingTop={2} paddingBottom={2}>
        <EditIconButton
          editRoute={{
            route: routes.userProfileEditAddress,
            params: {
              addressId: props.address.id!,
            },
          }}
        />
        <DeleteIconButton onRemove={deleteAddress} />
      </ColumnFlexBox>
    </FlexBox>
  );

  async function setUserAddressAsMain() {
    await api.portalUsers.portalUsersAddressesSetAsMainUpdate(props.address.id!);
    reload();
    history.navigate(routes.userProfileData);
  }

  async function deleteAddress() {
    await api.portalUsers.portalUsersAddressesDelete(props.address.id!);
    reload();
  }
}

//#region styles
const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      background: theme.palette.background.default,
      borderRadius: "8px",
      width: "100%",
      boxSizing: "border-box",

      [theme.breakpoints.down("xs")]: {
        backgroundColor: theme.palette.background.paper,
      },
    },
  };
});
//#endregion
