// @ts-nocheck
import { DetailsResult } from "use-places-autocomplete";
import { PromiseType } from "utility-types";

export type PlaceResult = Exclude<PromiseType<DetailsResult>, string>;

export function addMethodsToPlaceResult(placeResult: PlaceResult): PlaceResultWithMethods {
  return {
    ...placeResult,
    getCountry: () =>
      placeResult.address_components?.find((a) => a.types.includes("country"))?.long_name,
    getCountryCode: () =>
      placeResult.address_components?.find((a) => a.types.includes("country"))?.short_name,
    getZip: () =>
      placeResult.address_components?.find((a) => a.types.includes("postal_code"))?.long_name,
    getCity: () =>
      placeResult.address_components?.find((a) => a.types.includes("locality"))?.long_name,
    getState: () =>
      placeResult.address_components?.find((a) => a.types.includes("administrative_area_level_1"))
        ?.long_name,
    getDistrict: () =>
      placeResult.address_components?.find((a) => a.types.includes("administrative_area_level_2"))
        ?.long_name,
    getStreet: () =>
      placeResult.address_components?.find(
        (a) => a.types.includes("street_address") || a.types.includes("route")
      )?.long_name,
    getHouseNumber: () =>
      placeResult.address_components?.find((a) => a.types.includes("street_number"))?.long_name,
  };
}

export function getPlaceInfo(placeResult: PlaceResult): PlaceInfo {
  const city =
    placeResult.address_components?.find((a) => a.types.includes("locality"))?.long_name || "";
  const isVienna = city === "Wien";

  let district =
    placeResult.address_components?.find((a) =>
      a.types.includes(isVienna ? "sublocality_level_1" : "administrative_area_level_2")
    )?.long_name || "";

  switch (district) {
    case "Gemeinde Goldwörth":
      district = "Urfahr-Umgebung";
      break;
    case "Klagenfurt am Wörthersee":
      district = "Klagenfurt-Stadt";
      break;
  }

  return {
    country:
      placeResult.address_components?.find((a) => a.types.includes("country"))?.long_name || "",
    countryCode:
      placeResult.address_components?.find((a) => a.types.includes("country"))?.short_name || "",
    zip:
      placeResult.address_components?.find((a) => a.types.includes("postal_code"))?.long_name || "",
    city,
    state:
      placeResult.address_components?.find((a) => a.types.includes("administrative_area_level_1"))
        ?.long_name || "",
    district,
    street:
      placeResult.address_components?.find(
        (a) => a.types.includes("street_address") || a.types.includes("route")
      )?.long_name || "",
    houseNumber:
      placeResult.address_components?.find((a) => a.types.includes("street_number"))?.long_name ||
      "",
  };
}

type PlaceResultWithMethods = PlaceResult & PlaceResultMethods;

export interface PlaceResultMethods {
  getCountry(): string | undefined;

  getCountryCode(): string | undefined;

  getZip(): string | undefined;

  getState(): string | undefined;

  getDistrict(): string | undefined;

  getCity(): string | undefined;

  getStreet(): string | undefined;

  getHouseNumber(): string | undefined;
}
