import { generatePath, useHistory } from "react-router";
import { useLocation } from "react-router-dom";

type ParamsType = { [x: string]: string | number | boolean };
type LocationType = { from: string | undefined };

export type RouteType = {
  route: string;
  params?: ParamsType;
};

export function useCustomHistory() {
  const history = useHistory<LocationType>();
  // useLocation is needed for goBack to work properly when updating/adding address for the first time in the UI
  // without useLocation it doesn't go back then, only after the second update it goes back ...
  // It's in need of a refactoring, but hey, I'm in need of time
  useLocation();

  const isGoBackAvailable = history.location.state && history.location.state.from;

  function navigate(route: string, params?: ParamsType) {
    const path = generatePath(route, params);
    history.push(path, { from: history.location.pathname });
  }

  const goBack = () => {
    if (isGoBackAvailable) {
      history.goBack();
    }
  };

  function isCurrentRoute(route: string, params?: ParamsType) {
    const path = generatePath(route, params);
    return history.location.pathname === path;
  }

  return { navigate, goBack, isGoBackAvailable, isCurrentRoute };
}
