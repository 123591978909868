import * as React from "react";
import { FormikErrors } from "formik";
import { makeStyles, Typography } from "@material-ui/core";
import { RadioProps } from "@material-ui/core/Radio";
import { FormHelperTextProps } from "@material-ui/core/FormHelperText";
import { FormControlLabelProps } from "@material-ui/core/FormControlLabel";
import { FormikRadio } from "@dccs/react-formik-mui";
import { FlexBox } from "../Boxes/FlexBox";
import { CustomLabel } from "./CustomLabel";
import { IFormHelperTextWrapperProps } from "@dccs/react-formik-mui/lib/FormHelperTextWrapper";
import { useEffect, useState } from "react";
import { useCustomFormikContext } from "../../utils/customHooks/useCustomFormikContext";

interface Props {
  name: string;
  label?: React.ReactNode;
  error?: boolean;
  errors: FormikErrors<Record<string, any>>;
  useField?: boolean;
  formControlLabelProps?: Omit<FormControlLabelProps, "control" | "label">;
  formHelperTextProps?: FormHelperTextProps;
  formHelperTextWrapperProps?: IFormHelperTextWrapperProps;
  fieldProps?: {};
  validate?: any;
  options: Array<FormikRadioProps>;
}

interface IBaseProps {
  value: any;
  helperText?: string;
  label?: React.ReactNode;
}

export type FormikRadioGroupProps = Props;

export type FormikRadioProps = IBaseProps & RadioProps;

export function FormikRadioGroup(props: FormikRadioGroupProps) {
  const classes = useStyles();
  const formik = useCustomFormikContext();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(
    () => {
      const msg = props.errors[props.name];
      if (msg != null) {
        setErrorMessage(msg as string);
        props.errors[props.name] = undefined;
        return;
      }

      setErrorMessage(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.errors]
  );

  return (
    <form className={classes.wrapper}>
      <CustomLabel label={props.label} />
      <FlexBox flexWrap="wrap">
        {props.options.map((option) => (
          <FormikRadio name={props.name} {...option} />
        ))}
      </FlexBox>
      {errorMessage && (
        <Typography variant={"caption"} className={classes.error}>
          {errorMessage}
        </Typography>
      )}
    </form>
  );
}

//#region styles
const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
  },
}));
//#endregion
