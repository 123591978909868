import React from "react";
import { useTranslate } from "../../utils/customHooks/useTranslate";
import { UserRegistrationIncompleteWarning } from "./UserRegistrationIncompleteWarning";
import { PageWrapper } from "../../components/PageWrapper";
import { ColumnFlexBox } from "../../components/Boxes/ColumnFlexBox";
import { UserRegistrationIncompleteNoSchoolLinkedWarning } from "./UserRegistrationIncompleteNoSchoolLinkedWarning";
import { Button } from "../../components/Button";
import { CircularProgress, makeStyles } from "@material-ui/core";

type FinishRegistrationEarlyPageProps = {
  pageType: InterruptRegistrationPageType;
  onFinishNow: () => void;
  onFinishLater: () => void;
  isSubmitting: boolean;
};

export enum InterruptRegistrationPageType {
  Default,
  NoSchoolVerified,
}

export function InterruptRegistrationPage(props: FinishRegistrationEarlyPageProps) {
  const t = useTranslate();
  const classes = useStyles();

  const title =
    props.pageType === InterruptRegistrationPageType.NoSchoolVerified
      ? t("dontYouWantToLinkSchool")
      : t("completeProfileLater");
  return (
    <PageWrapper hideBackButton title={title}>
      <ColumnFlexBox alignItems={"center"} justifyContent={"center"} textAlign={"center"}>
        {props.pageType === InterruptRegistrationPageType.NoSchoolVerified ? (
          <UserRegistrationIncompleteNoSchoolLinkedWarning />
        ) : (
          <UserRegistrationIncompleteWarning />
        )}
        {
          props.isSubmitting ? <CircularProgress /> :
            <Button
              color={"primary"}
              variant={"contained"}
              onClick={props.onFinishNow}
              className={classes.button}
              buttonText={t("completeNow")}
              disabled={props.isSubmitting}
            />
        }
        {
          props.isSubmitting ? <CircularProgress /> :
            <Button
              color={"primary"}
              variant={"outlined"}
              onClick={props.onFinishLater}
              className={classes.button}
              buttonText={t("completeLater")}
              disabled={props.isSubmitting}
            />
        }
      </ColumnFlexBox>
    </PageWrapper>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    borderRadius: 8,
    width: "250px",
  },
}));
