import React, { useMemo } from "react";
import { InfoBoxData } from "../../../components/InfoBox";
import { CustomerTypes, UserType } from "../../../utils/beEnumMapping";
import { Step } from "../../../utils/customHooks/useRegistrationHandler";
import { TranslationFn, useTranslate } from "../../../utils/customHooks/useTranslate";
import { switchAssertUnreachable } from "../../../utils/objectHelper";
import { RegistrationAddEducationalInstitutions } from "../../institutes/instituteAssignment/educationalInstitution/RegistrationAddEducationalInstitutions";
import { RegistrationAddSchools } from "../../institutes/instituteAssignment/instituteWithSchoolCode/RegistrationAddSchools";
import { RegistrationStep } from "../enum/RegistrationStep";
import { RegistrationBasicInfo } from "../form/RegistrationBasicInfo";
import { IUserRegistrationFormValues } from "../form/UserRegistrationFormHelper";
import { RegistrationExperienceAndRoles } from "../RegistrationExperienceAndRoles";
import { RegistrationMailingConsents } from "../RegistrationMailingConsents";
import { RegistrationSubjects } from "../RegistrationSubjects";
import { GetPortalUserRegistrationInfoGetPortalUserRegistrationInfoResponse } from "../../../api";

const REGISTRATION_STEPS_TEACHER = [
  RegistrationStep.AddSchools,
  RegistrationStep.Subjects,
  RegistrationStep.ExperienceAndRoles,
  RegistrationStep.MailingConsents,
];

const REGISTRATION_STEPS_TEACHER_IN_KINDERGARTEN = [
  RegistrationStep.AddEducationalInstitutions,
  RegistrationStep.Subjects,
  RegistrationStep.MailingConsents,
];

const REGISTRATION_STEPS_STUDENTS = [RegistrationStep.MailingConsents];
const REGISTRATION_STEPS_OTHERS = [RegistrationStep.MailingConsents];

/**
 * User is registered only if both first and last name are populated
 * @param portalUser
 * @returns true if user is registered, otherwise false
 */

export type RegistrationStepModel = Step<IUserRegistrationFormValues, InfoBoxData>;

export function useUserTypeRegistrationSteps<FormikValue>(
  userType: UserType | null
): RegistrationStepModel[] {
  const t = useTranslate();
  let steps: RegistrationStep[] = [RegistrationStep.BasicInfo];
  switch (userType) {
    case UserType.Teacher:
      steps = steps.concat(REGISTRATION_STEPS_TEACHER);
      break;
    case UserType.KindergartenTeacher:
      steps = steps.concat(REGISTRATION_STEPS_TEACHER_IN_KINDERGARTEN);
      break;
    case UserType.Student:
      steps = steps.concat(REGISTRATION_STEPS_STUDENTS);
      break;
    case UserType.Other:
      steps = steps.concat(REGISTRATION_STEPS_OTHERS);
      break;
    case null:
      break;
    default:
      switchAssertUnreachable(userType);
  }

  return useMemo(
    () =>
      steps.map((x) => ({
        type: x,
        ...mapRegistrationStepData(x, t),
      })),
    // eslint-disable-next-line
    [userType]
  );
}

export function mapRegistrationInfoToUserType(
  ri: GetPortalUserRegistrationInfoGetPortalUserRegistrationInfoResponse | null | undefined
): UserType | null {
  switch (ri?.role) {
    case "teacher":
      return UserType.Teacher;
    case "pupil":
      return UserType.Student;
    case "other": {
      return (ri.customerTypes || []).findIndex((x) =>
        [
          CustomerTypes.KindergartenEducationCode.valueOf(),
          CustomerTypes.AdultEducationCode.valueOf(),
        ].includes(x)
      ) > -1
        ? UserType.KindergartenTeacher
        : UserType.Other;
    }
    default:
      return null;
  }
}

function mapRegistrationStepData(
  registrationStep: RegistrationStep,
  t: TranslationFn
): Omit<RegistrationStepModel, "type"> {
  const isStage = window.location.origin.indexOf("-stage") !== -1;
  switch (registrationStep) {
    case RegistrationStep.BasicInfo:
      return {
        content: <RegistrationBasicInfo />,
        additionalData: {
          title: "whatHappensToData",
          text: "whatHappensToDataExplanation",
          link: {
            url: isStage
              ? "https://staging.veritas.at/rechtliches/datenschutz"
              : "https://www.veritas.at/rechtliches/datenschutz",
            text: t("veritasPrivacyPolicy"),
          },
        },
      };
    case RegistrationStep.AddEducationalInstitutions:
      return {
        content: <RegistrationAddEducationalInstitutions />,
        title: "addEducationalInstitution",
        subtitle: "getTheRightInfoForYourEducationalInstitution",
      };
    case RegistrationStep.ExperienceAndRoles:
      return {
        content: <RegistrationExperienceAndRoles />,
        title: "teachingExperienceAndFunctionsAtSchool",
        subtitle: "weCanGiveYouInformationWhenYouGiveUsExperienceAndFunctions",
        additionalData: {
          text: "thisInformationCanBeChangedInTheProfileAtAnyTime",
        },
      };
    case RegistrationStep.MailingConsents:
      return {
        content: <RegistrationMailingConsents />,
        title: "madeIt",
        subtitle: "yourProfileWasSuccessfullyCreated",
      };
    case RegistrationStep.AddSchools:
      return {
        content: <RegistrationAddSchools />,
        title: "connectSchoolToVeritasAccount",
      };
    case RegistrationStep.Subjects:
      return {
        content: <RegistrationSubjects />,
        title: "selectSubjects",
        subtitle: "weCanGiveYouInformationWhenYouGiveUsSubjects",
        additionalData: {
          text: "thisInformationCanBeChangedInTheProfileAtAnyTime",
        },
      };
    default:
      switchAssertUnreachable(registrationStep);
  }
}
