import { Box, Button as MuiButton, ButtonProps, makeStyles, Typography } from "@material-ui/core";
import React from "react";

interface Props extends Omit<ButtonProps, "variant"> {
  icon?: React.ReactNode;
  buttonText: string;
}

export function OutlinedButton(props: Props) {
  const classes = useStyles();

  return (
    <MuiButton
      variant="outlined"
      color={"primary"}
      className={props.icon ? classes.buttonWithIcon : classes.button}
      {...props}
    >
      {props.icon && (
        <Box mr={1} height="21px">
          {props.icon}
        </Box>
      )}
      <Typography className={classes.text}>{props.buttonText}</Typography>
    </MuiButton>
  );
}

//#region styles
const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.text.primary,
    borderRadius: 8,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  buttonWithIcon: {
    color: theme.palette.text.primary,
    borderRadius: 8,
    width: "100%",
    justifyContent: "left",
    backgroundColor: theme.palette.background.paper,
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "initial",
  },
}));
//#endregion
