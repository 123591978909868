import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { PortalUserContext } from "../../PortalUserProvider";
import _ from "lodash";

export function UserEmail() {
  const classes = useStyles();
  const { portalUser } = React.useContext(PortalUserContext);

  return _.isNil(portalUser.email) ? null : (
    <Typography className={classes.email}>{portalUser.email}</Typography>
  );
}

//#region styles
const useStyles = makeStyles(() => {
  return {
    email: {
      textDecoration: "underline",
    },
  };
});
//#endregion
