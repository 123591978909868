import { FormikTextField, FormikTextFieldProps } from "@dccs/react-formik-mui";
import { InputLabel, makeStyles, TextField, TextFieldProps } from "@material-ui/core";
import { FormikErrors, FormikTouched } from "formik";
import React from "react";
import { FlexBox } from "../Boxes/FlexBox";
import { CustomLabel, CustomLabelProps } from "./CustomLabel";
import { FieldInfoIcon, InfoIconModel } from "./FieldInfoIcon";
import clsx from "clsx";
import { ColumnFlexBox } from "../Boxes/ColumnFlexBox";

interface CustomFormikTextFieldProps extends FormikTextFieldProps, CustomLabelProps {
  infoIcon?: InfoIconModel;
}

function FormikTextFieldWithLabelInner(props: CustomFormikTextFieldProps) {
  const classes = useStyles();
  const { label, showLabelAsRequired, error, ...rest } = props;

  const customStyle: Pick<FormikTextFieldProps, "className" | "InputProps"> = {
    className: clsx(classes.wrapper, classes.textfieldBackground),
    InputProps: {
      classes: { root: classes.root },
    },
  };

  return (
    <ColumnFlexBox width={props.fullWidth && "100%"}>
      <CustomLabel label={label} showLabelAsRequired={showLabelAsRequired} error={error} />
      <FlexBox alignItems="center" className={classes.fieldWrapper}>
        <FormikTextField variant="outlined" {...rest} {...customStyle} label={undefined} />
        {props.infoIcon && <FieldInfoIcon infoIcon={props.infoIcon} />}
      </FlexBox>
    </ColumnFlexBox>
  );
}

interface Props<T> extends FormikTextFieldProps, CustomLabelProps {
  errors: FormikErrors<T>;
  touched: FormikTouched<T>;
  infoIcon?: InfoIconModel;
}

export function FormikTextFieldWithLabel<T>(props: Props<T>) {
  return (
    <FormikTextFieldWithLabelInner
      error={
        props.errors[props.name as keyof T] !== undefined &&
        props.touched[props.name as keyof T] !== undefined
      }
      {...props}
    />
  );
}

export function LabelledTextField(
  props: TextFieldProps & {
    infoIcon?: InfoIconModel;
  }
) {
  const classes = useStyles();
  const customStyle: Pick<TextFieldProps, "className" | "InputProps"> = {
    className: clsx(classes.wrapper, classes.textfieldBackground),
    InputProps: {
      classes: { root: classes.root },
    },
  };

  return (
    <>
      {!props.label ? null : (
        <InputLabel className={classes.label} error={props.error}>
          {props.label} {props.required ? " *" : ""}
        </InputLabel>
      )}
      <FlexBox alignItems="center" className={classes.fieldWrapper}>
        <TextField variant={"outlined"} {...props} {...customStyle} label={undefined} />
        {props.infoIcon && <FieldInfoIcon infoIcon={props.infoIcon} />}
      </FlexBox>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: 0,
  },
  root: {
    borderRadius: 8,
  },
  fieldWrapper: {
    width: "100%",
    gap: theme.spacing(),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(),
  },

  textfieldBackground: {
    [theme.breakpoints.down("xs")]: {
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  label: {
    fontWeight: theme.typography.fontWeightBold,
    width: "100%",
  },
}));
