import React from "react";
import { FormikAutocomplete, FormikAutocompleteProps } from "../Formik";
import { useTranslate } from "../../utils/customHooks/useTranslate";
import { boolOrUndefined } from "../../utils/generics";
import usePlacesAutocomplete, { Suggestion } from "use-places-autocomplete";
import _ from "lodash";

export type GooglePlacesAutocompleteProps = Omit<
  FormikAutocompleteProps<Suggestion, false, boolOrUndefined, boolOrUndefined>,
  "label" | "options"
> & {
  hideLabel?: boolean;
};

export function GooglePlacesAutocomplete(props: GooglePlacesAutocompleteProps) {
  const t = useTranslate();
  const {
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 500,
  });

  const options = data.map((v, i) => ({
    key: i,
    value: v,
    displayValue: v.description,
  }));
  return (
    <FormikAutocomplete
      {...props}
      label={props.hideLabel ? undefined : t("search")}
      filterOptions={(x) => x}
      onInputChange={(event, value) => {
        if (
          !_.isNil(value) &&
          !_.isEmpty(value) &&
          !options.map((x) => x.displayValue).includes(value)
        )
          setValue(value, true);
      }}
      options={options}
      highlightInput
    />
  );
}
