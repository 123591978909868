import _ from "lodash";
import React, { useState } from "react";
import { PumaContextApi, ResponsesInstituteResponse, usePumaContextApi } from "../../../../api";
import { FieldInfoIcon, InfoIconType } from "../../../../components/Formik/FieldInfoIcon";
import { useAsyncQuerier } from "../../../../utils/customHooks/useAsyncQuerier";
import { useTranslate } from "../../../../utils/customHooks/useTranslate";
import { GetQueryType } from "../../../../utils/generics";
import { SchoolCodeInfo } from "../../SchoolCodeInfo";
import { CircularProgress, makeStyles, TextField, TextFieldProps } from "@material-ui/core";
import { useStateRef } from "../../../../utils/customHooks/useStateRef";
import { RowFlexBox } from "../../../../components/Boxes/RowFlexBox";
import clsx from "clsx";

type Query = GetQueryType<PumaContextApi["institutes"]["institutesBySchoolCodeList"], 0>;

type Props = TextFieldProps & {
  disabled?: boolean;
  onInputChange: (
    newVal: ResponsesInstituteResponse | null,
    oldVal: ResponsesInstituteResponse | null
  ) => void;
  alreadyAddedInstituteIds?: string[];
};

export function SchoolCodeField(props: Props) {
  const api = usePumaContextApi();
  const [schoolCodeInfoIcon, setSchoolCodeInfoIcon] = useState<InfoIconType>(InfoIconType.Info);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [institute, setInstitute] = useState<ResponsesInstituteResponse | null>(null);
  const classes = useStyles();

  const instituteAlreadyAssigned = props.alreadyAddedInstituteIds?.includes(institute?.id!);
  const t = useTranslate();
  const formHelperTextRef = useStateRef<HTMLParagraphElement>();

  const query = useAsyncQuerier({
    asyncFunction: async (query: Query) => await handleSchoolCodeChange(query),
    initialQuery: { SchoolCode: "" },
    debounce: 500,
    options: {
      executeOnMount: props.disabled === true,
      executeOnUpdate: !(props.disabled === true),
    },
  });

  const handleSchoolCodeChange = async (q: Query) => {
    if (props.disabled) {
    }

    if (_.isEmpty(q.SchoolCode)) {
      setSchoolCodeInfoIcon(InfoIconType.Info);
      props.onInputChange(null, institute);
      setInstitute(null);
      return;
    }

    const i = (await api.institutes.institutesBySchoolCodeList(q))?.data;
    if (props.alreadyAddedInstituteIds?.includes(i?.id!)) {
      setInstitute(i);
      return;
    }

    props.onInputChange(i, institute);
    setInstitute(i);
    setSchoolCodeInfoIcon(_.isNil(i) ? InfoIconType.Error : InfoIconType.Approved);
  };

  const textFieldProps: TextFieldProps = {
    onChange: (e) => query.reload({ SchoolCode: e.currentTarget.value }),
    label: t("schoolCode"),
    placeholder: "abc123",
    InputProps: {
      endAdornment: <>{query.loading ? <CircularProgress color="inherit" size={20} /> : null}</>,
    },
    fullWidth: true,
    helperText:
      (instituteAlreadyAssigned && `${t("schoolAlreadyAdded")} (${institute?.fullname})`) ||
      (schoolCodeInfoIcon === InfoIconType.Error && t("schoolCodeInvalid")),
    FormHelperTextProps: {
      className: classes.helperText,
      ref: formHelperTextRef,
    },
    variant: "outlined",
    className: clsx(classes.textField, props.className),
  };

  return (
    <RowFlexBox alignItems={"center"} width={"100%"}>
      <TextField {...props} {...textFieldProps} />
      <FieldInfoIcon
        infoIcon={{
          show: true,
          infoIconType: schoolCodeInfoIcon,
          onClick: () => setShowInfo(true),
        }}
        onHoverPointer
        marginBottom={formHelperTextRef.current?.clientHeight}
      />
      {showInfo && <SchoolCodeInfo onClose={() => setShowInfo(false)} />}
    </RowFlexBox>
  );
}

const useStyles = makeStyles((theme) => ({
  helperText: {
    marginLeft: 0,
    color: theme.palette.error.main,
  },
  textField: {
    marginRight: theme.spacing(1),
  },
}));
