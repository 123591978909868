import React from "react";
import {PageWrapper} from "../../components/PageWrapper";
import {EmailNewsletter} from "../profile/settingsAndNewsletters/EmailNewsletter";
import {PostNewsletter} from "../profile/settingsAndNewsletters/PostNewsletter";
import {useTranslate} from "../../utils/customHooks/useTranslate";
import {useAsync} from "react-async-hook";
import {usePumaContextApi} from "../../api";
import {Box, makeStyles} from "@material-ui/core";
import {EXTENDED_NORMAL_WIDTH, MOBILE_WIDTH} from "../../utils/layoutHelper";
import {Button} from "../../components/Button";
import {navigateToReturnUrlIfExists} from "../../auth";

export function ExternalModifyMailingConsentPage() {
    const t = useTranslate();
    const pumaApi = usePumaContextApi();
    const classes = useStyles();

    const request = useAsync(
        async () => pumaApi.portalUsers.portalUsersSettingsAndNewsletterList(),
        []
    );

    return request.loading ? null : (
        <PageWrapper title={t("mailingConsent")}>
            <>
                <EmailNewsletter
                    isSubscribed={request.result?.data?.emailMailingConsentSubscribed!}
                    className={classes.wrapper}
                    mb={2}
                />
                <PostNewsletter
                    isSubscribed={request.result?.data?.postMailingConsentSubscribed!}
                    className={classes.wrapper}
                />
                <Box className={classes.buttonWrapper} mt={2}>
                    <Button onClick={navigateToReturnUrlIfExists} buttonText={t("back")}/>
                </Box>
                <Button buttonText={t("cancel")} variant="text" onClick={() => navigateToReturnUrlIfExists()}
                        style={{width: "auto"}}/>
            </>
        </PageWrapper>
    );
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: EXTENDED_NORMAL_WIDTH + "px",
        [theme.breakpoints.down("xs")]: {
            width: MOBILE_WIDTH + "px",
            marginTop: theme.spacing(2),
        },
    },
    buttonWrapper: {
        width: MOBILE_WIDTH + "px",
    },
}));
