import React, { useState } from "react";
import { useTranslate } from "../../../../utils/customHooks/useTranslate";
import { IUserRegistrationFormValues } from "../../../registration/form/UserRegistrationFormHelper";
import { useCustomFormikContext } from "../../../../utils/customHooks/useCustomFormikContext";
import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import { MOBILE_WIDTH, NORMAL_WIDTH } from "../../../../utils/layoutHelper";
import _ from "lodash";
import { ResponsesInstituteResponse } from "../../../../api";
import { SchoolCodeField } from "./SchoolCodeField";
import { PlusTextButton } from "../../../../components/PlusTextButton";
import { ColumnFlexBox } from "../../../../components/Boxes/ColumnFlexBox";
import clsx from "clsx";
import { SchoolWidget } from "../../SchoolWidget/SchoolWidget";
import { CustomIcon } from "../../../../components/icons/CustomIcon";
import { ContentBox } from "../../../../components/ContentBox";
import { BoldTypography } from "../../../../components/BoldTypography";
import { ListElement } from "../../../../components";

export function RegistrationAddSchools() {
  const t = useTranslate();
  const formik = useCustomFormikContext<IUserRegistrationFormValues>();

  const [isAddingSchool, setIsAddingSchool] = useState(true);

  const classes = useStyles();

  const hasAssignedSchools = formik.values.schools.length > 0;

  if (
    formik.values.schools.length === 1 &&
    formik.values.schools[0].id !== formik.values.mainInstituteId
  )
    formik.setFieldValue(formik.propertyNames.mainInstituteId, formik.values.schools[0].id!);
  return (
    <>
      <Box className={classes.icon}>
        <CustomIcon name="Berechtigunsgnachweis" height={300} width={300} />
      </Box>
      {hasAssignedSchools && (
        <ColumnFlexBox>
          <Box mb={1} alignSelf={"left"}>
            <Typography variant={"h3"}>{t("alreadyLinkedSchools")}</Typography>
          </Box>

          {formik.values.schools.map((x) => (
            <SchoolWidget
              alignSelf={"stretch"}
              institute={x}
              setAsMainAssignment={() => {
                formik.setFieldValue(formik.propertyNames.mainInstituteId, x.id!);
              }}
              showMainAssignmentRadio={formik.values.schools.length > 1}
              isMainAssignment={formik.values.mainInstituteId === x.id}
              isVerified={!_.isNil(x.verificationCode)}
              mb={2}
              onValidVerificationCode={(sc) => handleTeacherApprovalChange(x.id!, sc)}
              onRemoveClick={() => {
                removeInstituteFromList(x.id!);
              }}
            />
          ))}
        </ColumnFlexBox>
      )}
      {isAddingSchool ? (
        <>
          {hasAssignedSchools && (
            <Divider className={clsx(classes.divider, classes.marginBottom)} />
          )}
          <SchoolCodeField
            onInputChange={handleSchoolCodeChange}
            alreadyAddedInstituteIds={formik.values.schools.map((x) => x.id!)}
          />
          <Divider className={clsx(classes.divider, classes.marginTop)} />
        </>
      ) : (
        <>
          <Divider className={classes.divider} />
          <PlusTextButton
            text={t("addAnotherSchool")}
            onClick={() => setIsAddingSchool(true)}
            className={classes.plusTextButton}
          />
          <Divider className={classes.divider} />
        </>
      )}
      <ContentBox className={clsx(classes.marginTop)}>
        <BoldTypography>{t("addSchoolBenefitsTitle")}</BoldTypography>
        <Box component={"ul"} sx={{ px: 2.5 }}>
          <ListElement text={t("addSchoolBenefit")} />
          <ListElement text={t("addSchoolBenefit2")} />
          <ListElement text={t("addSchoolBenefit3")} />
          <ListElement text={t("addSchoolBenefit4")} />
        </Box>
      </ContentBox>
      <Divider className={clsx(classes.divider, classes.marginTop)} />
    </>
  );

  function handleSchoolCodeChange(
    newValue: ResponsesInstituteResponse | null,
    oldValue: ResponsesInstituteResponse | null
  ) {
    if (_.isNil(newValue)) return;

    _.isNil(oldValue)
      ? addInstituteToList(newValue)
      : replaceInstituteInList(oldValue.id!, newValue);

    setIsAddingSchool(false);
  }

  function removeInstituteFromList(instituteId: string) {
    const institutes = [...formik.values.schools];
    const index = institutes.findIndex((x) => x.id === instituteId);
    if (index > -1) institutes.splice(index, 1);
    formik.setFieldValue(formik.propertyNames.schools, institutes);

    if (institutes.length === 0) setIsAddingSchool(true);
  }

  function addInstituteToList(institute: ResponsesInstituteResponse) {
    formik.setFieldValue(formik.propertyNames.schools, [...formik.values.schools, institute]);
  }

  function replaceInstituteInList(toReplaceId: string, newInstitute: ResponsesInstituteResponse) {
    const institutes = [...formik.values.schools];
    const index = institutes.findIndex((x) => x.id === toReplaceId);
    if (index > -1) institutes[index] = { id: newInstitute.id!, verificationCode: null };

    formik.setFieldValue(formik.propertyNames.schools, institutes);
  }

  function handleTeacherApprovalChange(instituteId: string, vc: string) {
    const institutes = [...formik.values.schools];
    const index = institutes.findIndex((x) => x.id === instituteId);
    if (index > -1) institutes[index].verificationCode = vc;

    formik.setFieldValue(formik.propertyNames.schools, institutes);
  }
}

const useStyles = makeStyles((theme) => ({
  icon: {
    position: "absolute",
    top: 50,
    left: 100,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  divider: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  selectEducationalInstitutionButton: {
    marginTop: theme.spacing(0.5),
    fontWeight: theme.typography.fontWeightRegular,
  },
  buttonText: {
    textDecoration: "underline",
    textTransform: "none",
  },
  institutesWrapper: {
    width: NORMAL_WIDTH + "px",
    [theme.breakpoints.down("xs")]: {
      width: MOBILE_WIDTH + "px",
    },
  },
  plusTextButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));
