import React, { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import { usePropertyNames } from "../../../../utils/customHooks/usePropertyNames";
import {
  EducationalInstituteCountry,
  EducationalInstituteType,
} from "../../../../utils/beEnumMapping";
import { useTranslate } from "../../../../utils/customHooks/useTranslate";
import {
  FormikAutocompleteWithLabel,
  LabelWrapper,
} from "../../../../components/Formik/FormikAutocompleteWithLabel";
import { FormikTextFieldWithLabel } from "../../../../components/Formik/FormikTextFieldWithLabel";
import FormikAutocompleteAsync from "../../../../components/Formik/FormikAutocompleteAsync";
import { GetQueryType } from "../../../../utils/generics";
import {
  PumaContextApi,
  ResponsesInstituteResponse,
  SharedEducationalInstituteCountry,
  usePumaContextApi,
} from "../../../../api";
import _ from "lodash";
import { Option } from "../../../../components";
import { makeStyles } from "@material-ui/core";
import { EducationalInstituteWidget } from "../../EducationalInstituteWidget/EducationalInstituteWidget";
import { useRandomKeygen } from "../../../../utils/customHooks/useRandomKeygen";
import { ZIP_PLACEHOLDER } from "../../../profile/address/AddressForm";

type EducationalInstituteFormProps = {
  onInstituteSelect: (newVal: ResponsesInstituteResponse, prevValId: string | null) => void;
  refreshFormWhenTrue: boolean;
  alreadySelectedEducationalInstituteType: EducationalInstituteType | null;
  alreadySelectedInstituteIds: string[];
  onRemoveClick?: (instituteId: string) => void;
};

type IEducationalInstituteForm = {
  reinitializationFlag: boolean;
  country: EducationalInstituteCountry;
  zip: string | null;
  instituteType: EducationalInstituteType | null;
  institute: ResponsesInstituteResponse | null;
};

type Query = GetQueryType<PumaContextApi["institutes"]["institutesEducationalInstitutesList"], 0>;

export function EducationalInstituteForm(props: EducationalInstituteFormProps) {
  const t = useTranslate();
  const api = usePumaContextApi();

  const [selectedInstitute, setSelectedInstitute] =
    useState<ResponsesInstituteResponse | null>(null);
  const [initialValues, setInitialValues] = useState<IEducationalInstituteForm>({
    reinitializationFlag: true,
    country: EducationalInstituteCountry.Austria,
    zip: null,
    instituteType: props.alreadySelectedEducationalInstituteType,
    institute: null,
  });

  useEffect(() => {
    if (props.refreshFormWhenTrue)
      setInitialValues((prevState) => ({
        ...prevState,
        reinitializationFlag: !prevState.reinitializationFlag,
      }));
  }, [props.refreshFormWhenTrue]);

  const names = usePropertyNames(initialValues);

  const classes = useStyles();

  const educationalInstituteCountryOptions = useCountryOptions();
  const educationalInstituteTypeOptions = useInstituteTypeOptions();

  const { key, resetKey } = useRandomKeygen(12345678);
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onReset={() => setSelectedInstitute(null)}
      onSubmit={() => {}}
    >
      {(formik) => (
        <>
          <FormikAutocompleteWithLabel
            label={t("country")}
            placeholder={t("country")}
            name={names.country}
            fullWidth
            disableClearable
            options={educationalInstituteCountryOptions}
            {...formik}
          />
          <FormikTextFieldWithLabel
            label={t("zip")}
            placeholder={ZIP_PLACEHOLDER}
            name={names.zip}
            fullWidth
            {...formik}
          />
          {!props.alreadySelectedEducationalInstituteType && (
            <FormikAutocompleteWithLabel
              key={formik.values.reinitializationFlag ? 1 : 2}
              label={t("typeOfEducationalInstitution")}
              TextFieldProps={{
                placeholder: t("pleaseChoose"),
              }}
              name={names.instituteType}
              disableClearable
              fullWidth
              options={educationalInstituteTypeOptions}
              {...formik}
            />
          )}
          {!_.isEmpty(formik.values.zip) && !_.isNil(formik.values.instituteType) && (
            <LabelWrapper label={t("educationalInstitution")}>
              <FormikAutocompleteAsync
                key={key}
                label={undefined}
                TextFieldProps={{
                  key: formik.values.institute?.id,
                  placeholder: t("searchInstitution"),
                  variant: "outlined",
                  margin: "none",
                  className: classes.textfieldBackground,
                }}
                disableClearable
                dataFilter={(d) =>
                  d.filter((x) => !props.alreadySelectedInstituteIds.includes(x.id!))
                }
                name={names.institute}
                fullWidth
                dataFetcher={async (queryParams: Query) =>
                  (await api.institutes.institutesEducationalInstitutesList(queryParams)).data || []
                }
                onChange={(e, v) => {
                  props.onInstituteSelect(
                    (v as Option<ResponsesInstituteResponse>)?.value,
                    selectedInstitute?.id!
                  );
                  setSelectedInstitute((v as Option<ResponsesInstituteResponse>).value);
                }}
                queryParams={{
                  EducationalInstituteCountry: formik.values.country,
                  InstituteType: formik.values.instituteType,
                  Zip: formik.values.zip!,
                }}
                optionsMapper={(x: ResponsesInstituteResponse) => ({
                  key: parseInt(x.id!, 10),
                  value: x,
                  displayValue: mapInstituteToDisplayValue(x),
                })}
                debounce={500}
              />
            </LabelWrapper>
          )}
          {formik.values.institute && (
            <EducationalInstituteWidget
              institute={formik.values.institute}
              width={"fill-available"}
              mb={2}
              onRemoveClick={
                props.onRemoveClick &&
                (() => {
                  props.onRemoveClick && props.onRemoveClick(formik.values.institute?.id!);
                  formik.setFieldValue(names.institute, null);
                  resetKey();
                })
              }
            />
          )}
        </>
      )}
    </Formik>
  );
}

function mapInstituteToDisplayValue(institute: ResponsesInstituteResponse) {
  if (_.isNil(institute.mainAddress)) return institute.fullname!;

  return `${institute.fullname} - ${institute.mainAddress.street}, ${institute.mainAddress.zip}, ${institute.mainAddress.city}`;
}

function useInstituteTypeOptions(): Option<EducationalInstituteType>[] {
  const t = useTranslate();
  return useMemo(
    () => [
      {
        key: EducationalInstituteType.Kindergarten,
        displayValue: t("kindergarten"),
        value: EducationalInstituteType.Kindergarten,
      },
      {
        key: EducationalInstituteType.AdultEducation,
        displayValue: t("adultEducation"),
        value: EducationalInstituteType.AdultEducation,
      },
    ],
    [t]
  );
}

function useCountryOptions(): Option<SharedEducationalInstituteCountry>[] {
  const t = useTranslate();

  return useMemo(
    () => [
      {
        key: EducationalInstituteCountry.Austria,
        displayValue: t("austria"),
        value: EducationalInstituteCountry.Austria,
      },
      {
        key: EducationalInstituteCountry.Germany,
        displayValue: t("germany"),
        value: EducationalInstituteCountry.Germany,
      },
      {
        key: EducationalInstituteCountry.Switzerland,
        displayValue: t("switzerland"),
        value: EducationalInstituteCountry.Switzerland,
      },
      {
        key: EducationalInstituteCountry.OtherEU,
        displayValue: t("otherEU"),
        value: EducationalInstituteCountry.OtherEU,
      },
      {
        key: EducationalInstituteCountry.NotEU,
        displayValue: t("notEU"),
        value: EducationalInstituteCountry.NotEU,
      },
    ],
    [t]
  );
}

const useStyles = makeStyles((theme) => ({
  textfieldBackground: {
    [theme.breakpoints.down("xs")]: {
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
}));
