import React from "react";
import { IPortalUserProps, PortalUserContext } from "../../PortalUserProvider";
import { UserType } from "../../../utils/beEnumMapping";
import { assertUnreachable } from "../../../utils";
import { TeacherProfilePage } from "./TeacherProfilePage";
import { StudentProfilePage } from "./StudentProfilePage";
import { OtherProfilePage } from "./OtherProfilePage";
import { KindergartenTeacherProfilePage } from "./KindergartenTeacherProfilePage";
import { GetPortalUserByIdGetPortalUserByIdQueryResponse } from "../../../api";

type ProfileHelperType = IPortalUserProps & {
  portalUser: GetPortalUserByIdGetPortalUserByIdQueryResponse;
  reload: () => void;
  content: React.ReactNode;
  // commands: IPortalUserProps["commands"];
};

export function useProfileHelper(): ProfileHelperType {
  const { portalUser, reload, preselectedEducationalInstituteType } =
    React.useContext(PortalUserContext);
  return {
    portalUser,
    reload,
    content: getContent(portalUser.type as UserType),
    preselectedEducationalInstituteType,
  };
}

function getContent(userType: UserType) {
  switch (userType) {
    case UserType.Teacher:
      return <TeacherProfilePage />;
    case UserType.Student:
      return <StudentProfilePage />;
    case UserType.Other:
      return <OtherProfilePage />;
    case UserType.KindergartenTeacher:
      return <KindergartenTeacherProfilePage />;
    default:
      assertUnreachable(userType);
  }
}
