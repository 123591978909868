import {makeStyles} from "@material-ui/core";
import _ from "lodash";
import React, {useState} from "react";
import {ResponsesInstituteResponse, usePumaContextApi} from "../api";
import {PageWrapper} from "../components/PageWrapper";
import {PortalUserContext} from "../features/PortalUserProvider";
import {useCustomHistory} from "../utils/customHooks/useCustomHistory";
import {useTranslate} from "../utils/customHooks/useTranslate";
import {SchoolCodeField} from "../features/institutes/instituteAssignment/instituteWithSchoolCode/SchoolCodeField";
import {SchoolWidget} from "../features/institutes/SchoolWidget/SchoolWidget";
import {MOBILE_WIDTH, NORMAL_WIDTH} from "../utils/layoutHelper";
import {ColumnFlexBox} from "../components/Boxes/ColumnFlexBox";
import {NavigationButtons} from "../components/NavigationButtons";

export function AddSchoolPage() {
    const api = usePumaContextApi();
    const history = useCustomHistory();
    const {reload, portalUser} = React.useContext(PortalUserContext);

    const t = useTranslate();
    const classes = useStyles();

    const [institute, setInstitute] =
        useState<(ResponsesInstituteResponse & { verificationCode: string | null }) | null>(null);

    return (
        <PageWrapper title={t("connectSchoolToVeritasAccount")} hideBackButton>
            <ColumnFlexBox className={classes.wrapper} alignItems={"center"}>
                <SchoolCodeField
                    onInputChange={(i) => setInstitute(_.isNil(i) ? null : {...i, verificationCode: null})}
                    alreadyAddedInstituteIds={portalUser.institutes?.map((x) => x.id!) || []}
                />
                {!_.isNil(institute) && (
                    <SchoolWidget
                        institute={institute}
                        onValidVerificationCode={(code) => {
                            setInstitute((prevState) => ({...prevState, verificationCode: code}));
                        }}
                        isVerified={!_.isNil(institute.verificationCode)}
                        width={"fill-available"}
                    />
                )}

                <NavigationButtons disabled={_.isNil(institute)} onSave={async () => {
                    await api.portalUsers.portalUsersInstitutesCreate({
                        instituteId: institute?.id!,
                        isMainInstitute: _.isNil(portalUser.institutes) || portalUser.institutes.length === 0, // What to do here?
                        teacherVerification: institute?.verificationCode,
                    });
                    reload();
                    history.goBack();
                }}/>
            </ColumnFlexBox>
        </PageWrapper>
    );
}

//#region styles
const useStyles = makeStyles((theme) => ({
    submitButton: {
        marginTop: theme.spacing(2),
        borderRadius: 8,
        width: "250px",
    },
    wrapper: {
        width: NORMAL_WIDTH + "px",
        [theme.breakpoints.down("xs")]: {
            width: MOBILE_WIDTH + "px",
        },
    },
}));
//#endregion
