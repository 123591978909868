import * as React from "react";
import { Box, Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import { useTranslate } from "../utils/customHooks/useTranslate";

export function PortalInfos() {
  const classes = useStyles();
  const t = useTranslate();
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Typography variant="body1">{t("myProfileSubtitlePart1")}</Typography>
        <Typography variant="body1">{t("myProfileSubtitlePart2")}</Typography>
      </div>
      <div className={classes.cardWrapper}>
        <Card className={classes.card}>
          <a className={classes.cardLink} href="https://veritas.at">
            <CardContent>
              <Box className={classes.imageWrapper}>
                <img className={classes.logo} src="/img/veritas-logo.png" alt="Veritas" />
              </Box>
              <Typography variant="body1">{t("myProfilePortalSubtitleVeritas")}</Typography>
            </CardContent>
          </a>
        </Card>
        <Card className={classes.card}>
          <a className={classes.cardLink} href="https://scook.at">
            <CardContent>
              <Box className={classes.imageWrapper}>
                <img className={classes.logo} src="/img/Scook.svg" alt="Scook" />
              </Box>
              <Typography variant="body1">{t("myProfilePortalSubtitleScook")}</Typography>
            </CardContent>
          </a>
        </Card>
        <Card className={classes.card}>
          <a className={classes.cardLink} href="https://vera.veritas.at">
            <CardContent>
              <Box className={classes.imageWrapper}>
                <img className={classes.logo} src="/img/vera-logo.png" alt="Vera" />
              </Box>
              <Typography variant="body1">{t("myProfilePortalSubtitleVera")}</Typography>
            </CardContent>
          </a>
        </Card>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    justifyContent: "center",
    alignItems: "center",
    marginLeft: -theme.spacing(4),
    marginRight: -theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      background: theme.palette.background.default,
    },
  },
  header: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  cardWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    maxWidth: theme.spacing(30 * 3 + 3),
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
    },
  },
  cardLink: {
    textDecoration: "none",
  },
  card: {
    cursor: "pointer",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: theme.spacing(30),
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    height: theme.spacing(7),
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  logo: {
    maxHeight: theme.spacing(7),
  },
}));
