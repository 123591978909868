import React from "react";
import { makeStyles, TypographyVariant } from "@material-ui/core";
import { BoldTypography } from "../../../components/BoldTypography";
import { GetPortalUserByIdGetPortalUserByIdQueryResponseAddressResponse } from "../../../api";

interface Props {
  variant?: TypographyVariant;
  address: GetPortalUserByIdGetPortalUserByIdQueryResponseAddressResponse;
}

export function AddressRecipient(props: Props) {
  const classes = useStyles();
  const { recipientName, recipientHandlerName } = props.address;

  return (
    <BoldTypography variant={props.variant || "h6"} className={classes.title}>
      {recipientName} {recipientHandlerName}
    </BoldTypography>
  );
}

//#region styles
const useStyles = makeStyles((theme) => {
  return {
    title: {
      overflowWrap: "break-word",
    },
  };
});
//#endregion
