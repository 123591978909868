import React from "react";
import { useLocation } from "react-router-dom";
import { usePumaContextApi } from "../../api";
import { useAsync } from "react-async-hook";
import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { RowFlexBox } from "../../components/Boxes/RowFlexBox";
import { PageWrapper } from "../../components/PageWrapper";
import { useTranslate } from "../../utils/customHooks/useTranslate";
import { Button } from "../../components/Button";
import { EXTENDED_NORMAL_WIDTH, MOBILE_WIDTH } from "../../utils/layoutHelper";
import { ColumnFlexBox } from "../../components/Boxes/ColumnFlexBox";

export function UnsubscribeEmail() {
  const viewModel = useUnsubscribeEmailViewModel();
  const t = useTranslate();
  const classes = useStyles();
  return (
    <>
      {viewModel.content.show && (
        <PageWrapper title={t("newsletter")} hideBackButton className={classes.pageWrapper}>
          <ColumnFlexBox>
            <Typography variant={"body1"}>{viewModel.content.text}</Typography>
            {viewModel.unsubscribeButton.show && (
              <Button
                buttonText={t("unsubscribeFromNewsletterNow")}
                disabled={viewModel.unsubscribeButton.disabled}
                onClick={viewModel.unsubscribeButton.unsubscribe}
              />
            )}
          </ColumnFlexBox>
        </PageWrapper>
      )}
      <RowFlexBox justifyContent={"center"}>
        {viewModel.showLoader && <CircularProgress />}
        {viewModel[404].show && <Typography variant="h1">{viewModel[404].text}</Typography>}
      </RowFlexBox>
    </>
  );
}

function useUnsubscribeEmailViewModel() {
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const _ = urlSearchParams.get("_") ?? "";

  const pumaApi = usePumaContextApi();

  const canUnsubscribe = useAsync(
    async () => await pumaApi.unsubscribe.unsubscribeCanUnsubscribeList({ value: _ }),
    []
  );

  const unsubscribe = useAsync(
    async () => await pumaApi.unsubscribe.unsubscribeCreate({ value: _ }),
    [],
    {
      executeOnMount: false,
    }
  );

  //@ts-ignore
  const showContent = canUnsubscribe.result?.data?.item1 === (true as boolean);
  //@ts-ignore
  const email = canUnsubscribe.result?.data?.item2 as string;

  return {
    content: {
      show: showContent,
      text: (
        <span>
          {unsubscribe.status === "success" ? (
            <>
              E-Mail-Adresse <b>{email}</b> erfolgreich abgemeldet.
            </>
          ) : (
            <>
              Ich möchte in Zukunft keinen Newsletter an die E-Mail-Adresse <b>{email}</b> erhalten
            </>
          )}
        </span>
      ),
    },
    showLoader: canUnsubscribe.loading,
    unsubscribeButton: {
      show: unsubscribe.status !== "success",
      disabled: unsubscribe.loading,
      unsubscribe: unsubscribe.execute,
    },
    404: {
      show: !canUnsubscribe.loading && !showContent,
      text: "404",
    },
  };
}

//#region styles
const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    [theme.breakpoints.down("xs")]: {
      height: "100vh",
    },
  },
  wrapper: {
    width: EXTENDED_NORMAL_WIDTH,
    [theme.breakpoints.down("xs")]: {
      width: MOBILE_WIDTH,
    },
  },
}));
//#endregion
