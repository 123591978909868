import React from "react";
import { CategoriesDropdown, CategoriesDropdownProps, CategoryGroup } from "./CategoriesDropdown";
import { useTranslate } from "../../utils/customHooks/useTranslate";
import { usePumaContextApi } from "../../api";

type InterestsDropdownProps = Omit<CategoriesDropdownProps, "label" | "categoryGroups"> & {
  sendRequestOnChange?: boolean;
};

export function InterestsDropdown(props: InterestsDropdownProps) {
  const t = useTranslate();
  const pumaApi = usePumaContextApi();
  return (
    <CategoriesDropdown
      {...props}
      multiple
      categoryGroups={[CategoryGroup.StandardPerson]}
      label={t("areasOfInterest")}
      fullWidth
      disableClearable
      onChange={async (_, value) => {
        if (props.sendRequestOnChange) {
          const categoryIds = value.map((x) => x.value);
          const query = categoryIds.length > 0 ? { CategoryIds: categoryIds } : {};
          await pumaApi.portalUsers.portalUsersCategoriesUpdate(query);
        }
      }}
    />
  );
}
