import React from "react";
import { CustomIcon } from "../../../components/icons/CustomIcon";
import { UserType } from "../../../utils/beEnumMapping";
import { switchAssertUnreachable } from "../../../utils/objectHelper";
import { PortalUserContext } from "../../PortalUserProvider";

export function UserTypeIcon() {
  const { portalUser } = React.useContext(PortalUserContext);

  const userType = portalUser.type as UserType;
  switch (userType) {
    case UserType.Student:
      return <CustomIcon name="Icon_SchuelerInnen" height={40} width={40} />;
    case UserType.Teacher:
      return <CustomIcon name="Icon_LehrerInnen" height={40} width={40} />;
    case UserType.KindergartenTeacher:
      return <CustomIcon name="Icon_Kindergarten" height={40} width={40} />;
    case UserType.Other:
      return <CustomIcon name="Icon_Lehramt" height={40} width={40} />;
    default:
      return switchAssertUnreachable(userType);
  }
}
