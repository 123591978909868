import { useMemo, useState } from "react";
import useMountEffect from "./useMountEffect";
import { useTheme } from "@material-ui/core";

export function useIsMobileScreen() {
  const theme = useTheme();

  const xsWidth = useMemo(
    () => parseFloat(theme.breakpoints.down("xs").match("[\\d|\\.]*px")![0]),
    [theme.breakpoints]
  );
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useMountEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  });

  return width <= xsWidth;
}
