import React, { ChangeEvent, useContext } from "react";
import _ from "lodash";
import { useAsyncQuerier, useAsyncQuerierProps } from "../../utils/customHooks/useAsyncQuerier";
import { PickByValue } from "utility-types";
import { boolOrUndefined, ConditionalPartial, IncludeWhen, Nullish } from "../../utils/generics";
import { CircularProgress } from "@material-ui/core";
import { FormikContext } from "formik";
import { useRandomKeygen } from "../../utils/customHooks/useRandomKeygen";
import {
  FormikAutocomplete,
  FormikAutocompleteProps,
  FreeSoloConditionalValue,
} from "./FormikAutocomplete";

type QueryParamsProps<
  Query extends object | undefined,
  queryInputKey extends keyof PickByValue<Query, Nullish<string>> | undefined,
  queryParamForInputChange = Omit<Query, Exclude<queryInputKey, undefined | null>>
> = ConditionalPartial<
  keyof queryParamForInputChange extends never ? true : false,
  {
    queryParams: queryInputKey extends undefined ? Query : queryParamForInputChange;
    queryParamForInputChange?: queryInputKey;
  },
  "queryParams"
>;

type CoreProps<
  Response,
  OptionValue,
  QueryParams extends object | undefined,
  queryParamForInputChange extends keyof PickByValue<QueryParams, Nullish<string>> | undefined // Autocomplete input is always string
> = {
  dataFetcher: (query: QueryParams) => Promise<Response[]>;
  optionsMapper: (value: Response, index?: number, array?: Response[]) => OptionValue;
  dataFilter?: (data: Response[]) => Response[];
  debounce?: number;
  requestOptions?: useAsyncQuerierProps<QueryParams, Response[]>["options"];
} & IncludeWhen<QueryParams, object, QueryParamsProps<QueryParams, queryParamForInputChange>>;

export type FormikAutocompleteAsyncProps<
  Response,
  OptionValue,
  Multiple extends boolOrUndefined,
  DisableClearable extends boolOrUndefined,
  FreeSolo extends boolOrUndefined,
  QueryParams extends object | undefined = undefined,
  queryOnInputBy extends keyof PickByValue<QueryParams, Nullish<string>> | undefined = undefined
> = Omit<FormikAutocompleteProps<OptionValue, Multiple, DisableClearable, FreeSolo>, "options"> &
  CoreProps<Response, FreeSoloConditionalValue<OptionValue, FreeSolo>, QueryParams, queryOnInputBy>;

function FormikAutocompleteAsync<
  Response,
  OptionValue,
  Multiple extends boolOrUndefined,
  DisableClearable extends boolOrUndefined,
  FreeSolo extends boolOrUndefined,
  QueryParams extends object | undefined,
  queryParamForInputChange extends keyof PickByValue<QueryParams, Nullish<string>> | undefined
>(
  props: FormikAutocompleteAsyncProps<
    Response,
    OptionValue,
    Multiple,
    DisableClearable,
    FreeSolo,
    QueryParams,
    queryParamForInputChange
  >
) {
  const {
    dataFetcher,
    optionsMapper,
    queryParams,
    queryParamForInputChange,
    debounce,
    ...formikAutocompleteProps
  } = props as unknown as FormikAutocompleteAsyncProps<
    Response,
    OptionValue,
    Multiple,
    DisableClearable,
    FreeSolo,
    object,
    keyof object
  >;
  const formikValue = useContext(FormikContext).values[props.name] as OptionValue;

  const { key, resetKey } = useRandomKeygen({}); // used for rerendering after initial fetch to show default value

  const dataRequest = useAsyncQuerier({
    asyncFunction: dataFetcher,
    initialQuery: queryParams === undefined ? {} : queryParams!,
    debounce: debounce,
    options: {
      ...props.requestOptions,
      onSuccess: (r, options) => {
        if (!_.isNil(props.requestOptions?.onSuccess)) props.requestOptions?.onSuccess(r, options);
      },
    },
    onAfterInitialLoad: () => !props.freeSolo && resetKey(),
  });

  if (
    queryParams !== undefined &&
    !_.isEqual(queryParams, _.omit(dataRequest.currentQuery, queryParamForInputChange!))
  ) {
    dataRequest.reload(queryParams);
  }

  let data = dataRequest.result ? dataRequest.result : [];
  if (props.dataFilter) {
    data = props.dataFilter(data);
  }

  let options = data.map(optionsMapper);

  if (props.freeSolo && !_.isNil(queryParamForInputChange)) {
    removeDuplicateInput();
  }

  return (
    <FormikAutocomplete
      highlightInput
      {...formikAutocompleteProps}
      key={key}
      options={options}
      loading={dataRequest.loading}
      onInputChange={handleInputChangeQuery}
      TextFieldProps={(params) => ({
        ...(typeof props.TextFieldProps === "function"
          ? props.TextFieldProps(params)
          : props.TextFieldProps),
        InputProps: {
          ...params.InputProps,
          endAdornment: (
            <>
              {dataRequest.loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        },
      })}
    />
  );

  function handleInputChangeQuery(event: ChangeEvent<{}>, value: string) {
    if (queryParamForInputChange === undefined) return;

    const valueSelected =
      !_.isNil(formikValue) && !_.isEmpty(formikValue) && !_.isEqual(formikValue, 0);
    if (!props.freeSolo && valueSelected) return;
    if (dataRequest.currentQuery[queryParamForInputChange!] === value || _.isEmpty(value)) return;
    let q = {};
    q = Object.defineProperty(q, queryParamForInputChange!, {
      value: value,
      enumerable: true,
      // writable: true,
      // configurable: true,
    });
    dataRequest.reload(q);
  }

  function removeDuplicateInput() {
    //@ts-ignore
    const inputtedValue = dataRequest.currentQuery[queryParamForInputChange];
    const hasDuplicateOption = options.filter((o) => o === inputtedValue).length > 1;
    if (hasDuplicateOption) {
      const index = options.lastIndexOf(inputtedValue);
      options.splice(index);
    }
  }
}

export default FormikAutocompleteAsync;
