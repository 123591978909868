import React from "react";
import { BoldTypography } from "../../../components/BoldTypography";
import { makeStyles, TypographyVariant } from "@material-ui/core";
import { PortalUserContext } from "../../PortalUserProvider";

type UserFullNameProps = {
  variant?: TypographyVariant;
};

export function UserFullName(props: UserFullNameProps) {
  const classes = useStyles();
  const { portalUser } = React.useContext(PortalUserContext);

  return (
    <BoldTypography variant={props.variant || "h6"} className={classes.title}>
      {portalUser.firstName} {portalUser.lastName}
    </BoldTypography>
  );
}

//#region styles
const useStyles = makeStyles((theme) => {
  return {
    title: {
      overflowWrap: "break-word",
    },
  };
});
//#endregion
