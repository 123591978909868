import { makeStyles, Radio, Typography } from "@material-ui/core";
import React from "react";
import { FlexBox } from "./Boxes/FlexBox";

interface Props {
  label: string;
  isChecked: boolean;
  onClick: (isChecked: boolean) => void;
}

export function RadioButton(props: Props) {
  const classes = useStyles();
  return (
    <FlexBox onClick={() => props.onClick(!props.isChecked)} alignItems="center">
      <Radio checked={props.isChecked} className={classes.radio} />
      <Typography className={classes.label}>{props.label}</Typography>
    </FlexBox>
  );
}
//#region styles
const useStyles = makeStyles((theme) => {
  return {
    label: {
      marginLeft: theme.spacing(0.5),
    },
    radio: {
      padding: 0,
    },
  };
});
//#endregion
