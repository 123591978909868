import React from "react";
import { useIsMobileScreen } from "../../../utils/customHooks/useIsMobileScreen";
import { useTranslate } from "../../../utils/customHooks/useTranslate";
import { CustomIcon } from "../../../components/icons/CustomIcon";
import { Typography } from "@material-ui/core";
import { ColumnFlexBox } from "../../../components/Boxes/ColumnFlexBox";

export function NotVerifiedSchoolIcon() {
  const isMobile = useIsMobileScreen();
  const t = useTranslate();
  return (
    <ColumnFlexBox sx={{ alignItems: "end" }}>
      <CustomIcon name="Icon_Not_Verified" height={40} width={40} style={{ float: "right" }} />
      <Typography
        color={"error"}
        style={{
          width: isMobile ? undefined : 100,
          wordWrap: "break-word",
          textAlign: "right",
          hyphens: "auto",
        }}
        lang={"de"}
      >
        {!isMobile && t("authorizationCodeMissing")}
      </Typography>
    </ColumnFlexBox>
  );
}
