import { useEffect, useRef, useState } from "react";

/**
 * Rerenders component after ref assignment,
 * thereby ensuring that the component uses the latest ref value.
 * The standard useRef hook doesn't cause a rerender.
 * @returns ref object and value (which is ref.current).
 */
export function useStateRef<T>() {
  const [value, setValue] = useState<T | null>(null); // eslint-disable-line
  const ref = useRef<T | null>(null);

  useEffect(() => {
    setValue(ref.current);
  }, [ref]);

  return ref;
}
