import React from "react";
import { FlexBoxProps } from "../../../../components/Boxes/FlexBox";
import { useTranslate } from "../../../../utils/customHooks/useTranslate";
import { useCustomHistory } from "../../../../utils/customHooks/useCustomHistory";
import { PlusTextButton } from "../../../../components/PlusTextButton";
import { routes } from "../../../../routes/routes";
import { makeStyles } from "@material-ui/core";

type AddSchoolButtonProps = FlexBoxProps;

export function AddSchoolButton(props: AddSchoolButtonProps) {
  const t = useTranslate();
  const history = useCustomHistory();
  const classes = useStyles();
  return (
    <PlusTextButton
      {...props}
      TypographyProps={{
        variant: "h3",
        className: classes.addSchoolButtonText,
      }}
      text={t("addSchool")}
      onClick={() => history.navigate(routes.userProfileAddSchool)}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  addSchoolButtonText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
