import React from "react";
import { ColumnFlexBox, ColumnFlexBoxProps } from "../../components/Boxes/ColumnFlexBox";
import { Box, Typography } from "@material-ui/core";
import { useTranslate } from "../../utils/customHooks/useTranslate";
import _ from "lodash";
import { useRegistrationContext } from "../../utils/customHooks/useRegistrationContext";

type UserRegistrationTitleAndSubtitleProps = Omit<ColumnFlexBoxProps, "alignItems">;

export function UserRegistrationStepTitleAndSubtitle(props: UserRegistrationTitleAndSubtitleProps) {
  const { activeStep } = useRegistrationContext();
  const t = useTranslate();
  return _.isNil(activeStep.title) ? null : (
    <ColumnFlexBox {...props} alignItems="center">
      <Box mb={0.5}>
        <Typography variant="h3" align="center">
          {t(activeStep.title)}
        </Typography>
      </Box>
      {!_.isNil(activeStep.subtitle) && (
        <Box sx={{ maxWidth: 440 }}>
          <Typography variant="body1" align="center">
            {t(activeStep.subtitle)}
          </Typography>
        </Box>
      )}
    </ColumnFlexBox>
  );
}
