import _, { isNil } from "lodash";
import * as React from "react";
import { useContext } from "react";
import { useAsync } from "react-async-hook";
import { GetPortalUserByIdGetPortalUserByIdQueryResponse, usePumaContextApi } from "../api";
import { Loading } from "../components";
import { UserFetchError } from "./UserFetchError";
import { EducationalInstituteType, UserType } from "../utils/beEnumMapping";
import { ViewHandlerContext } from "./ViewHandler";
import { useCustomHistory } from "../utils/customHooks/useCustomHistory";
import { mapInterestsToPreselectedEducationalInsituteType } from "../utils/mappers";

export const PortalUserContext = React.createContext<IPortalUserProps>({} as IPortalUserProps);

export interface IPortalUserProps {
  portalUser: GetPortalUserByIdGetPortalUserByIdQueryResponse;
  preselectedEducationalInstituteType: EducationalInstituteType | null;
  reload: (goBack?: true) => void;
}

interface IProps {
  children: React.ReactElement;
  userType: UserType | null;
}

/**
 * Loads user. That's it.
 * Loading user and applying the providing it to the children components.
 * This is the loading component.
 * @param props Just the children ;)
 */
export function PortalUserProvider(props: IProps) {
  const api = usePumaContextApi();
  const history = useCustomHistory();

  const { isRegistered } = useContext(ViewHandlerContext);
  const userInfo = useAsync(
    async (userType: UserType | null) => {
      if (!isRegistered) return;
      if (_.isNil(props.userType)) {
        return await api.portalUsers.portalUsersList();
      }
      return await api.portalUsers.portalUsersGetByUserTypeList({ UserType: userType! });
    },
    [props.userType]
  );

  const portalUsersSettingsAndNewsletterList = useAsync(
    () => api.portalUsers.portalUsersSettingsAndNewsletterList(),
    []
  );

  const interests = portalUsersSettingsAndNewsletterList.result?.data?.categories?.map(
    (it) => it.id!
  );

  const preselectedEducationalInstituteType =
    mapInterestsToPreselectedEducationalInsituteType(interests);

  if (userInfo.error) return <UserFetchError />;
  if (userInfo.loading) return <Loading msg={"Loading"} />;

  return !isNil(userInfo.result) && !isNil(userInfo.result.data) ? (
    <PortalUserContext.Provider
      value={{
        portalUser: userInfo.result.data,
        reload: (goBack) => {
          goBack && history.goBack();
          userInfo.execute(props.userType);
        },
        preselectedEducationalInstituteType: preselectedEducationalInstituteType,
      }}
    >
      {props.children}
    </PortalUserContext.Provider>
  ) : null;
}

// enum PortalUserStoreDispatch {
//   AddInstitute,
//   RemoveInstitute,
//   ReloadPortalUser,
// }
//
// type Action<
//   Dispatch extends PortalUserStoreDispatch,
//   Payload extends object | undefined = undefined
// > = {
//   type: Dispatch;
// } & IncludeWhen<Payload, object, { payload: Payload }>;
//
// type AddInstituteQuery = GetQueryType<
//   PumaContextApi["portalUsers"]["portalUsersInstitutesCreate"],
//   1
// >;
// type RemoveInstituteQuery = GetQueryType<
//   PumaContextApi["portalUsers"]["portalUsersInstitutesDelete"],
//   1
// >;
// type PortalUserReducerAction =
//   | Action<PortalUserStoreDispatch.AddInstitute, AddInstituteQuery>
//   | Action<PortalUserStoreDispatch.RemoveInstitute, RemoveInstituteQuery>
//   | Action<PortalUserStoreDispatch.ReloadPortalUser>;
//
// const addInstituteInitialValues: AddInstituteQuery = {
//   instituteId: undefined,
//   isMainInstitute: undefined,
//   teacherVerification: undefined,
// };
//
// type PickedUseAsyncReturn<R = unknown, Args extends any[] = any[]> = Omit<
//   UseAsyncReturn<R, Args>,
//   "execute" | "set" | "merge" | "reset"
// >;
//
// type MapDispatchToAction<Dispatch extends PortalUserStoreDispatch> =
//   Dispatch extends PortalUserStoreDispatch.RemoveInstitute
//     ? Action<PortalUserStoreDispatch.AddInstitute, AddInstituteQuery>
//     : Dispatch extends PortalUserStoreDispatch.AddInstitute
//     ? Action<PortalUserStoreDispatch.RemoveInstitute, RemoveInstituteQuery>
//     : Dispatch extends PortalUserStoreDispatch.ReloadPortalUser
//     ? Action<PortalUserStoreDispatch.ReloadPortalUser>
//     : never;
//
// type ActionRequest<
//   Dispatch extends PortalUserStoreDispatch,
//   Action = MapDispatchToAction<Dispatch>,
//   R = unknown,
//   Args extends any[] = any
// > = Action & {
//   request: PickedUseAsyncReturn<R, Args>;
// };
//
// type MapDispatchToRequest<Dispatch extends PortalUserStoreDispatch> =
//   Dispatch extends PortalUserStoreDispatch.RemoveInstitute
//     ? ActionRequest<Dispatch, void, [string]>
//     : Dispatch extends PortalUserStoreDispatch.AddInstitute
//     ? ActionRequest<Dispatch, void, [AddInstituteQuery]>
//     : never;
//
// interface ReducerState {
//   portalUser: GetPortalUserByIdGetPortalUserByIdQueryResponse;
//   // activeRequest: MapDispatchToRequest<any> | null;
//   getRequest?: <T extends PortalUserStoreDispatch>(actionType: T) => MapDispatchToRequest<T>;
// }
//
// function usePortalUserReducer(portalUser: GetPortalUserByIdGetPortalUserByIdQueryResponse) {
//   const api = usePumaContextApi();
//   const options = {
//     executeOnMount: false,
//     executeOnUpdate: false,
//   };
//   const addInstituteRequest = useAsync<void, [AddInstituteQuery]>(
//     async (query: AddInstituteQuery) => {
//       await api.portalUsers.portalUsersInstitutesCreate(portalUser.id!, query);
//     },
//     [addInstituteInitialValues],
//     options
//   );
//
//   // create custom useAsync for multiple calls which creates a queue
//   // investigate RxJs for that
//   const removeInstituteRequest = useAsync<void, [string]>(
//     async (query: string) => {
//       await api.portalUsers.portalUsersInstitutesDelete(portalUser.id!, query);
//     },
//     [""],
//     options
//   );
//
//   const [state, setState] = useState<ReducerState>({ portalUser });
//
//   const dispatchState = useCallback(
//     (action: PortalUserReducerAction) => portalUserReducer(state, action),
//     []
//   );
//
//   useEffect(() => {
//     if (!addInstituteRequest.loading)
//       dispatchState({ type: PortalUserStoreDispatch.ReloadPortalUser });
//   }, [addInstituteRequest.loading]);
//
//   return state;
//
//   // return [state, dispatchState];
//
//   function portalUserReducer<T extends PortalUserReducerAction>(
//     state: ReducerState,
//     action: T
//   ): MapDispatchToRequest<T["type"]> {
//     switch (action.type) {
//       case PortalUserStoreDispatch.AddInstitute: {
//         addInstituteRequest.execute(action.payload as AddInstituteQuery);
//         //@ts-ignore
//         return { actionType: action.type, ...addInstituteRequest };
//       }
//       case PortalUserStoreDispatch.RemoveInstitute:
//         return state;
//       default:
//         assertUnreachable(action);
//     }
//   }

// function getRequest<T extends PortalUserStoreDispatch>(x: T): MapDispatchToRequest<T> {
//   switch (x) {
//     case PortalUserStoreDispatch.RemoveInstitute:
//       return removeInstituteRequest;
//     case PortalUserStoreDispatch.AddInstitute:
//       return addInstituteRequest;
//     default:
//       assertUnreachable(x);
//   }
// }

// function useAsyncReducer(reducer, initState) {
//   const [state, setState] = useState(initState);
//   const dispatchState = async (action) => setState(await reducer(state, action));
//   return [state, dispatchState];
// }
//
// async function reducer(state, action) {
//   switch (action.type) {
//     case "switch1":
//       // Do async code here
//       return "newState";
//   }
// }
//
// function App() {
//   const [state, dispatchState] = useAsyncReducer(reducer, "initState");
//   return <ExampleComponent dispatchState={dispatchState} />;
// }
//
// function ExampleComponent({ dispatchState }) {
//   return <button onClick={() => dispatchState({ type: "switch1" })}>button</button>;
// }
