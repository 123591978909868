import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { UIStateProvider } from "../contexts";
import LayoutBody from "./LayoutBody";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    width: "100%",
    height: "100vh",
    flexDirection: "column",
  },
  appBody: {
    display: "flex",
    flexDirection: "row",
    flex: "1 1 100%",
    width: "100%",

    "& > div": {
      width: "100%",
    },
  },
}));

interface IProps {
  children: React.ReactNode;
}

export function Layout(props: IProps) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <UIStateProvider>
        <LayoutBody>{props.children}</LayoutBody>
      </UIStateProvider>
    </div>
  );
}
