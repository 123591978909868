import * as React from "react";
import { IntlProvider } from "react-intl";
import { UIStateContext } from "../../contexts";
import { useAsync } from "react-async-hook";
import { useCommonApi } from "../../api";
import { Loading } from "../Loading";
import { Center } from "../Center";

interface IProps {
  children: React.ReactNode;
}

export function AsyncIntlProvider(props: IProps) {
  const commonApi = useCommonApi();
  const { currentCulture } = React.useContext(UIStateContext);

  const messages = useAsync(() => commonApi.i18N.i18NResourcesList(), [currentCulture]);

  if (messages.loading) {
    return <Loading msg="i18n" />;
  } else if (messages.error) {
    return <Center>Failed to load text resources.</Center>;
  } else if (messages.result != null) {
    return (
      <IntlProvider locale={currentCulture} messages={messages.result.data}>
        {props.children}
      </IntlProvider>
    );
  } else {
    return <strong>Could not load i18n text resources.</strong>;
  }
}
