import urljoin from "url-join";
import { PostHelperResponse } from "../contexts";
import { OnLoadData } from "@dccs/react-datagrid-plain";
import { SortDirection } from "@dccs/react-table-plain";
import { hasValues } from "../utils";
import _ from "lodash";

type SetUIErrorFn = (name: string, error: string) => void;

interface IFormErrors {
  [key: string]: string[];
}

export function apiUrl(relative: string) {
  return urljoin(`${window.apiUrl}/api`, relative);
}

export const createHandleServerErrors =
  (setUIErrorFn: SetUIErrorFn, fieldNamesMap?: Map<string, string>) =>
  (data: PostHelperResponse | any, shouldAlert: boolean = true) => {
    if (data) {
      // need this if for httpresponse object from generated api client
      // it looks different than the old response
      if (data.status) {
        switch (data.status) {
          case 400:
            handleServerValidationError(data.error, setUIErrorFn, fieldNamesMap);
            break;
          case 500:
            if (shouldAlert) alert("Uups. Da ist uns auf dem Server ein Fehler passiert!");
            break;
        }
      } else {
        switch (data.response.status) {
          case 400:
            handleServerValidationError(data.json, setUIErrorFn, fieldNamesMap);
            break;
          case 500:
            if (shouldAlert) alert("Uups. Da ist uns auf dem Server ein Fehler passiert!");
            break;
        }
      }
    }
  };

export const createJsonServerSource =
  (get: (url: string) => Promise<any>, url: string) =>
  (
    page: number,
    rowsPerPage: number,
    orderBy?: string,
    sort?: "desc" | "asc",
    filter?: { [key: string]: any }
  ) => {
    const urlSep = url.indexOf("?") === -1 ? "?" : "&";
    const p = new Promise<{ total: number; data: any[] }>((res, rej) => {
      get(
        `${url}${urlSep}_page=${page}&_limit=${rowsPerPage}&_sort=${orderBy}&_order=${sort}${serializeFilter(
          filter || {}
        )}`
      )
        .then((resp) => res({ data: resp, total: resp.length }))
        .catch(rej);
    });

    return p;
  };

function serializeFilter(filter: { [key: string]: any }) {
  let query = "";
  // tslint:disable-next-line:forin
  for (const x in filter) {
    if (filter[x] != null) {
      query += `&${x}=${encodeURIComponent(filter[x])}`;
    }
  }
  return query;
}

function handleServerValidationError(
  errors: IFormErrors,
  setUIError: SetUIErrorFn,
  fieldNamesMap?: Map<string, string>
) {
  let formikFieldsAndMessages: Record<string, string[]> = {};
  for (const property in errors) {
    if (_.isNil(property)) continue;
    const formikFieldName = getFieldName(property);
    const message = errors[property].join(" ");
    assignMessageToField(formikFieldName, message);
  }
  setUIErrors(formikFieldsAndMessages);

  function getFieldName(property: string) {
    const camelCasedProperty = lowerAllFirstLetters(property);

    const fieldName = fieldNamesMap?.has(camelCasedProperty)
      ? fieldNamesMap?.get(camelCasedProperty)
      : camelCasedProperty;

    if (_.isNil(fieldName)) throw Error("Missing mapping between command and form field.");
    return fieldName;
  }

  function assignMessageToField(fieldName: string, message: string) {
    let field = formikFieldsAndMessages[fieldName];
    if (Array.isArray(field)) {
      field.push(message);
    } else {
      formikFieldsAndMessages[fieldName] = [message];
    }
  }

  function setUIErrors(fieldsAndMessages: Record<string, string[]>) {
    if (hasValues(fieldsAndMessages)) {
      Object.keys(fieldsAndMessages).forEach((key) =>
        setUIError(key, fieldsAndMessages[key].join(", "))
      );
    }
  }
}

function lowerAllFirstLetters(str: string) {
  const strs = str.split(".");
  const lowered = strs.map(lowerFirstLetter);
  return lowered.join(".");
}

function lowerFirstLetter(str: string) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

interface Result<D> {
  page?: number | null;
  count?: number | null;
  orderBy?: string | null;
  desc?: boolean;
  data?: D[] | null;
  total?: number;
}

interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D | null;
  error: E | null;
}

export function api2SourceAdapter<data>(
  api: (query?: object) => Promise<HttpResponse<Result<data>>>,
  query?: object
): OnLoadData {
  return (
    page: number,
    rowsPerPage: number,
    orderBy: string | undefined,
    sort: SortDirection | undefined,
    filter: { [key: string]: any } | undefined
  ) => {
    return new Promise<{
      total: number;
      data: data[];
    }>((res, rej) => {
      api({ ...(query || {}), page, count: rowsPerPage, orderBy, desc: sort === "desc", filter })
        .then((hr) =>
          res({
            total: hr?.data?.total ? hr.data.total : 0,
            data: hr?.data?.data ? hr.data.data : [],
          })
        )
        .catch(rej);
    });
  };
}
