import { Box, Button, Dialog, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Layout } from "../layouts";
import { useTranslate } from "../utils/customHooks/useTranslate";
import { BoldTypography } from "./BoldTypography";
import { ContentWrapper } from "./ContentWrapper";
import { CustomIcon } from "./icons/CustomIcon";
import { PageWrapper } from "./PageWrapper";

interface Props {
  title: string;
  subTitle: string;
  text: string;
  icon: string;
  onClose: () => void;
}

export function InfoPage(props: Props) {
  const t = useTranslate();
  const classes = useStyles();

  return (
    <Dialog open fullScreen onClose={props.onClose}>
      <Layout>
        <PageWrapper title={props.title} hideBackButton onClose={props.onClose}>
          <ContentWrapper>
            <Box mb={2}>
              <CustomIcon name={props.icon} height={250} width={250} />
            </Box>
            <BoldTypography align="center">{props.subTitle}</BoldTypography>
            <Typography align="center">{props.text}</Typography>
            <Button
              color={"primary"}
              variant={"contained"}
              type="submit"
              className={classes.submitButton}
              onClick={props.onClose}
            >
              <BoldTypography>{t("back")}</BoldTypography>
            </Button>
          </ContentWrapper>
        </PageWrapper>
      </Layout>
    </Dialog>
  );
}

//#region styles
const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(4),
    borderRadius: 8,
    width: "250px",
  },
  dialog: {
    width: "100%",
    height: "100%",
    maxHeight: "100%",
    margin: 0,
  },
}));
//#endregion
