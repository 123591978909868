import React, { useContext } from "react";
import { Stepper } from "../../components/Stepper/Stepper";
import { ColumnFlexBox } from "../../components/Boxes/ColumnFlexBox";
import { BoldTypography } from "../../components/BoldTypography";
import { Box, Button, CircularProgress, Divider, makeStyles, Typography } from "@material-ui/core";
import { useTranslate } from "../../utils/customHooks/useTranslate";
import { InfoBox } from "../../components/InfoBox";
import { RegistrationStep } from "./enum/RegistrationStep";
import { PageWrapper } from "../../components/PageWrapper";
import { UserRegistrationStepTitleAndSubtitle } from "./UserRegistrationStepTitleAndSubtitle";
import { EXTENDED_NORMAL_WIDTH, MOBILE_WIDTH } from "../../utils/layoutHelper";
import { useRegistrationContext } from "../../utils/customHooks/useRegistrationContext";
import { ViewHandlerContext } from "../ViewHandler";
import { InterruptRegistrationPageType } from "./InterruptRegistrationPage";
import { AuthenticationContext } from "../../auth";

interface Props {
  showInterruptPage: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      onFinishLater: () => void;
      onFinishNow: () => void;
      pageType: InterruptRegistrationPageType;
    }>
  >;
  isSubmitting: boolean;
}

export function UserRegistrationDetails(props: Props) {
  const classes = useStyles();
  const t = useTranslate();

  const { activeStep, steps, formik } = useRegistrationContext();

  const stepperSteps = steps
    .filter((x) => x.type !== RegistrationStep.BasicInfo)
    .map((x) => ({ id: x.type }));

  const { isTestingMode } = useContext(ViewHandlerContext);

  const showStepper = activeStep.type !== RegistrationStep.BasicInfo;
  const title =
    activeStep.type === RegistrationStep.BasicInfo ? t("welcome") : t("completeProfile");

  const noSchoolSelectedButton =
    activeStep.type === RegistrationStep.AddSchools && formik.values.schools.length === 0;

  const { logout } = React.useContext(AuthenticationContext);

  return (
    <PageWrapper
      title={title}
      onBackButtonClick={{
        callback: () => {
          if (activeStep.isFirstStep) logout();
          else activeStep.returnToPreviousStep();
        },
        shouldNavigateBack: false,
      }}
    >
      {showStepper && (
        <Box mb={3}>
          <Stepper steps={stepperSteps} activeStep={{ id: activeStep.type }} />
        </Box>
      )}
      <UserRegistrationStepTitleAndSubtitle mb={2} />
      <ColumnFlexBox className={classes.wrapper} alignItems="center">
        {activeStep.content}
      </ColumnFlexBox>
      {props.isSubmitting ? (
        <CircularProgress />
      ) : (
        <Button
          color={"primary"}
          variant={noSchoolSelectedButton ? "outlined" : "contained"}
          onClick={() => formik.handleSubmit()}
          className={classes.submitButton}
          disabled={props.isSubmitting || (activeStep.isLastStep && isTestingMode)}
        >
          {noSchoolSelectedButton ? (
            <Typography>{t("noSchoolSelectedButton")}</Typography>
          ) : (
            <BoldTypography>{activeStep.isLastStep ? t("save") : t("next")}</BoldTypography>
          )}
        </Button>
      )}

      {activeStep.additionalData ? (
        <ColumnFlexBox className={classes.wrapper}>
          <Divider className={classes.divider} />
          <InfoBox
            title={activeStep.additionalData.title}
            text={activeStep.additionalData.text}
            link={activeStep.additionalData.link}
          />
        </ColumnFlexBox>
      ) : null}
    </PageWrapper>
  );
}

//#region styles
const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(2),
    borderRadius: 8,
    width: "250px",
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  wrapper: {
    width: EXTENDED_NORMAL_WIDTH + "px",
    [theme.breakpoints.down("xs")]: {
      width: MOBILE_WIDTH + "px",
    },
  },
}));
//#endregion
