import {CircularProgress, makeStyles} from "@material-ui/core";
import React, {useContext, useState} from "react";
import {FormikTextFieldWithLabel} from "../../../components/Formik/FormikTextFieldWithLabel";
import {PortalUserContext} from "../../PortalUserProvider";
import {usePropertyNames} from "../../../utils/customHooks/usePropertyNames";
import {useTranslate} from "../../../utils/customHooks/useTranslate";
import {EXTENDED_NORMAL_WIDTH, MOBILE_WIDTH} from "../../../utils/layoutHelper";
import {getDetails, Suggestion} from "use-places-autocomplete";
import {Option} from "../../../components";
import {getPlaceInfo, PlaceResult} from "../../../components/GeoAutocomplete/googleUtils";
import {GooglePlacesAutocompleteWithLabel} from "../../../components/GeoAutocomplete/GooglePlacesAutocompleteWithLabel";
import {ContentWrapper} from "../../../components/ContentWrapper";
import {RowFlexBox} from "../../../components/Boxes/RowFlexBox";
import {PageWrapperLoaderContext} from "../../../components/PageWrapper";
import {createHandleServerErrors, RequestsAddressRequest, usePumaContextApi} from "../../../api";
import {useLocation, useParams} from "react-router-dom";
import {useAsync} from "react-async-hook";
import {assertUnreachable, parseQueryString} from "../../../utils";
import _ from "lodash";
import {NavigationButtons} from "../../../components/NavigationButtons";
export const ZIP_PLACEHOLDER = "1234";

export enum ActionType {
    Add,
    Edit,
}

export interface IUserAddressFormValues {
    city: string;
    street: string;
    buildingNumber: string;
    buildingNumberAddition: string;
    zip: string;
    state: string;
    country: string | null;
    district: string;
    countryCode: string | null;
    recipientName: string | null;
    recipientHandlerName: string | null;
    zipIsEditable: boolean;
    cityIsGoogleSearchable: boolean;
    streetIsGoogleSearchable: boolean;
    isMainAddress: boolean;
}

type Props = {
    type: ActionType;
    onSuccess?: () => void;
};

export function AddressForm(props: Props) {
    const t = useTranslate();
    const classes = useStyles();

    const {submitAddress, initialValues} = useSubmitAddress(props.type, props.onSuccess);
    const names = usePropertyNames(initialValues);
    const [isSaveButtonActive, setIsSaveButtonActive] = React.useState<boolean>(false);

    return (
        <ContentWrapper
            className={classes.wrapper}
            mt={2}
            mb={2}
            hasFormik
            onChange={()=>setIsSaveButtonActive(true)}
            initialValues={initialValues}
            onSubmit={async (fv, fh) => {
                await submitAddress(fv, fh.setSubmitting, fh.setFieldError);
            }}
        >
            {(formik) => (
                <>
                    {!initialValues.isMainAddress && <>
                        <FormikTextFieldWithLabel
                            fullWidth
                            placeholder={t("firma")}
                            name={names.recipientName}
                            {...formik}
                        />
                        <FormikTextFieldWithLabel
                            name={names.recipientHandlerName}
                            fullWidth
                            placeholder={t("zuHanden")}
                            {...formik}
                        />
                    </>
                    }
                    <GooglePlacesAutocompleteWithLabel
                        fullWidth
                        label={t("address")}
                        name={"fetchedAddress"}
                        disableClearable
                        onChange={async (event, val) => {
                            if (val === null || val === undefined) return;

                            const placeDetails = (await getDetails({
                                placeId: (val as Option<Suggestion>).value.place_id,
                                fields: ["formatted_address", "address_component", "place_id", "adr_address"],
                            })) as PlaceResult;

                            parseAddress();

                            function parseAddress() {
                                const placeInfo = getPlaceInfo(placeDetails);
                                formik.setFieldValue(names.country, placeInfo.countryCode);
                                formik.setFieldValue(names.countryCode, placeInfo.countryCode);
                                formik.setFieldValue(names.city, placeInfo.city);
                                formik.setFieldValue(names.state, placeInfo.state);
                                formik.setFieldValue(names.district, placeInfo.district);
                                formik.setFieldValue(names.zip, placeInfo.zip);
                                formik.setFieldValue(names.street, placeInfo.street);
                                formik.setFieldValue(names.buildingNumber, placeInfo.houseNumber);
                                formik.setFieldValue(names.streetIsGoogleSearchable, _.isEmpty(placeInfo.street));
                                formik.setFieldValue(names.cityIsGoogleSearchable, _.isEmpty(placeInfo.city));
                                formik.setFieldValue(names.zipIsEditable, _.isEmpty(placeInfo.zip));
                            }
                        }}
                    />

                    {formik.values.streetIsGoogleSearchable ? (
                        <GooglePlacesAutocompleteWithLabel
                            fullWidth
                            label={t("street")}
                            TextFieldProps={{placeholder: t("street")}}
                            hideLabel
                            freeSolo
                            name={names.street}
                            value={formik.values.street}
                            disableClearable
                            onChange={async (event, val) => {
                                if (_.isNil(val)) return;

                                const placeDetails = (await getDetails({
                                    placeId: (val as Option<Suggestion>).value.place_id,
                                    fields: ["formatted_address", "address_component", "place_id", "adr_address"],
                                })) as PlaceResult;

                                const placeInfo = getPlaceInfo(placeDetails);

                                formik.setFieldValue(names.street, placeInfo.street);
                                formik.setFieldValue(names.buildingNumber, placeInfo.houseNumber);
                                formik.setFieldValue(names.city, placeInfo.city);
                                formik.setFieldValue(names.cityIsGoogleSearchable, _.isEmpty(placeInfo.city));
                                formik.setFieldValue(names.zip, placeInfo.zip);
                                formik.setFieldValue(names.zipIsEditable, _.isEmpty(placeInfo.zip));
                                formik.setFieldValue(names.district, placeInfo.district);
                                formik.setFieldValue(names.state, placeInfo.state);
                                formik.setFieldValue(names.country, placeInfo.countryCode);
                                formik.setFieldValue(names.countryCode, placeInfo.countryCode);
                            }}
                        />
                    ) : (
                        <FormikTextFieldWithLabel
                            label={t("street")}
                            placeholder={t("street")}
                            name={names.street}
                            fullWidth
                            disabled
                            {...formik}
                        />
                    )}
                    <RowFlexBox width={"100%"} justifyContent={"space-between"}>
                        <FormikTextFieldWithLabel
                            label={"Nr."}
                            placeholder={t("buildingNumber")}
                            name={names.buildingNumber}
                            style={{minWidth: 0}}
                            {...formik}
                        />
                        <FormikTextFieldWithLabel
                            label={t("addition")}
                            placeholder={t("addition")}
                            name={names.buildingNumberAddition}
                            style={{minWidth: 0}}
                            {...formik}
                        />
                    </RowFlexBox>
                    <FormikTextFieldWithLabel
                        key={formik.values.zipIsEditable ? "1234" : "5678"}
                        label={t("zip")}
                        placeholder={t("zip")}
                        disabled={!formik.values.zipIsEditable}
                        name={names.zip}
                        fullWidth
                        {...formik}
                    />
                    {formik.values.cityIsGoogleSearchable ? (
                        <GooglePlacesAutocompleteWithLabel
                            fullWidth
                            label={t("place")}
                            TextFieldProps={{placeholder: t("place")}}
                            hideLabel
                            freeSolo
                            name={names.city}
                            value={formik.values.city}
                            disableClearable
                            onChange={async (event, val) => {
                                if (_.isNil(val)) return;

                                const placeDetails = (await getDetails({
                                    placeId: (val as Option<Suggestion>).value.place_id,
                                    fields: ["formatted_address", "address_component", "place_id", "adr_address"],
                                })) as PlaceResult;

                                const placeInfo = getPlaceInfo(placeDetails);
                                formik.setFieldValue(names.city, placeInfo.city);
                            }}
                        />
                    ) : (
                        <FormikTextFieldWithLabel
                            label={t("place")}
                            placeholder={t("placeName")}
                            name={names.city}
                            disabled
                            fullWidth
                            {...formik}
                        />
                    )}
                    <FormikTextFieldWithLabel
                        label={t("country")}
                        placeholder={t("country")}
                        name={names.country}
                        fullWidth
                        disabled
                        {...formik}
                    />

                    {
                        formik.isSubmitting ? <CircularProgress/> :
                            <NavigationButtons isSubmitting={formik.isSubmitting}
                                               onSave={() => formik.handleSubmit()} 
                                               disabled={!isSaveButtonActive}/>

                    }
                </>
            )}
        </ContentWrapper>
    );
}

function useSubmitAddress(actionType: ActionType, onSuccess: (() => void) | undefined) {
    const api = usePumaContextApi();
    const setShowPageWrapperLoader = useContext(PageWrapperLoaderContext);
    const {reload, portalUser} = useContext(PortalUserContext);
    // Get the addressId from params or search
    const {addressId: paramsAddressId} = useParams<{ addressId: string }>();
    const searchAddressId = parseQueryString(useLocation().search)["addressId"];
    const addressId = paramsAddressId || searchAddressId;

    const [address] = useState(portalUser.addresses?.find((x) => x.id === addressId));

    const initialValues: IUserAddressFormValues = {
        street: address?.street || "",
        buildingNumber: address?.buildingNumber || "",
        zip: address?.zip || "",
        state: address?.state || "",
        country: address?.country || null,
        city: address?.city || "",
        district: address?.city || "",
        countryCode: address?.country || "",
        buildingNumberAddition: address?.buildingNumberAddition || "",
        recipientHandlerName: address?.recipientHandlerName || "",
        recipientName: address?.recipientName || "",
        cityIsGoogleSearchable: false,
        streetIsGoogleSearchable: false,
        zipIsEditable: false,
        isMainAddress: address?.addressType === 1,
    };

    const submitAddressRequest = useAsync(
        async (
            form: IUserAddressFormValues | null,
            setSubmitting: (isSubmitting: boolean) => void,
            setFieldError: (field: string, message: string | undefined) => void
        ) => {
            const isThereMainAddress = portalUser.addresses?.some((x) => x.addressType === 1);

            const request: RequestsAddressRequest = {
                addressType:
                    address?.addressType != null ? address?.addressType : isThereMainAddress ? 2 : 1,
                country: form?.country,
                state: form?.state,
                city: form?.city,
                zip: form?.zip,
                street: form?.street,
                buildingNumber: form?.buildingNumber,
                buildingNumberAddition: form?.buildingNumberAddition,
                district: form?.district || "",
                recipientHandlerName: form?.recipientHandlerName,
                recipientName: form?.recipientName,
            };
            try {
                switch (actionType) {
                    case ActionType.Edit:
                        await api.portalUsers.portalUsersAddressesUpdate(
                            address!.id!,
                            request!
                        );

                        break;
                    case ActionType.Add:
                        await api.portalUsers.portalUsersAddressesCreate(request);
                        break;
                    default:
                        assertUnreachable(actionType);
                }

                onSuccess && onSuccess();
                reload(true);
            } catch (e) {
                if (e.status !== 400) reload(true);
                createHandleServerErrors(setFieldError)(e, false);
                setSubmitting(false);
            }
        },
        [null, () => {
        }, () => {
        }],
        {executeOnMount: false, executeOnUpdate: false}
    );

    setShowPageWrapperLoader!(submitAddressRequest.loading);

    return {submitAddress: submitAddressRequest.execute, initialValues};
}

//#region styles
const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: EXTENDED_NORMAL_WIDTH + "px",
        [theme.breakpoints.down("xs")]: {
            width: MOBILE_WIDTH + "px",
        },
    },
    button: {
        width: MOBILE_WIDTH + "px",
    },
}));
//#endregion
