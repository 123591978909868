import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslate } from "../../../utils/customHooks/useTranslate";
import { PASSWORD_PLACEHOLDER } from "../../../utils/passwordHelper";
import { PortalUserContext } from "../../PortalUserProvider";
import { UserEmail } from "./UserEmail";
import { UserName } from "./UserName";
import { UserFullName } from "./UserFullName";

export function UserPersonalDataWidget() {
  const classes = useStyles();
  const t = useTranslate();
  const { portalUser } = React.useContext(PortalUserContext);

  return (
    <Box className={classes.wrapper} padding={2}>
      <Typography>{portalUser.genderDescription}</Typography>
      <UserFullName />
      <Box mt={1}>
        <UserName hideLabel />
        <UserEmail />
      </Box>
      <Box mt={1}>
        <Typography>
          {t("password")}: {PASSWORD_PLACEHOLDER}
        </Typography>
      </Box>
    </Box>
  );
}

//#region styles
const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      background: theme.palette.background.default,
      borderRadius: "8px",
      width: "100%",
      boxSizing: "border-box",

      [theme.breakpoints.down("xs")]: {
        backgroundColor: theme.palette.background.paper,
      },
    },
  };
});
//#endregion
