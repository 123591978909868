import { Box, Link, makeStyles, Typography } from "@material-ui/core";
import { Formik, FormikProps } from "formik";
import React, { useMemo } from "react";
import {
  createHandleServerErrors,
  RequestsUpdatePortalUserRequest,
  usePumaContextApi,
} from "../../../api";
import { ContentWrapper } from "../../../components/ContentWrapper";
import { FormikRadioGroup } from "../../../components/Formik/FormikRadioGroup";
import { FormikTextFieldWithLabel } from "../../../components/Formik/FormikTextFieldWithLabel";
import { PageWrapper } from "../../../components/PageWrapper";
import { PortalUserContext } from "../../../features/PortalUserProvider";
import { UserPassword } from "../../../features/profile/basicInfo/UserPassword";
import { usePropertyNames } from "../../../utils/customHooks/usePropertyNames";
import { useTranslate } from "../../../utils/customHooks/useTranslate";
import { EXTENDED_NORMAL_WIDTH, MOBILE_WIDTH } from "../../../utils/layoutHelper";
import { AuthenticationContext } from "../../../auth";
import { ObjectSchema } from "yup";
import { useGendersAsync } from "../../../features/registration/form/RegistrationBasicInfo";
import { useCustomHistory } from "../../../utils/customHooks/useCustomHistory";
import { routes } from "../../../routes/routes";
import { AcademicDegreeDropdown } from "../../../components/Dropdowns/AcademicDegreeDropdown";
import { UserType } from "../../../utils/beEnumMapping";
import { NavigationButtons } from "../../../components/NavigationButtons";
import { PersonalDataValidationObject } from "../../../utils/validations";

interface IUserPersonalDataFormValues {
  firstName: string;
  lastName: string;
  salutation: string;
  userName: string;
  email: string;
  academicDegree: number | null;
  additionalAcademicDegree: number | null;
}

export function EditPersonalDataPage() {
  const api = usePumaContextApi();
  const t = useTranslate();
  const { authority } = React.useContext(AuthenticationContext);

  const changeEmailUrl = new URL("Identity/Account/Manage/ChangeEmail", authority);
  changeEmailUrl.searchParams.append("returnUrl", window.location.href);

  const changeUserNameUrl = new URL("Identity/Account/Manage/ChangeUserName", authority);
  changeUserNameUrl.searchParams.append("returnUrl", window.location.href);

  const classes = useStyles();
  const { reload, portalUser } = React.useContext(PortalUserContext);

  const initialValues: IUserPersonalDataFormValues = {
    firstName: portalUser.firstName || "",
    lastName: portalUser.lastName || "",
    salutation: portalUser.genderCode || "-",
    userName: portalUser.userName || "",
    email: portalUser.email || "",
    academicDegree: portalUser.academicDegree || null,
    additionalAcademicDegree: portalUser.additionalAcademicDegree || null,
  };

  const gendersRequest = useGendersAsync();

  const propertyNames = usePropertyNames(initialValues);
  const validationSchema = useValidationSchema();

  const history = useCustomHistory();

  const showAcademicDegreeDropdowns = [
    UserType.Teacher.valueOf(),
    UserType.Other.valueOf(),
    UserType.KindergartenTeacher.valueOf(),
  ].includes(portalUser.type ?? -1);
  const [isSaveButtonActive, setIsSaveButtonActive] = React.useState<boolean>(false);

  return gendersRequest.loading ? null : (
    <PageWrapper hideBackButton title={t("editData")}>
      <Formik
        onSubmit={(form, { setSubmitting, setFieldError }) =>
          updatePersonalInfo(form, setSubmitting, setFieldError)
        }
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(formik: FormikProps<IUserPersonalDataFormValues>) => (
          <form
            onSubmit={formik.handleSubmit}
            onChange={() => {
              setIsSaveButtonActive(true);
            }}
          >
            <ContentWrapper>
              <Box className={classes.wrapper} mt={2} mb={2}>
                <Typography variant={"h2"}>{t("personalData")}</Typography>
                <FormikRadioGroup
                  name={propertyNames.salutation}
                  options={gendersRequest.options}
                  {...formik}
                />
                {showAcademicDegreeDropdowns && (
                  <AcademicDegreeDropdown name={propertyNames.academicDegree} type={"base"} />
                )}

                <FormikTextFieldWithLabel
                  label={t("firstName")}
                  placeholder={t("firstName")}
                  name={propertyNames.firstName}
                  fullWidth
                  {...formik}
                />
                <FormikTextFieldWithLabel
                  label={t("lastName")}
                  placeholder={t("lastName")}
                  name={propertyNames.lastName}
                  fullWidth
                  {...formik}
                />
                {showAcademicDegreeDropdowns && (
                  <AcademicDegreeDropdown
                    name={propertyNames.additionalAcademicDegree}
                    type={"additional"}
                  />
                )}
                <FormikTextFieldWithLabel
                  label={t("userName")}
                  placeholder={t("userName")}
                  name={propertyNames.userName}
                  disabled
                  fullWidth
                  {...formik}
                />
                <Box mb={2}>
                  <FormikTextFieldWithLabel
                    label={t("emailAddress")}
                    placeholder={t("emailAddress")}
                    name={propertyNames.email}
                    disabled
                    fullWidth
                    {...formik}
                  />
                </Box>
                <Link underline="always" href={changeEmailUrl.href} color="textSecondary">
                  <Typography>{t("changeEmail")}</Typography>
                </Link>
                <Link underline="always" href={changeUserNameUrl.href} color="textSecondary">
                  <Typography>{t("changeUserName")}</Typography>
                </Link>
                <UserPassword />
              </Box>
              <NavigationButtons
                isSubmitting={formik.isSubmitting}
                backRoute={routes.userProfileData}
                disabled={!isSaveButtonActive}
              />
            </ContentWrapper>
          </form>
        )}
      </Formik>
    </PageWrapper>
  );

  function useValidationSchema(): ObjectSchema<Partial<IUserPersonalDataFormValues>> {
    const t = useTranslate();

    return useMemo(
      () => PersonalDataValidationObject(t),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );
  }

  function updatePersonalInfo(
    form: IUserPersonalDataFormValues,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldError: (field: string, message: string | undefined) => void
  ) {
    const request: RequestsUpdatePortalUserRequest = {
      firstName: form.firstName,
      lastName: form.lastName,
      genderId: form.salutation,
      academicDegree: form.academicDegree,
      additionalAcademicDegree: form.additionalAcademicDegree,
    };
    api.portalUsers
      .portalUsersUpdate(request)
      .then(() => {
        reload();
        history.navigate(routes.userProfileData);
      })
      .catch(createHandleServerErrors(setFieldError))
      .finally(() => setSubmitting(false));
  }
}

//#region styles
const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: EXTENDED_NORMAL_WIDTH + "px",
    [theme.breakpoints.down("xs")]: {
      width: MOBILE_WIDTH + "px",
    },
  },
  buttonsWrapper: {
    width: EXTENDED_NORMAL_WIDTH + "px",
    [theme.breakpoints.down("xs")]: {
      width: MOBILE_WIDTH + "px",
    },
    gap: theme.spacing(),
  },
}));

//#endregion
