export const DEFAULT_ROUTE = "/profile/user";
export const EXTERNAL_EDIT_ROUTE = "/profile/user/externalEdit";
export const routes = {
  userRegistration: DEFAULT_ROUTE + "/registration",
  userProfile: DEFAULT_ROUTE,
  userProfileData: DEFAULT_ROUTE + "/data",
  userProfilePersonalDataEdit: DEFAULT_ROUTE + "/data/personal/edit",
  userProfileAddAddress: DEFAULT_ROUTE + "/data/address/add",
  userProfileEditAddress: DEFAULT_ROUTE + "/data/address/:addressId/update",
  userProfileSettingsAndNewsletters: DEFAULT_ROUTE + "/settingAndNewsletters",
  userProfileSettingsAndNewslettersEdit: DEFAULT_ROUTE + "/settingAndNewsletters/edit",
  userProfileAddSchool: DEFAULT_ROUTE + "/school/add",
  userProfileUpdateSchool: DEFAULT_ROUTE + "/school/:instituteId/update",
  userProfileAddInstitute: DEFAULT_ROUTE + "/institute/add",
  //*************************************************************************** */
  externalEditAddAddress: EXTERNAL_EDIT_ROUTE + "/addresses/add",
  externalEditModifyAddress: EXTERNAL_EDIT_ROUTE + "/addresses/:addressId/update",
  externalEditModifyMailingConsent: EXTERNAL_EDIT_ROUTE + "/mailingConsent",
  externalEditVerifyInstitute: EXTERNAL_EDIT_ROUTE + "/institutes/:instituteId/verify",
  // Alternative routes for scook
  externalEditVerifyInstituteAlt: EXTERNAL_EDIT_ROUTE + "/institutes/verify", // ?instituteId=
  externalEditModifyAddressAlt: EXTERNAL_EDIT_ROUTE + "/addresses/update", // ?addressId=
};

export const unauthorizedRoutes = {
  unsubscribe: "/unsubscribe",
};
