import React, {useContext} from "react";
import {PageWrapper} from "../../components/PageWrapper";
import {useTranslate} from "../../utils/customHooks/useTranslate";
import {SchoolWidget} from "../institutes/SchoolWidget/SchoolWidget";
import {Box, makeStyles} from "@material-ui/core";
import {Button} from "../../components/Button";
import {navigateToReturnUrlIfExists} from "../../auth";
import {EXTENDED_NORMAL_WIDTH, MOBILE_WIDTH} from "../../utils/layoutHelper";
import {useLocation, useParams} from "react-router-dom";
import {PortalUserContext} from "../PortalUserProvider";
import {ContentWrapper} from "../../components/ContentWrapper";
import {parseQueryString} from "../../utils";

export function ExternalVerifyInstitutePage() {
    const t = useTranslate();
    const classes = useStyles();
    // Get the addressId from params or search
    const {instituteId: paramsInstituteId} = useParams<{ instituteId: string }>();
    const searchInstituteId = parseQueryString(useLocation().search)["instituteId"];
    const instituteId = paramsInstituteId || searchInstituteId;
    const {portalUser} = useContext(PortalUserContext);

    const institute = portalUser.institutes?.find((x) => x.id === instituteId);
    return (
        <PageWrapper title={t("verifySchool")}>
            <ContentWrapper className={classes.wrapper}>
                <SchoolWidget
                    institute={institute!}
                    isVerified={institute!.authorizationCodeProvided!}
                    showMainAssignmentRadio={false}
                    onValidVerificationCode={() => navigateToReturnUrlIfExists()}
                />
                <Box className={classes.buttonWrapper} mt={2}>
                    <Button onClick={navigateToReturnUrlIfExists} buttonText={t("back")}/>
                </Box>
            </ContentWrapper>
        </PageWrapper>
    );
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: EXTENDED_NORMAL_WIDTH + "px",
        [theme.breakpoints.down("xs")]: {
            width: MOBILE_WIDTH + "px",
            marginTop: theme.spacing(2),
        },
    },
    buttonWrapper: {
        width: MOBILE_WIDTH + "px",
    },
}));
