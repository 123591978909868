import * as React from "react";
import { useDebounce } from "use-debounce";
import { isFunction } from "lodash";

interface IUIState {
  globalSearch: string;
  debouncedGlobalSearch: string;
  setGlobalSearch: (value: string) => void;
  currentCulture: string;
  setCurrentCulture: (value: string) => void;
}

interface IUIStateProviderProps {
  children: React.ReactNode | ((context: IUIState) => React.ReactNode);
}

const UIStateContext = React.createContext<IUIState>({} as IUIState);

const language = (navigator.languages && navigator.languages[0]) || navigator.language;

export function UIStateProvider(props: IUIStateProviderProps) {
  const { children } = props;

  // State
  const [globalSearch, setGlobalSearch] = React.useState("");
  const [debouncedGlobalSearch] = useDebounce(globalSearch, 500);
  const [currentCulture, setCurrentCulture] = React.useState<string>(language);

  const contextValue = {
    globalSearch,
    setGlobalSearch,
    debouncedGlobalSearch,
    currentCulture,
    setCurrentCulture,
  };

  return (
    <UIStateContext.Provider value={contextValue}>
      {isFunction(children) ? children(contextValue) : children}
    </UIStateContext.Provider>
  );
}

export { UIStateContext };
