import { Box } from "@material-ui/core";
import React from "react";
import { FlexBox } from "../../../components/Boxes/FlexBox";
import { UserEmail } from "./UserEmail";
import { UserName } from "./UserName";
import { UserTypeIcon } from "./UserTypeIcon";
import { UserTypeText } from "./UserTypeText";
import { ColumnFlexBox } from "../../../components/Boxes/ColumnFlexBox";
import { UserFullName } from "./UserFullName";

export function UserBasicInfoWidgetMobile() {
  return (
    <>
      <FlexBox alignItems="center">
        <UserTypeIcon />
        <Box ml={2}>
          <UserFullName />
          <UserTypeText />
        </Box>
      </FlexBox>
      <ColumnFlexBox mt={0.5}>
        <UserName />
        <UserEmail />
      </ColumnFlexBox>
    </>
  );
}
