import { Divider, DividerProps, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { CSSProperties } from "@material-ui/styles/withStyles/withStyles";

interface Props extends DividerProps, StyleProps {}

interface StyleProps {
  mt?: number;
  mb?: number;
  hideOnMobile?: boolean;
  width?: CSSProperties["width"];
}

export function FullWidthDivider(props: Props) {
  const classes = useStyles(props);

  return <Divider className={classes.divider} {...props} />;
}

//#region styles
const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  divider: (props) => ({
    width: props.width || "100%",
    marginTop: theme.spacing(props.mt || 0),
    marginBottom: theme.spacing(props.mb || 0),
    [theme.breakpoints.down("xs")]: {
      display: props.hideOnMobile ? "none" : "block",
    },
  }),
}));
//#endregion
