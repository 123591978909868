import React from "react";
import { usePumaContextApi } from "../../api";
import { useTranslate } from "../../utils/customHooks/useTranslate";
import FormikAutocompleteAsync from "../Formik/FormikAutocompleteAsync";
import { mapKvpNumberStringToOption } from "../../utils/mappers";
import { InputLabel, makeStyles } from "@material-ui/core";
import { FormikTextFieldProps } from "@dccs/react-formik-mui";
import clsx from "clsx";

interface AcademicDegreeDropdownProps {
  name: string;
  type: "base" | "additional";
}

export function AcademicDegreeDropdown(props: AcademicDegreeDropdownProps) {
  const pumaApi = usePumaContextApi();
  const t = useTranslate();

  const classes = useStyles();
  const label = props.type === "base" ? t("academicDegree") : t("additionalAcademicDegree");
  const customStyle: Pick<FormikTextFieldProps, "className" | "InputProps" | "hiddenLabel"> = {
    className: clsx(classes.wrapper, classes.textfieldBackground),
    hiddenLabel: true,
    InputProps: {
      classes: { root: classes.root },
    },
  };
  return (
    <>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <FormikAutocompleteAsync
        dataFetcher={async () =>
          (
            await pumaApi.autocomplete.autocompleteAcademicDegreesList({
              Additional: props.type === "additional",
            })
          ).data!
        }
        optionsMapper={mapKvpNumberStringToOption}
        name={props.name}
        placeholder={label}
        label={undefined}
        fullWidth
        TextFieldProps={{
          variant: "outlined",
          ...customStyle,
          placeholder: label,
          InputLabelProps: { shrink: false },
        }}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: theme.typography.fontWeightBold,
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  textfieldBackground: {
    [theme.breakpoints.down("xs")]: {
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  wrapper: {
    margin: 0,
    marginBottom: theme.spacing(2),
  },
  root: {
    borderRadius: 8,
  },
}));
