import React from "react";
import { InstituteWidgetWrapper } from "../shared/InstituteWidgetWrapper";
import { InstituteWidgetTitle } from "../shared/InstituteWidgetTitle";
import { InstituteWidgetMainAddress } from "../shared/InstituteWidgetMainAddress";
import { InstituteWidgetEmails } from "../shared/InstituteWidgetEmails";
import { ResponsesInstituteResponse } from "../../../api";
import { ColumnFlexBox, ColumnFlexBoxProps } from "../../../components/Boxes/ColumnFlexBox";
import { RowFlexBox } from "../../../components/Boxes/RowFlexBox";
import { DeleteIconButton } from "../../../components/DeleteIconButton";

type EducationalInstituteWidgetProps = ColumnFlexBoxProps & {
  institute: ResponsesInstituteResponse;
  onRemoveClick?: () => void;
};

export function EducationalInstituteWidget(props: EducationalInstituteWidgetProps) {
  return (
    <RowFlexBox width={"fill-available"} {...props}>
      <InstituteWidgetWrapper>
        <InstituteWidgetTitle institute={props.institute} mb={0.5} />

        {props.institute.mainAddress && (
          <InstituteWidgetMainAddress institute={props.institute} mt={0.5} />
        )}
        <InstituteWidgetEmails institute={props.institute} />
      </InstituteWidgetWrapper>

      {props.onRemoveClick && (
        <ColumnFlexBox justifyContent={"end"} ml={1.5}>
          <DeleteIconButton onRemove={props.onRemoveClick} />
        </ColumnFlexBox>
      )}
    </RowFlexBox>
  );
}
