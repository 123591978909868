export function parseBooleanString(s: booleanString | undefined): boolean | undefined {
  switch (s) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return undefined;
  }
}

export function parseQueryString( queryString : string ) {
  let params : any = {} ;
      let queries, temp, i, l;

  // Split into key/value pairs
  queries = queryString.substring(1).split("&");

  // Convert the array of strings into an object
  for ( i = 0, l = queries.length; i < l; i++ ) {
    temp = queries[i].split('=');
    params[temp[0]] = temp[1];
  }

  return params;
};

export type booleanString = "true" | "false";
