import React from "react";
import { makeStyles, TextFieldProps } from "@material-ui/core";
import clsx from "clsx";
import { CustomLabel, CustomLabelProps } from "../Formik/CustomLabel";
import { FlexBox } from "../Boxes/FlexBox";
import { GooglePlacesAutocomplete, GooglePlacesAutocompleteProps } from "./GoogleAutocomplete";
import { ColumnFlexBox } from "../Boxes/ColumnFlexBox";

type GoogleAutocompleteWithLabelProps = GooglePlacesAutocompleteProps & CustomLabelProps & {};

export function GooglePlacesAutocompleteWithLabel(props: GoogleAutocompleteWithLabelProps) {
  const classes = useStyles();
  const { label, showLabelAsRequired, error, ...rest } = props;
  const customStyle: Pick<TextFieldProps, "className" | "InputProps" | "variant"> = {
    className: clsx(classes.wrapper, classes.textfieldBackground),
    // InputProps: {
    //   classes: { root: classes.root },
    // },
    variant: "outlined",
  };

  return (
    <ColumnFlexBox width={"100%"}>
      <CustomLabel label={label} showLabelAsRequired={showLabelAsRequired} error={error} />
      <FlexBox alignItems="center" className={classes.fieldWrapper}>
        <GooglePlacesAutocomplete
          {...rest}
          TextFieldProps={{ ...customStyle, ...rest.TextFieldProps }}
        />
      </FlexBox>
    </ColumnFlexBox>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: 0,
  },
  root: {
    borderRadius: 8,
  },
  fieldWrapper: {
    width: "100%",
    gap: theme.spacing(),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(),
  },

  textfieldBackground: {
    [theme.breakpoints.down("xs")]: {
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  label: {
    fontWeight: theme.typography.fontWeightBold,
    width: "100%",
  },
}));
