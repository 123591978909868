import React from "react";
import { Center } from "./Center";
import { CircularProgress } from "@material-ui/core";
export const Loading = ({ msg }: { msg: string }) => (
  <DelayedRender delay={250}>
    <Center style={{ zIndex: 100000000 }}>
      <CircularProgress />
      {/*<Typography>Loading {msg}...</Typography>*/}
    </Center>
  </DelayedRender>
);

const useDelayedRender = (delay: number) => {
  const [delayed, setDelayed] = React.useState(true);
  React.useEffect(() => {
    const timeout = setTimeout(() => setDelayed(false), delay);
    return () => clearTimeout(timeout);
  }, [delay]);
  return (fn: any) => !delayed && fn();
};

export const DelayedRender = ({ delay, children }: { delay: number; children: React.ReactNode }) =>
  useDelayedRender(delay)(() => children);
