import React from "react";
import { Option } from "../Formik";
import { GetCategoryDataCategoryDataDto, usePumaContextApi } from "../../api";
import FormikAutocompleteAsync, {
  FormikAutocompleteAsyncProps,
} from "../Formik/FormikAutocompleteAsync";
import { boolOrUndefined } from "../../utils/generics";
import { makeStyles } from "@material-ui/core";

export enum CategoryGroup {
  StandardPerson = 1,
  Institute,
  Employee,
}

export interface CategoriesDropdownProps
  extends Omit<
    FormikAutocompleteAsyncProps<
      GetCategoryDataCategoryDataDto,
      number,
      true,
      boolOrUndefined,
      false
    >,
    "options" | "dataFetcher" | "optionsMapper"
  > {
  categoryGroups?: CategoryGroup[];
}

export function CategoriesDropdown(props: CategoriesDropdownProps) {
  const pumaApi = usePumaContextApi();
  const classes = useStyles();

  return (
    <FormikAutocompleteAsync
      {...props}
      TextFieldProps={{
        variant: "outlined",
        className: classes.textfieldBackground,
        ...props.TextFieldProps,
      }}
      dataFetcher={async (query: { CategoryGroups: CategoryGroup[] | undefined }) =>
        (await pumaApi.autocomplete.autocompleteCategoriesList(query)).data!
      }
      optionsMapper={(categoryData) => mapCategoryDataToOption(categoryData)}
      queryParams={{ CategoryGroups: props.categoryGroups }}
    />
  );
}

function mapCategoryDataToOption(categoryData: GetCategoryDataCategoryDataDto): Option<number> {
  return {
    key: categoryData.id!,
    value: categoryData.id!,
    displayValue: categoryData?.description!,
  };
}

const useStyles = makeStyles((theme) => ({
  textfieldBackground: {
    [theme.breakpoints.down("xs")]: {
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
}));
