import React from "react";
import { FormikAutocomplete, FormikAutocompleteProps } from ".";
import { FlexBox } from "../Boxes/FlexBox";
import { CustomLabel } from "./CustomLabel";
import { boolOrUndefined } from "../../utils/generics";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

export function FormikAutocompleteWithLabel<
  T,
  Multiple extends boolOrUndefined,
  DisableClearable extends boolOrUndefined,
  FreeSolo extends boolOrUndefined
>(props: FormikAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const classes = useStyles();
  return (
    <>
      <CustomLabel {...props} />
      <FlexBox alignItems="center" mt={1} mb={2} width={"100%"}>
        <FormikAutocomplete
          {...props}
          label={undefined}
          TextFieldProps={{
            variant: "outlined",
            margin: "none",
            ...props.TextFieldProps,
            className: clsx(props.className, classes.textfieldBackground),
          }}
        />
      </FlexBox>
    </>
  );
}

export function LabelWrapper(props: { label: string; children: React.ReactNode }) {
  return (
    <>
      <CustomLabel {...props} />
      <FlexBox alignItems="center" mt={1} mb={2} width={"100%"}>
        {props.children}
      </FlexBox>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  textfieldBackground: {
    [theme.breakpoints.down("xs")]: {
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
}));
