import { KeyValuePair2GuidString, KeyValuePair2Int32String } from "../api";
import { Option } from "../components";
import { CustomerTypes, EducationalInstituteType } from "./beEnumMapping";

export function mapKvpGuidStringToOption(
  kvp: KeyValuePair2GuidString,
  index?: number
): Option<string> {
  return {
    key: index ? index : 0,
    value: kvp.key!,
    displayValue: kvp.value!,
  };
}

export function mapKvpNumberStringToOption(kvp: KeyValuePair2Int32String): Option<number> {
  return {
    key: kvp.key!,
    value: kvp.key!,
    displayValue: kvp.value!,
  };
}

export function mapInterestsToPreselectedEducationalInsituteType(
  interests: number[] | null | undefined
): EducationalInstituteType | null {
  const interestsCorrelatingToEducationalInstituteTypes = interests?.filter(
    (it) =>
      it.toString() === CustomerTypes.KindergartenEducationCode ||
      it.toString() === CustomerTypes.AdultEducationCode
  );

  if (interestsCorrelatingToEducationalInstituteTypes?.length !== 1) {
    return null;
  }

  const interestToPreselect = interestsCorrelatingToEducationalInstituteTypes?.[0].toString();
  if (interestToPreselect === CustomerTypes.KindergartenEducationCode) {
    return EducationalInstituteType.Kindergarten;
  } else if (interestToPreselect === CustomerTypes.AdultEducationCode) {
    return EducationalInstituteType.AdultEducation;
  }

  return null;
}
