import React from "react";
import { makeStyles } from "@material-ui/core";
import { TestingViewMenu, TestingModeMenuProps } from "./TestingViewMenu";
import { UserTypeMenu, UserTypeMenuProps } from "./UserTypeMenu";
import { FlexBox } from "./components/Boxes/FlexBox";

type TestingModeMenusProps = TestingModeMenuProps & UserTypeMenuProps;

export function TestingModeMenus(props: TestingModeMenusProps) {
  const classes = useStyles();
  return (
    <FlexBox alignItems={"center"} className={classes.testingModeMenu}>
      <TestingViewMenu viewState={props.viewState} setViewState={props.setViewState} />
      <UserTypeMenu userType={props.userType} setUserType={props.setUserType} />
    </FlexBox>
  );
}

const useStyles = makeStyles((theme) => ({
  testingModeMenu: {
    position: "fixed",
    left: theme.spacing(5),
    top: theme.spacing(5),
    zIndex: 1000,
    gap: theme.spacing(1),
  },
}));
