import { Box, BoxProps, DrawerProps, IconButton, makeStyles, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import clsx from "clsx";
import { FlexBox } from "./Boxes/FlexBox";

interface BaseDrawerProps extends BoxProps {}

interface IDrawerHeaderProps extends Omit<BaseDrawerProps, "title"> {
  onClose?: DrawerProps["onClose"] | (() => void);
}

interface IDrawerBodyProps extends BaseDrawerProps {}

interface IDrawerContainerProps extends BaseDrawerProps {
  scroll?: "paper" | "body";
}

interface IDrawerActionsProps extends BaseDrawerProps {}

export function DrawerHeader(props: IDrawerHeaderProps) {
  const classes = useStyles();

  return (
    <FlexBox
      {...props}
      p={2}
      pl={2.5}
      alignItems={"center"}
      justifyContent={"space-between"}
      className={clsx(classes.drawerHeader, props.className)}
    >
      <Typography variant="h2" style={{ display: "flex", alignItems: "center" }}>
        {props.children}
      </Typography>
      {props.onClose && (
        <IconButton onClick={() => (props.onClose as Function)()}>
          <CloseIcon />
        </IconButton>
      )}
    </FlexBox>
  );
}

export function DrawerContainer(props: IDrawerContainerProps) {
  const classes = useStyles();
  const styles: any = {};

  if (props.scroll === "paper") {
    styles.overflowY = "scroll";
  }

  return (
    <Box {...props} className={clsx(classes.drawer, props.className)} style={styles}>
      {props.children}
    </Box>
  );
}

export function DrawerBody(props: IDrawerBodyProps) {
  const classes = useStyles();

  return <div className={clsx(classes.drawerBody, props.className)}>{props.children}</div>;
}

export function DrawerActions(props: IDrawerActionsProps) {
  const classes = useStyles();

  return (
    <FlexBox
      {...props}
      flexDirection={"row-reverse"}
      p={2}
      pr={2.5}
      alignItems={"center"}
      className={clsx(classes.drawerActions, props.className)}
    >
      {props.children}
    </FlexBox>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: theme.palette.grey[100],
    padding: theme.spacing(3),
    boxSizing: "border-box",
    minHeight: "calc(100vh - 56px)",
  },
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    minWidth: 400,
    height: "100%",
    padding: 0,
    paddingTop: 0,
    "&:first-child": {
      paddingTop: 0,
    },
  },
  drawer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  drawerHeader: {
    flex: "1 1",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    boxSizing: "border-box",
  },
  drawerBody: {
    flex: "2 2 100%",
    overflowY: "auto",
    padding: 0,
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  drawerActions: {
    flex: "1 1 ",
  },
  globalActions: {
    position: "fixed",
    zIndex: theme.zIndex.appBar,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "flex-end",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    background: theme.palette.grey[50],
  },
}));
