import React from "react";
import {ColumnFlexBox} from "../../../components/Boxes/ColumnFlexBox";
import {CustomIcon} from "../../../components/icons/CustomIcon";

export function VerifiedSchoolIcon() {
    return (
        <ColumnFlexBox alignItems={"center"} justifyContent="right">
            <CustomIcon name="Icon_Verifiziert" height={40} width={40}/>
        </ColumnFlexBox>
    );
}
