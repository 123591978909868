import React from "react";
import { CustomIcon } from "../../components/icons/CustomIcon";
import { Box, Typography } from "@material-ui/core";
import { useTranslate } from "../../utils/customHooks/useTranslate";
import { InstituteWidgetWrapper } from "../institutes/shared/InstituteWidgetWrapper";
import {ListElement} from "../../components";

export function UserRegistrationIncompleteNoSchoolLinkedWarning() {
  const t = useTranslate();
  return (
    <>
      <CustomIcon name={"Störer_Schule"} width={300} height={300} />
      <InstituteWidgetWrapper maxWidth={300} textAlign={"start"} m={0} pt={3} pb={1}>
        <Typography variant={"h3"}>{t("connectSchoolInfoHeadline")}</Typography>
        <Box component={"ul"} px={3}>
          <ListElement text={t("connectSchoolInfoBulletPoint1")} />
          <ListElement text={t("connectSchoolInfoBulletPoint2")} />
          <ListElement text={t("connectSchoolInfoBulletPoint3")} />
          <ListElement text={t("connectSchoolInfoBulletPoint4")} />
        </Box>
      </InstituteWidgetWrapper>

      <Box mt={2}>
        <Typography variant={"h3"}>{t("schoolsCanBeAddedAtAnyTime")}</Typography>
      </Box>
    </>
  );
}

