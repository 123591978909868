import { useIntl } from "react-intl";
import translationIds, { TranslationId } from "../translationIds";
import { useCallback } from "react";

export type TranslationFn = (id: TranslationId, defaultMessage?: string) => string;

export function useTranslate(): TranslationFn {
  const intl = useIntl();

  return useCallback(
    (id: TranslationId) => intl.formatMessage({ id: id, defaultMessage: translationIds[id] }),
    [intl]
  );
}
