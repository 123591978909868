import { useContext } from "react";
import { CustomFormikContextType, useCustomFormikContext } from "./useCustomFormikContext";
import { IUserRegistrationFormValues } from "../../features/registration/form/UserRegistrationFormHelper";
import { RegistrationHandler } from "./useRegistrationHandler";
import { RegistrationStepsContext } from "../../pages/UserRegistrationPage";

type useRegistrationContextReturn = {
  formik: CustomFormikContextType<IUserRegistrationFormValues>;
} & RegistrationHandler;

export function useRegistrationContext(): useRegistrationContextReturn {
  const formik = useCustomFormikContext<IUserRegistrationFormValues>();
  const registrationSteps = useContext(RegistrationStepsContext);

  return {
    formik,
    ...registrationSteps,
  };
}
