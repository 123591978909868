import { makeStyles, Theme } from "@material-ui/core";
import { CancelOutlined, Check, InfoOutlined } from "@material-ui/icons";
import React, { CSSProperties } from "react";
import { switchAssertUnreachable } from "../../utils/objectHelper";

export enum InfoIconType {
  Info,
  Approved,
  Error,
}

export type InfoIconModel = {
  show: boolean;
  infoIconType: InfoIconType;
  onClick: () => void;
};

interface Props {
  infoIcon: InfoIconModel;
  onHoverPointer?: boolean;
  marginBottom?: CSSProperties["marginBottom"];
}

export function FieldInfoIcon(props: Props) {
  const classes = useStyles({ onHoverPointer: props.onHoverPointer });

  const renderInfoIcon = () => {
    if (!props.infoIcon.show) return null;

    switch (props.infoIcon.infoIconType) {
      case InfoIconType.Info:
        return (
          <InfoOutlined
            fontSize={"large"}
            className={classes.icon}
            onClick={props.infoIcon.onClick}
            style={{ marginBottom: props.marginBottom }}
          />
        );
      case InfoIconType.Approved:
        return (
          <Check
            fontSize={"large"}
            className={classes.icon}
            onClick={props.infoIcon.onClick}
            style={{ marginBottom: props.marginBottom }}
          />
        );
      case InfoIconType.Error:
        return (
          <CancelOutlined
            fontSize={"large"}
            className={classes.icon}
            onClick={props.infoIcon.onClick}
            style={{ marginBottom: props.marginBottom }}
          />
        );
      default:
        return switchAssertUnreachable(props.infoIcon.infoIconType);
    }
  };

  return renderInfoIcon();
}

const useStyles = makeStyles<Theme, { onHoverPointer: boolean | undefined }>((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    "&:hover": {
      cursor: (props) => (props.onHoverPointer ? "pointer" : undefined),
    },
  },
}));
