import React, { useContext, useMemo, useState } from "react";
import { useCustomFormikContext } from "../../../../utils/customHooks/useCustomFormikContext";
import { IUserRegistrationFormValues } from "../../../registration/form/UserRegistrationFormHelper";
import { EducationalInstituteForm } from "./EducationalInstituteForm";
import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import { PlusTextButton } from "../../../../components/PlusTextButton";
import { useTranslate } from "../../../../utils/customHooks/useTranslate";
import { EducationalInstituteWidget } from "../../EducationalInstituteWidget/EducationalInstituteWidget";
import _ from "lodash";
import { CustomIcon } from "../../../../components/icons/CustomIcon";
import { RegistrationStepsContext } from "../../../../pages/UserRegistrationPage";

export function RegistrationAddEducationalInstitutions() {
  const formik = useCustomFormikContext<IUserRegistrationFormValues>();
  const t = useTranslate();
  const classes = useStyles();

  const [hasSelectedInstitute, setHasSelectedInstitute] = useState(false);
  const registrationStepsContext = useContext(RegistrationStepsContext);

  const instituteWidgets = useMemo(
    () => {
      const institutesToRender = formik.values.educationalInstitutions.map((x) => (
        <EducationalInstituteWidget
          institute={x}
          mb={2}
          width={"fill-available"}
          onRemoveClick={() => {
            const institutes = [...formik.values.educationalInstitutions];
            formik.setFieldValue(formik.propertyNames.educationalInstitutions, [
              ...institutes.filter((a) => a.id !== x.id),
            ]);
          }}
        />
      ));

      if (hasSelectedInstitute) institutesToRender.pop();

      return institutesToRender.length > 0 ? (
        <>
          <Box mb={2} alignSelf={"flex-start"}>
            <Typography variant={"h3"}>{t("alreadyLinkedEducationalInstitution")}</Typography>
          </Box>
          {institutesToRender}
          <Divider className={classes.divider} />
        </>
      ) : null;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasSelectedInstitute, formik.values.educationalInstitutions.length]
  );

  return (
    <>
      <Box className={classes.icon}>
        <CustomIcon name={"Bildungseinrichtung"} width={300} height={300} />
      </Box>
      {instituteWidgets}
      {!_.isNil(instituteWidgets) && (
        <Box mt={2} mb={2} alignSelf={"flex-start"}>
          <Typography variant={"h3"}>{t("addAnotherEducationalInstitution")}</Typography>
        </Box>
      )}
      <EducationalInstituteForm
        onInstituteSelect={(newVal, prevVal) => {
          setHasSelectedInstitute(true);
          const institutes = [...formik.values.educationalInstitutions];
          if (_.isNil(prevVal)) {
            institutes.push(newVal);

            formik.setFieldValue(formik.propertyNames.educationalInstitutions, institutes);

            return;
          }
          formik.setFieldValue(formik.propertyNames.educationalInstitutions, [
            ...institutes.filter((x) => x.id !== prevVal),
            newVal,
          ]);
        }}
        onRemoveClick={(instituteId) =>
          formik.setFieldValue(formik.propertyNames.educationalInstitutions, [
            ...formik.values.educationalInstitutions.filter((i) => i.id !== instituteId),
          ])
        }
        refreshFormWhenTrue={!hasSelectedInstitute}
        alreadySelectedInstituteIds={formik.values.educationalInstitutions.map((x) => x.id!)}
        alreadySelectedEducationalInstituteType={
          registrationStepsContext.preselectedEducationalInstituteType
        }
      />
      {hasSelectedInstitute && (
        <>
          <Divider className={classes.divider} />
          <PlusTextButton
            text={t("addAnotherEducationalInstitution")}
            mt={2}
            mb={2}
            onClick={() => setHasSelectedInstitute(false)}
          />
        </>
      )}
      <Divider className={classes.divider} />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  divider: { width: "100%" },
  icon: {
    position: "absolute",
    top: 50,
    left: 100,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));
