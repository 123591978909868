import { makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

type IconProps = StyleProps &
  React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
    name: string;
  };

interface StyleProps {
  width?: number;
  height?: number;
}

export function CustomIcon(props: IconProps) {
  const classes = useStyles(props);

  return (
    <img
      {...props}
      alt={"img"}
      src={`${process.env.PUBLIC_URL}/icons/${encodeURIComponent(props.name)}.svg`}
      className={clsx(classes.icon, props.className)}
    />
  );
}

//#region styles
const useStyles = makeStyles<Theme, IconProps>((theme: Theme) => ({
  icon: (props) => {
    const clickable = props.onClick
      ? {
          cursor: "pointer",
        }
      : {};

    return {
      objectFit: "contain",
      width: props.width ? props.width + "px" : "18px",
      height: props.height ? props.height + "px" : "18px",
      ...clickable,
    };
  },
}));

//#endregion
