import React from "react";
import { makeStyles, Radio, Typography } from "@material-ui/core";
import { FlexBox, FlexBoxProps } from "../../../components/Boxes/FlexBox";

type MainAssignmentRadioButtonProps = FlexBoxProps & {
  isMainAssignment: boolean;
  setAsMainAssignment: () => void;
};

export function MainAssignmentRadioButton(props: MainAssignmentRadioButtonProps) {
  const classes = useStyles();
  return (
    <FlexBox {...props} alignItems={"center"}>
      <Radio
        className={classes.radioButton}
        checked={props.isMainAssignment}
        onChange={() => !props.isMainAssignment && props.setAsMainAssignment()}
      />
      <Typography>Stammschule</Typography>
    </FlexBox>
  );
}

const useStyles = makeStyles((theme) => ({
  radioButton: {
    padding: 0,
    marginRight: theme.spacing(0.5),
  },
}));
