import { useAsync, UseAsyncOptions, UseAsyncReturn } from "react-async-hook";
import { useEffect, useRef, useState } from "react";
import { getTypedObjectKeys } from "../methodsOnObjects";
import _ from "lodash";
import { booleanString, parseBooleanString } from "../parsers";
import { useDebouncedCallback } from "use-debounce";
import useMountEffect from "./useMountEffect";

export type useAsyncQuerierProps<TQuery extends object | undefined, TResponse> = {
  asyncFunction: (query: TQuery, ...args: any[]) => Promise<TResponse>;
  initialQuery: TQuery;
  args?: any[];
  enableUrlQueryParsing?: boolean;
  debounce?: number;
  options?: UseAsyncOptions<TResponse>;
  onAfterInitialLoad?: (request: QuerierState<TQuery, TResponse>) => void;
};

export function useAsyncQuerier<TQuery extends object | undefined, TResponse>(
  props: useAsyncQuerierProps<TQuery, TResponse>,
  newQueryParams?: TQuery
) {
  const initialQueryRef = useRef(getInitialQuery(props.initialQuery!, props.enableUrlQueryParsing));

  const request = useAsync<TResponse>(
    props.asyncFunction,
    [initialQueryRef.current, props.args],
    props.options
  );
  const currentQuery = request.currentParams ? request.currentParams[0] : initialQueryRef.current;

  let state: QuerierState<TQuery, TResponse> = {
    reload: useDebouncedCallback(
      (newQuery, ...args) => {
        const query = Object.assign({}, currentQuery, newQuery);
        request.execute(query, args);
      },
      props.debounce ? props.debounce : 0
    ),
    currentQuery: currentQuery,
    shareUrl: buildShareUrl(currentQuery),
    ...request,
  };

  useNewQueryParamsResolver(newQueryParams, state);

  useOnAfterInitialLoad(state, props.onAfterInitialLoad);

  return state;
}

type BaseQuerierStateProps<TQuery extends object | undefined, TResponse> = Omit<
  UseAsyncReturn<TResponse, TQuery[]>,
  "execute"
> & {
  reload: (query?: Partial<TQuery>, ...args: any[]) => void;
  currentQuery: TQuery;
};

export type QuerierState<TQuery extends object | undefined, TResponse> = BaseQuerierStateProps<
  TQuery,
  TResponse
> & { shareUrl: string }; // showing of shareUrl should be dependent on enableUrlQueryParsing

function getInitialQuery<TQuery extends object>(
  initialQuery: TQuery,
  enableUrlQueryParsing?: boolean
) {
  if (!enableUrlQueryParsing) return initialQuery;

  const ps = new URLSearchParams(
    window.location.search === "" ? window.location.hash.split("#")[1] : window.location.search
  );

  const keys = getTypedObjectKeys(initialQuery);

  let rec: Record<keyof TQuery, string[]> = {} as Record<keyof TQuery, string[]>;
  keys.forEach((key) => (rec[key] = ps.getAll(key as string)));

  window.history.pushState({}, "", window.location.href.split("?")[0]);
  return defaultParser(rec, initialQuery);
}

function defaultParser<TQuery extends object>(
  record: Record<keyof TQuery, string[]>,
  initialQuery: TQuery
): TQuery {
  const keys = getTypedObjectKeys(record);
  let obj: TQuery = {} as TQuery;

  keys.forEach((key) => {
    const recordValue = record[key];
    if (_.isEmpty(recordValue)) {
      obj[key] = initialQuery[key];
      return;
    }

    if (!isNaN(parseFloat(recordValue[0]))) {
      if (recordValue.length > 1) {
        obj[key] = recordValue.map((rv) => parseFloat(rv)) as unknown as TQuery[typeof key];
      } else {
        obj[key] = parseFloat(recordValue[0]) as unknown as TQuery[typeof key];
      }
      return;
    }

    if (parseBooleanString(recordValue[0] as booleanString) !== undefined) {
      obj[key] = parseBooleanString(
        recordValue[0] as booleanString
      ) as unknown as TQuery[typeof key];
      return;
    }

    if (typeof recordValue[0] === "string") {
      if (recordValue.length > 1) obj[key] = recordValue as unknown as TQuery[typeof key];
      else obj[key] = recordValue[0] as unknown as TQuery[typeof key];
      return;
    }

    obj[key] = initialQuery[key];
  });

  return obj;
}

function buildShareUrl<TQuery extends object>(q: TQuery) {
  const url = window.location.href;
  const urlSep = url.indexOf("?") === -1 ? "?" : "&";
  return `${url}${urlSep}${serializeQuery(q)}`;
}

function serializeQuery<TQuery extends object>(query: TQuery) {
  let queryString = "";
  if (query === undefined) return queryString;
  const filterKeys = getTypedObjectKeys(query);

  filterKeys.forEach((key) => {
    const value = query[key];
    if (_.isNil(value)) return;
    if (Array.isArray(value)) {
      value.forEach((v) => (queryString += `&${key}=${encodeURIComponent(v)}`));
    } else {
      queryString += `&${key}=${encodeURIComponent(value as unknown as string)}`;
    }
  });

  return queryString;
}

function useNewQueryParamsResolver<TQuery extends object | undefined, TResponse>(
  newQueryParams: TQuery,
  state: QuerierState<TQuery, TResponse>
) {
  const didMount = useRef(false);
  useMountEffect(() => {
    didMount.current = true;
  });

  if (didMount && !_.isNil(newQueryParams) && !_.isEqual(state.currentQuery, newQueryParams)) {
    state.reload(newQueryParams);
  }
}

function useOnAfterInitialLoad<TQuery extends object | undefined, TResponse>(
  state: QuerierState<TQuery, TResponse>,
  onAfterInitialLoad?: useAsyncQuerierProps<TQuery, TResponse>["onAfterInitialLoad"]
) {
  const [initialLoadStarted, setInitialLoadStarted] = useState(false);
  const [initialLoadFinished, setInitialLoadFinished] = useState(false);

  useEffect(() => {
    if (initialLoadFinished || _.isNil(onAfterInitialLoad)) return;

    if (state.loading) {
      setInitialLoadStarted(true);
    }

    if (!state.loading && initialLoadStarted) {
      setInitialLoadStarted(false);
      setInitialLoadFinished(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loading]);

  useEffect(() => {
    if (!initialLoadFinished || _.isNil(onAfterInitialLoad)) return;

    onAfterInitialLoad(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoadFinished]);
}

// interface IPersistState {
//   store: "localStorage" | "sessionStorage";
//   uniqueID: string;
// }

// function usePersistState(state: QuerierState<any, any>, persistState?: IPersistState) {
//   useEffect(() => {
//     if (!persistState || !persistState.uniqueID) return;
//
//     if (persistState.store === "localStorage") {
//       localStorage.setItem(persistState.uniqueID, JSON.stringify(state));
//     } else if (persistState.store === "sessionStorage") {
//       sessionStorage.setItem(persistState.uniqueID, JSON.stringify(state));
//     }
//   }, [state]);
// }
