import { AutocompleteRenderOptionState } from "@material-ui/lab/Autocomplete/Autocomplete";
import parse from "autosuggest-highlight/parse";
import React from "react";
import match from "autosuggest-highlight/match";

const RenderOptionHighlighter = (option: string, state: AutocompleteRenderOptionState) => {
  const matches = match(option, state.inputValue);
  const parts = parse(option, matches);
  return (
    <div>
      {parts?.map((part, index) => (
        <span key={index} style={part.highlight ? { fontWeight: 700 } : {}}>
          {part.text}
        </span>
      ))}
    </div>
  );
};

// function customMatch(text: string, query: string): [] {
//   if (!query) {
//     return [];
//   }
//   const results = [];
//   const trimmedQuery = query.trim().toLowerCase();
//   const textLower = text.toLowerCase();
//   const queryLength = trimmedQuery.length;
//   let indexOf = textLower.indexOf(trimmedQuery);
//   while (indexOf > -1) {
//     results.push([indexOf, indexOf + queryLength]);
//     indexOf = textLower.indexOf(query, indexOf + queryLength);
//   }
//   //@ts-ignore
//   return results;
// }

export default RenderOptionHighlighter;
