import { FormikProps } from "formik";
import React, { useContext, useEffect, useState } from "react";
import {
  mapRegistrationInfoToUserType,
  RegistrationStepModel,
  useUserTypeRegistrationSteps,
} from "../../features/registration/helper/RegistrationHelper";
import { RegistrationStep } from "../../features/registration/enum/RegistrationStep";
import { IUserRegistrationFormValues } from "../../features/registration/form/UserRegistrationFormHelper";
import { InfoBoxData } from "../../components/InfoBox";
import { TranslationId } from "../translationIds";
import { useAsync } from "react-async-hook";
import { usePumaContextApi } from "../../api";
import { TestingUserTypeContext } from "../../App";
import { useRegistrationFinalizer } from "./useRegistrationFinalizer";
import { EducationalInstituteType, UserType } from "../beEnumMapping";
import { navigateToReturnUrlIfExists } from "../../auth";
import { mapInterestsToPreselectedEducationalInsituteType } from "../mappers";

export interface Step<FormikValues, AdditionalDataType = undefined> {
  type: RegistrationStep;
  title?: TranslationId;
  subtitle?: TranslationId;
  content: React.ReactNode;
  onAbortRegistrationTitle?: (formikProps: FormikProps<FormikValues>) => TranslationId | undefined;
  additionalData?: AdditionalDataType;
}

export interface ActiveStep<FormikType, AdditionalDataType>
  extends Step<FormikType, AdditionalDataType> {
  index: number;
  isFirstStep: boolean;
  isLastStep: boolean;
  continueToNextStep: () => void;
  returnToPreviousStep: () => void;
}

export type RegistrationHandler = {
  goToStep: (step: RegistrationStep) => void;
  activeStep: ActiveStep<IUserRegistrationFormValues, InfoBoxData>;
  steps: RegistrationStepModel[];
  loading: boolean;
  finishRegistration: (form: IUserRegistrationFormValues) => Promise<void>;
  isFinalizingRegistration: boolean;
  userType: UserType | null;
  preselectedEducationalInstituteType: EducationalInstituteType | null;
  preselectedInterests: number[];
};

export function useRegistrationHandler(): RegistrationHandler {
  const { testingUserType } = useContext(TestingUserTypeContext);

  const api = usePumaContextApi();

  const registrationInfo = useAsync(() => api.portalUsers.portalUsersRegistrationInfoList(), []);

  const userType = testingUserType ?? mapRegistrationInfoToUserType(registrationInfo.result?.data);

  const preselectedInterests =
    registrationInfo.result?.data?.customerTypes?.map((i) => Number(i)) ?? [];
  const preselectedEducationalInstituteType =
    mapInterestsToPreselectedEducationalInsituteType(preselectedInterests);

  const registrationFinalizer = useRegistrationFinalizer();

  const steps = useUserTypeRegistrationSteps(userType);

  const [activeStep, setActiveStep] = useState(getActiveStepByIndex(steps, 0));

  useEffect(() => {
    setActiveStep(getActiveStepByIndex(steps, 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationInfo.loading, testingUserType]);

  return {
    goToStep,
    activeStep,
    steps,
    loading: registrationInfo.loading,
    isFinalizingRegistration: registrationFinalizer.loading,
    finishRegistration: (form) => registrationFinalizer.execute(form),
    userType: userType,
    preselectedEducationalInstituteType: preselectedEducationalInstituteType,
    preselectedInterests: preselectedInterests,
  };

  function goToStep(step: RegistrationStep) {
    const index = steps.findIndex((x) => x.type === step);

    setActiveStep(getActiveStepByIndex(steps, index));
  }

  function getActiveStepByIndex(
    steps: RegistrationStepModel[],
    index: number
  ): ActiveStep<IUserRegistrationFormValues, InfoBoxData> {
    if (index < 0 || index >= steps.length)
      throw new Error("index out of range - getStepBasedOnIndex");

    const step = { ...steps[index], index };

    return {
      ...step,
      isFirstStep: index === 0,
      isLastStep: index === steps.length - 1,
      continueToNextStep: () => setActiveStep(getActiveStepByIndex(steps, index + 1)),
      returnToPreviousStep: () =>
        index === 0
          ? navigateToReturnUrlIfExists()
          : setActiveStep(getActiveStepByIndex(steps, index - 1)),
    };
  }
}
