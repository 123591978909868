import React, { useState } from "react";
import { PageWrapper } from "../../../components/PageWrapper";
import { useTranslate } from "../../../utils/customHooks/useTranslate";
import { ContentWrapper } from "../../../components/ContentWrapper";
import { makeStyles, Typography } from "@material-ui/core";
import { EXTENDED_NORMAL_WIDTH, MOBILE_WIDTH } from "../../../utils/layoutHelper";
import { useAsync } from "react-async-hook";
import { GetTraitDataGetTraitDataResponse, usePumaContextApi } from "../../../api";
import { PortalUserContext } from "../../PortalUserProvider";
import { getPropertyNames } from "../../../utils";
import { BoldTypography } from "../../../components/BoldTypography";
import { SubjectsDropdown } from "../../../components/Dropdowns/SubjectsDropdown";
import { InterestsDropdown } from "../../../components/Dropdowns/InterestsDropdown";
import { OccupationTitlesDropdown } from "../../../components/Dropdowns/OccupationTitlesDropdown";
import { Button } from "../../../components/Button";
import { ColumnFlexBox } from "../../../components/Boxes/ColumnFlexBox";
import { Option } from "../../../components";
import { TraitGroup } from "../../../components/Dropdowns/TraitsDropdown";
import { useAsyncQuerier } from "../../../utils/customHooks/useAsyncQuerier";
import { FunctionAtSchoolSubGroupEnum } from "../../../components/Dropdowns/FunctionsDropdown";
import { FunctionsAtSchoolDropdown } from "./FunctionsAtSchoolDropdown";
import { UserType } from "../../../utils/beEnumMapping";

export function SettingsAndNewslettersEditPage() {
  const pumaApi = usePumaContextApi();
  const t = useTranslate();
  const classes = useStyles();
  const { reload } = React.useContext(PortalUserContext);
  const { portalUser } = React.useContext(PortalUserContext);
  const request = useAsync(
    async () => pumaApi.portalUsers.portalUsersSettingsAndNewsletterList(),
    []
  );

  const result = request.result?.data;
  const init = {
    subjects: result?.subjects?.map((x) => x.id!),
    categories: result?.categories?.map((x) => x.id!),
    occupationTitle: result?.occupationTitle?.id,
    teachingExperience: undefined,
    generalFunctions: result?.generalFunctions?.map(mapTraitToOption),
    subjectCoordinatorFunctions: result?.subjectCoordinatorFunctions?.map(mapTraitToOption),
    studentTeacherFunction: result?.studentTeacherFunction
      ? mapTraitToOption(result?.studentTeacherFunction)
      : null,
  };

  interface FunctionsAtSchoolOptions {
    generalFunctionsOptions: Option<number>[];
    subjectCoordinatorFunctionsOptions: Option<number>[];
    studentTeacherFunctionOptions: Option<number>[];
  }

  const [allFunctionsAtSchool, setAllFunctionsAtSchool] = useState({} as FunctionsAtSchoolOptions);

  useAsyncQuerier({
    asyncFunction: getFunctionsAtSchool,
    options: {
      onSuccess: (options, _) => {
        setAllFunctionsAtSchool({
          generalFunctionsOptions: options
            .filter((it) => it.traitSubGroupId === FunctionAtSchoolSubGroupEnum.GeneralFunction)
            .map(mapTraitToOption),
          subjectCoordinatorFunctionsOptions: options
            .filter((it) => it.traitSubGroupId === FunctionAtSchoolSubGroupEnum.SubjectCoordinator)
            .map(mapTraitToOption),
          studentTeacherFunctionOptions: options
            .filter((it) => it.traitSubGroupId === FunctionAtSchoolSubGroupEnum.StudentTeacher)
            .map(mapTraitToOption),
        });
      },
    },
    initialQuery: {},
  });

  const names = getPropertyNames(init);

  return request.loading ? null : (
    <PageWrapper title={t("editSettings")} hideBackButton>
      <ContentWrapper hasFormik initialValues={init} className={classes.wrapper} alignItems={""}>
        {portalUser.type === UserType.Teacher && (
          <>
            <BoldTypography variant={"body2"}>{t("occupationTitle")}</BoldTypography>
            <OccupationTitlesDropdown name={names.occupationTitle} sendRequestOnChange />
            <BoldTypography variant={"body2"}>{t("functionsAtTheSchool")}</BoldTypography>
            <FunctionsAtSchoolDropdown
              options={allFunctionsAtSchool.generalFunctionsOptions}
              formikValueKey={"generalFunctions"}
              label={"functionsAtTheSchool"}
            />
            <BoldTypography variant={"body2"}>{t("subjectCoordinator")}</BoldTypography>
            <FunctionsAtSchoolDropdown
              options={allFunctionsAtSchool.subjectCoordinatorFunctionsOptions}
              formikValueKey={"subjectCoordinatorFunctions"}
              label={"subjectCoordinator"}
            />
            <BoldTypography variant={"body2"}>{t("studentTeacher")}</BoldTypography>
            <FunctionsAtSchoolDropdown
              options={allFunctionsAtSchool.studentTeacherFunctionOptions}
              formikValueKey={"studentTeacherFunction"}
              label={"studentTeacher"}
            />
          </>
        )}
        <BoldTypography variant={"body2"}>{t("subjectsOfInterest")}</BoldTypography>
        <SubjectsDropdown name={names.subjects} sendRequestOnChange />
        <BoldTypography variant={"body2"}>{t("areasOfInterest")}</BoldTypography>
        <InterestsDropdown name={names.categories} sendRequestOnChange />
        <ColumnFlexBox mt={2} justifyContent="space-between">
          <Typography variant={"caption"}>{t("autoSaveChanges")}</Typography>
          <Button onClick={() => reload()} buttonType={"back"} />
        </ColumnFlexBox>
      </ContentWrapper>
    </PageWrapper>
  );

  async function getFunctionsAtSchool(query: object | undefined, ...args: any[]) {
    return (
      (
        await pumaApi.autocomplete.autocompleteTraitsList({
          TraitGroupName: TraitGroup.Functions,
        })
      ).data ?? []
    );
  }

  function mapTraitToOption(traitDto: GetTraitDataGetTraitDataResponse): Option<number> {
    return {
      key: traitDto.id!,
      value: traitDto.id!,
      displayValue: traitDto.description!,
    };
  }
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: EXTENDED_NORMAL_WIDTH + "px",
    [theme.breakpoints.down("xs")]: {
      width: MOBILE_WIDTH + "px",
      marginTop: theme.spacing(2),
    },
  },
  buttonWrapper: {
    width: MOBILE_WIDTH + "px",
  },
  textfieldBackground: {
    [theme.breakpoints.down("xs")]: {
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
}));
