import { useContext } from "react";
import {
  RequestsFinishRegistrationRequest,
  RequestsInstituteRequest,
  usePumaContextApi,
} from "../../api";
import { navigateToReturnUrlIfExists } from "../../auth";
import { useAsync } from "react-async-hook";
import {
  AssignedSchool,
  IUserRegistrationFormValues,
} from "../../features/registration/form/UserRegistrationFormHelper";
import { ViewHandlerContext } from "../../features/ViewHandler";

export function useRegistrationFinalizer() {
  const api = usePumaContextApi();
  const { reload } = useContext(ViewHandlerContext);
  return useAsync(
    async (form: IUserRegistrationFormValues) => {
      await api.portalUsers.portalUsersFinishRegistrationUpdate(
        mapFormToFinishRegistrationRequest(form)
      );

      navigateToReturnUrlIfExists();
      await reload();
    },

    [{} as IUserRegistrationFormValues],
    {
      executeOnMount: false,
      executeOnUpdate: false,
    }
  );
}

function mapFormToFinishRegistrationRequest(
  form: IUserRegistrationFormValues
): RequestsFinishRegistrationRequest {
  return {
    basicPersonData: {
      firstName: form.firstName,
      lastName: form.lastName,
      gender: form.salutation,
      academicDegree: form.academicDegree,
      additionalAcademicDegree: form.additionalAcademicDegree,
    },
    traitIds: form.subjects.concat(form.functionsAtSchool).concat(form.coordinatedSubjects),
    categoryIds: form.interests,
    mailingConsents: {
      emailSubscribed: form.emailSubscription,
    },
    institutes: form.schools
      .concat(
        form.educationalInstitutions.map<AssignedSchool>((x) => ({
          ...x,
          verificationCode: null,
        }))
      )
      .map<RequestsInstituteRequest>((x) => ({
        instituteId: x.id,
        isMainAssignment: x.id === form.mainInstituteId,
        verificationCode: x.verificationCode,
      })),
    occupationTitleId: form.occupationTitleId,
  };
}
