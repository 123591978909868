import { TranslationId } from "../../../utils/translationIds";
import { GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponseSettingsResponseEntity } from "../../../api";
import { useTranslate } from "../../../utils/customHooks/useTranslate";
import { FlexBox } from "../../../components/Boxes/FlexBox";
import { BoldTypography } from "../../../components/BoldTypography";
import { BoxProps, Typography } from "@material-ui/core";
import React from "react";

type SettingDisplayProps = Omit<BoxProps, "children"> & {
  translationKey: TranslationId;
  setting:
    | GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponseSettingsResponseEntity
    | undefined
    | null;
};

export function SettingDisplay(props: SettingDisplayProps) {
  const t = useTranslate();

  return (
    <FlexBox mb={2}>
      <BoldTypography variant={"body2"}>{t(props.translationKey)}&nbsp;-&nbsp;</BoldTypography>
      {props.setting ? (
        <Typography variant={"body2"}>{props.setting.description}</Typography>
      ) : (
        <Typography variant={"body2"}>{t("notSelected")}</Typography>
      )}
    </FlexBox>
  );
}
