import React from "react";
import { Box, BoxProps, makeStyles } from "@material-ui/core";
import { UserBasicInfoWidgetMobile } from "./UserBasicInfoWidgetMobile";
import { UserBasicInfoWidgetNormal } from "./UserBasicInfoWidgetNormal";
import clsx from "clsx";

export function UserBasicInfoWidget(props: BoxProps) {
  const classes = useStyles();

  return (
    <Box {...props} className={clsx(classes.wrapper, props.className)} padding={2}>
      <Box className={classes.normal}>
        <UserBasicInfoWidgetNormal />
      </Box>
      <Box className={classes.mobile}>
        <UserBasicInfoWidgetMobile />
      </Box>
    </Box>
  );
}

//#region styles
const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      background: theme.palette.background.default,
      borderRadius: "8px",
      width: "100%",
      boxSizing: "border-box",

      [theme.breakpoints.down("xs")]: {
        backgroundColor: theme.palette.background.paper,
      },
    },
    normal: {
      display: "block",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    mobile: {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
  };
});

//#endregion
