import { FormikContextType, useFormikContext } from "formik";
import { usePropertyNames } from "./usePropertyNames";
import { mapPropertyNamesToValuesDeep } from "../generics";

export type CustomFormikContextType<
  T extends object,
  propertyNames = mapPropertyNamesToValuesDeep<T>
> = FormikContextType<T> & {
  propertyNames: propertyNames;
};

export function useCustomFormikContext<T extends object>(): CustomFormikContextType<T> {
  const formik = useFormikContext<T>();

  const propertyNames = usePropertyNames(formik.values);

  return {
    ...formik,
    propertyNames: propertyNames,
  };
}
