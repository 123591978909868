/* tslint:disable */
/* eslint-disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type RequestParams = Omit<RequestInit, "body" | "method"> & {
  secure?: boolean;
};

interface ApiConfig<SecurityDataType> {
  baseUrl?: string;
  baseApiParams?: RequestParams;
  securityWorker?: (securityData: SecurityDataType) => Promise<RequestParams>;
}

interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D | null;
  error: E | null;
}

enum BodyType {
  Json,
}

class HttpClient<SecurityDataType> {
  public baseUrl: string = "";
  private securityData: SecurityDataType = null as any;
  private securityWorker: null | ApiConfig<SecurityDataType>["securityWorker"] = null;

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType) => {
    this.securityData = data;
  };

  private bodyFormatters: Record<BodyType, (input: any) => any> = {
    [BodyType.Json]: JSON.stringify,
  };

  private mergeRequestOptions(
    params: RequestParams,
    securityParams?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params,
      ...(securityParams || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params.headers || {}),
        ...((securityParams && securityParams.headers) || {}),
      },
    };
  }

  private safeParseResponse = <T = any, E = any>(
    response: Response
  ): Promise<HttpResponse<T, E>> => {
    const r = response as HttpResponse<T, E>;
    r.data = null;
    r.error = null;

    return response
      .json()
      .then((data) => {
        if (r.ok) {
          r.data = data;
        } else {
          r.error = data;
        }
        return r;
      })
      .catch((e) => {
        r.error = e;
        return r;
      });
  };

  public request = async <T = any, E = any>(
    path: string,
    method: string,
    { secure, ...params }: RequestParams = {},
    body?: any,
    bodyType?: BodyType,
    secureByDefault?: boolean
  ): Promise<HttpResponse<T>> => {
    const requestUrl = `${this.baseUrl}${path}`;
    const secureOptions =
      (secureByDefault || secure) && this.securityWorker
        ? await this.securityWorker(this.securityData)
        : {};
    const requestOptions = {
      ...this.mergeRequestOptions(params, secureOptions),
      method,
      body: body ? this.bodyFormatters[bodyType || BodyType.Json](body) : null,
    };

    return fetch(requestUrl, requestOptions).then(async (response) => {
      const data = await this.safeParseResponse<T, E>(response);
      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Veritas VLS API - Common
 * @version Common
 */
export class CommonApi<SecurityDataType = any> extends HttpClient<SecurityDataType> {
  config = {
    /**
     * @tags Config
     * @name configList
     * @request GET:/api/Config
     * @secure
     */
    configList: (params?: RequestParams) =>
      this.request<any, any>(`/api/Config`, "GET", params, null, BodyType.Json, true),
  };
  i18N = {
    /**
     * @tags I18n
     * @name i18NResourcesList
     * @request GET:/api/I18n/resources
     * @secure
     */
    i18NResourcesList: (params?: RequestParams) =>
      this.request<any, any>(`/api/I18n/resources`, "GET", params, null, BodyType.Json, true),
  };
}

/**
 * React Hook to use.
 */

import * as React from "react";
import { AuthenticationContext } from "../auth";
import { UIStateContext } from "../contexts";

export function useCommonApi() {
  const auth = React.useContext(AuthenticationContext);
  const { currentCulture } = React.useContext(UIStateContext);
  const [api] = React.useState(
    new CommonApi({
      baseUrl: `${window.location.origin}`,
      securityWorker: async () => {
        let headers: object = {
          "Accept-Language": currentCulture,
        };
        if (auth.getToken != null) {
          const token = await auth.getToken();
          headers = {
            ...headers,
            Authorization: `Bearer ${token.id_token || token.access_token}`,
          };
        }
        return { headers } as RequestParams;
      },
    })
  );

  return api;
}
