import { Button as MuiButton, ButtonProps, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { CustomIcon } from "./icons/CustomIcon";

interface Props extends Omit<ButtonProps, "variant"> {
  icon?: React.ReactNode;
  buttonText: string;
}

export function ButtonWithAddIcon(props: Props) {
  const classes = useStyles();

  return (
    <MuiButton {...props}>
      <CustomIcon name="Icon_Plus" height={14} width={14} />
      <Typography className={classes.buttonText}>{props.buttonText}</Typography>
    </MuiButton>
  );
}

//#region styles
const useStyles = makeStyles((theme) => ({
  buttonText: {
    textDecoration: "underline",
    textTransform: "none",
    marginLeft: theme.spacing(),
  },
}));
//#endregion
