import { Typography, TypographyProps } from "@material-ui/core";
import React from "react";
import { UserType } from "../../../utils/beEnumMapping";
import { useTranslate } from "../../../utils/customHooks/useTranslate";
import { PortalUserContext } from "../../PortalUserProvider";

export function UserTypeText(props: TypographyProps) {
  const { portalUser } = React.useContext(PortalUserContext);

  const t = useTranslate();
  let text: string;
  switch (portalUser.type as UserType) {
    case UserType.Student:
      text = t("student");
      break;
    case UserType.Teacher:
      text = t("teacher");
      break;
    case UserType.KindergartenTeacher:
      text = t("pedagogue");
      break;
    case UserType.Other:
      text = t("client");
      break;
  }

  return <Typography {...props}>{text}</Typography>;
}
