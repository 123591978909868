import { Button as MuiButton, ButtonProps, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useCustomHistory } from "../utils/customHooks/useCustomHistory";
import _ from "lodash";
import { useTranslate } from "../utils/customHooks/useTranslate";

interface Props extends ButtonProps {
  buttonText?: string;
  buttonType?: "back";
}

export function Button(props: Props) {
  const classes = useStyles();
  const history = useCustomHistory();
  const t = useTranslate();

  return (
    <MuiButton
      variant="contained"
      color={"primary"}
      className={classes.button}
      {...props}
      disabled={props.buttonType === "back" ? !history.isGoBackAvailable : props.disabled}
      onClick={(e) => {
        if (!_.isNil(props.onClick)) props.onClick(e);
        if (props.buttonType === "back") history.goBack();
        
      }}
    >
      <Typography className={classes.text}>
        {props.buttonType === "back" ? t("back") : props.buttonText}
      </Typography>
    </MuiButton>
  );
}

//#region styles
const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
    borderRadius: 8,
    width: "100%",
  },

  text: {
    fontWeight: 500,
  },
}));
//#endregion
