import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { ColumnFlexBox, ColumnFlexBoxProps } from "../../../components/Boxes/ColumnFlexBox";
import { ResponsesInstituteResponse } from "../../../api";

type EmailsProps = ColumnFlexBoxProps & {
  institute: ResponsesInstituteResponse;
};

export function InstituteWidgetEmails(props: EmailsProps) {
  const classes = useStyles();
  return (
    <ColumnFlexBox {...props}>
      {props.institute.emails?.map((email) => (
        <Typography className={classes.email}>{email}</Typography>
      ))}
    </ColumnFlexBox>
  );
}

const useStyles = makeStyles((theme) => ({
  email: {
    textDecoration: "underline",
    overflowWrap: "break-word",
  },
}));
