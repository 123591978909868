import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { useAsync } from "react-async-hook";
import { usePumaContextApi } from "../../../api";
import { FormikRadioGroup } from "../../../components/Formik/FormikRadioGroup";
import { FormikTextFieldWithLabel } from "../../../components/Formik/FormikTextFieldWithLabel";
import { CustomIcon } from "../../../components/icons/CustomIcon";
import { useTranslate } from "../../../utils/customHooks/useTranslate";
import { useRegistrationContext } from "../../../utils/customHooks/useRegistrationContext";
import { AcademicDegreeDropdown } from "../../../components/Dropdowns/AcademicDegreeDropdown";
import { UserType } from "../../../utils/beEnumMapping";

export function RegistrationBasicInfo() {
  const t = useTranslate();
  const classes = useStyles();
  const { formik } = useRegistrationContext();

  const gendersRequest = useGendersAsync();

  const { propertyNames } = formik;

  const showAcademicDegreeDropdowns = [UserType.Teacher, UserType.Other, UserType.KindergartenTeacher].includes(
    formik.values.userType
  );

  return gendersRequest.loading ? null : (
    <>
      <Box className={classes.icon}>
        <CustomIcon name="Icon_Anrede" height={300} width={300} />
      </Box>
      <FormikRadioGroup
        name={propertyNames.salutation}
        label={t("whichSalutationShouldWeUse")}
        options={gendersRequest.options}
        {...formik}
      />
      {showAcademicDegreeDropdowns && (
        <AcademicDegreeDropdown name={propertyNames.academicDegree} type={"base"} />
      )}
      <FormikTextFieldWithLabel
        label={t("firstName")}
        showLabelAsRequired
        placeholder={t("firstName")}
        name={propertyNames.firstName}
        fullWidth
        {...formik}
      />
      <FormikTextFieldWithLabel
        label={t("lastName")}
        showLabelAsRequired
        placeholder={t("lastName")}
        name={propertyNames.lastName}
        fullWidth
        {...formik}
      />
      {showAcademicDegreeDropdowns && (
        <AcademicDegreeDropdown name={propertyNames.additionalAcademicDegree} type={"additional"} />
      )}
    </>
  );
}

//#region styles
const useStyles = makeStyles((theme) => ({
  icon: {
    position: "absolute",
    top: 50,
    left: 100,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

//#endregion

export function useGendersAsync() {
  const api = usePumaContextApi();

  const genderRequest = useAsync(() => api.autocomplete.autocompleteGendersList(), []);

  const genderOptions: Array<{ label: string; value: string }> =
    genderRequest.result?.data?.map((x) => ({ value: x.key!, label: x.value! })) || [];

  return { loading: genderRequest.loading, options: genderOptions };
}
