import React from "react";
import { OutlinedButton } from "../../../components/OutlinedButton";
import { CustomIcon } from "../../../components/icons/CustomIcon";
import { useTranslate } from "../../../utils/customHooks/useTranslate";

type MyWishlistButtonProps = {};

export function MyWishlistButton(props: MyWishlistButtonProps) {
  const t = useTranslate();
  return (
    <OutlinedButton
      buttonText={t("myNotepad")}
      onClick={() =>
        window.open("https://www.veritas.at/wishlist/")
      }
      icon={<CustomIcon name="Icon_Merkzettel" />}
    />
  );
}