import React from "react";
import { TranslationFn, useTranslate } from "./utils/customHooks/useTranslate";
import ButtonWithMenu from "./components/ButtonWithMenu";
import { makeStyles, Typography } from "@material-ui/core";
import { UserType } from "./utils/beEnumMapping";
import { assertUnreachable } from "./utils";

export type UserTypeMenuProps = {
  userType: UserType | null;
  setUserType: (ut: UserType | null) => void;
};

const userTypeArray = [
  UserType.Teacher,
  UserType.KindergartenTeacher,
  UserType.Student,
  UserType.Other,
  null,
];

export function UserTypeMenu(props: UserTypeMenuProps) {
  const classes = useStyles();
  const { setUserType, userType } = props;
  const t = useTranslate();

  return (
    <ButtonWithMenu
      title={mapUserTypeToText(userType, t)}
      items={userTypeArray.map((x) => (
        <Typography
          className={userType === x ? classes.activeView : ""}
          onClick={() => setUserType(x)}
        >
          {mapUserTypeToText(x, t)}
        </Typography>
      ))}
      buttonProps={{
        variant: "contained",
        color: userType !== null ? "secondary" : "primary",
      }}
      menuProps={{
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        transformOrigin: { vertical: "top", horizontal: "center" },
        getContentAnchorEl: null,
      }}
    />
  );
}

function mapUserTypeToText(ut: UserType | null, t: TranslationFn) {
  switch (ut) {
    case UserType.KindergartenTeacher:
      return `${t("kindergarten")} ${t("teacher")}`;
    case UserType.Other:
      return "Andere";
    case UserType.Student:
      return t("student");
    case UserType.Teacher:
      return t("teacher");
    case null:
      return "Angemeldeter Benutzer";
    default:
      assertUnreachable(ut);
  }
}

const useStyles = makeStyles((theme) => ({
  activeView: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.dark,
  },
}));
