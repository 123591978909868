import React from "react";
import { PrivateRoute } from "../auth";
import { RouteProps } from "react-router-dom";

export function AdminRoute({ component, render, ...props }: RouteProps) {
  const Component = component as any;
  return (
    <PrivateRoute
      {...props}
      render={(matchProps) => (
        <React.Fragment>
          {Component != null && <Component {...matchProps} />}
          {render != null && render(matchProps)}
        </React.Fragment>
      )}
    />
  );
}
