import React, { useMemo } from "react";
import { useTranslate } from "../../../utils/customHooks/useTranslate";
import { PageWrapper } from "../../../components/PageWrapper";
import { FullWidthDivider } from "../../../components/FullWidthDivider";
import { Box, makeStyles } from "@material-ui/core";
import { EXTENDED_NORMAL_WIDTH, MOBILE_WIDTH } from "../../../utils/layoutHelper";
import { usePumaContextApi } from "../../../api";
import { useAsync } from "react-async-hook";
import { PortalUserContext } from "../../PortalUserProvider";
import { MySettingsDisplay } from "./MySettingsDisplay";
import { EmailNewsletter } from "./EmailNewsletter";
import { Button } from "../../../components/Button";
import { PostNewsletter } from "./PostNewsletter";
import { UserType } from "../../../utils/beEnumMapping";

export function SettingsAndNewslettersPage() {
  const pumaApi = usePumaContextApi();
  const t = useTranslate();
  const classes = useStyles();
  const { portalUser } = React.useContext(PortalUserContext);

  const request = useAsync(
    async () => pumaApi.portalUsers.portalUsersSettingsAndNewsletterList(),
    []
  );

  const subjects = request.result?.data?.subjects;
  const categories = request.result?.data?.categories;
  const occupationTitle = request.result?.data?.occupationTitle;

  const generalFunctions = request.result?.data?.generalFunctions;
  const studentTeacherFunction = request.result?.data?.studentTeacherFunction;
  const subjectCoordinatorFunctions = request.result?.data?.subjectCoordinatorFunctions;

  const shouldShowSubjectsAndInterests = useMemo(
    () => portalUser.type !== UserType.Student,
    [portalUser.type]
  );

  return request.loading ? null : (
    <PageWrapper title={t("mySettingsAndNewsletter")} hideBackButton>
      {shouldShowSubjectsAndInterests && (
        <>
          <MySettingsDisplay
            subjects={subjects}
            categories={categories}
            occupationTitle={occupationTitle}
            generalFunctions={generalFunctions}
            subjectCoordinatorFunctions={subjectCoordinatorFunctions}
            studentTeacherFunction={studentTeacherFunction}
            className={classes.wrapper}
          />
          <FullWidthDivider hideOnMobile mt={2} mb={2} />
        </>
      )}
      <EmailNewsletter
        isSubscribed={request.result?.data?.emailMailingConsentSubscribed!}
        className={classes.wrapper}
        mb={2}
      />
      <PostNewsletter
        isSubscribed={request.result?.data?.postMailingConsentSubscribed!}
        className={classes.wrapper}
      />
      <Box className={classes.buttonWrapper} mt={2}>
        <Button buttonType={"back"} />
      </Box>
    </PageWrapper>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: EXTENDED_NORMAL_WIDTH + "px",
    [theme.breakpoints.down("xs")]: {
      width: MOBILE_WIDTH + "px",
      marginTop: theme.spacing(2),
    },
  },
  buttonWrapper: {
    width: MOBILE_WIDTH + "px",
  },
  wrapper2: {
    background: theme.palette.background.default,
    borderRadius: "8px",
    width: "100%",
    boxSizing: "border-box",

    [theme.breakpoints.down("xs")]: {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));
