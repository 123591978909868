import React from "react";
import { InfoPage } from "../../components/InfoPage";
import { useTranslate } from "../../utils/customHooks/useTranslate";

interface Props {
  onClose: () => void;
}

export function SchoolCodeInfo(props: Props) {
  const t = useTranslate();

  return (
    <InfoPage
      title={t("schoolCode")}
      subTitle={t("schoolCodeInfoSubtitle")}
      text={t("schoolCodeInfoText")}
      // TODO: Missing icon
      icon={"Icon_Profil_2"}
      onClose={props.onClose}
    />
  );
}
