import React from "react";
import { OutlinedButton } from "../../../components/OutlinedButton";
import { CustomIcon } from "../../../components/icons/CustomIcon";
import { useTranslate } from "../../../utils/customHooks/useTranslate";

type MyOrdersButtonProps = {};

export function MyOrdersButton(props: MyOrdersButtonProps) {
  const t = useTranslate();
  return (
    <OutlinedButton
      buttonText={t("myOrders")}
      onClick={() =>
        window.open(
          "https://www.veritas.at/sales/order/history/"
        )
      }
      icon={<CustomIcon name="Icon_Bestellung" />}
    />
  );
}
