import { makeStyles, Paper, Typography, Divider } from "@material-ui/core";
import { ErrorOutline as ErrorIcon } from "@material-ui/icons";
import React from "react";
import { Center } from "../components";
import { useTranslate } from "../utils/customHooks/useTranslate";

const useStyles = makeStyles((theme) => ({
  errorPaper: {
    maxWidth: "80%",
    padding: theme.spacing(2),
  },
  headline: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(0.25),
  },
  text: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    marginBottom: 0,
  },
}));

export function UserFetchError() {
  const classes = useStyles();
  const t = useTranslate();

  return (
    <Center>
      <Paper className={classes.errorPaper} variant="outlined">
        <div>
          <Typography
            variant="body1"
            className={classes.headline}
            style={{
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
            }}
          >
            <ErrorIcon />
            &nbsp; {t("failedToGetTheUser")}
          </Typography>
          <Typography variant="body2" className={classes.text}>
            {t("anErrorOccurredWhileGettingUser")}
          </Typography>
          <Divider />
        </div>
      </Paper>
    </Center>
  );
}
