import React from "react";
import { makeStyles } from "@material-ui/core";
import { ColumnFlexBox, ColumnFlexBoxProps } from "../../../components/Boxes/ColumnFlexBox";
import clsx from "clsx";

type InstituteWidgetWrapperProps = ColumnFlexBoxProps & {};

export function InstituteWidgetWrapper(props: InstituteWidgetWrapperProps) {
  const classes = useStyles();
  return (
    <ColumnFlexBox
      {...props}
      className={clsx(classes.wrapper, props.className)}
      bgcolor={"background.default"}
      borderRadius={8}
      padding={2}
      width={"fill-available"}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: `1px solid`,
    borderColor: theme.palette.divider,
    [theme.breakpoints.down("xs")]: {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));
