import React from "react";
import { NoAssignedSchoolError } from "./NoAssignedSchoolError";
import { FlexBox } from "../../../../components/Boxes/FlexBox";
import { AddSchoolButton } from "./AddSchoolButton";

type NoAssignedSchoolsProps = {};

export function NoAssignedSchools(props: NoAssignedSchoolsProps) {
  return (
    <>
      <NoAssignedSchoolError
        makeStyles={(theme) => ({
          [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(3),
          },
        })}
      />
      <FlexBox
        p={2}
        makeStyles={(theme) => ({
          border: `2px ${theme.palette.divider} dashed`,
          [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(3),
          },
        })}
        justifyContent={"center"}
        width={"100%"}
        mt={2}
      >
        <AddSchoolButton />
      </FlexBox>
    </>
  );
}
