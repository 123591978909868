import { Box, BoxProps } from "@material-ui/core";
import React from "react";

interface ListElementProps extends BoxProps {
  text: string;
}

export function ListElement(props: Readonly<ListElementProps>) {
  return (
    <Box component={"li"} my={0.5} sx={{ ...props.sx }}>
      {props.text}
    </Box>
  );
}
