import React, { useEffect } from "react";
import { RouteType, useCustomHistory } from "../utils/customHooks/useCustomHistory";
import { useTranslate } from "../utils/customHooks/useTranslate";
import { Box, Grid, GridProps, makeStyles } from "@material-ui/core";
import { PageNavigationButton } from "./PageNavigationButton";
import { BoldTypography } from "./BoldTypography";
import { useStateRef } from "../utils/customHooks/useStateRef";
import _ from "lodash";
import { getReturnUrlParam } from "../auth";

export type PageWrapperTitleProps = Omit<GridProps, "container" | "children"> & {
  title?: string;
  hideBackButton?: boolean;
  onBackButtonClick?: {
    callback: () => void;
    shouldNavigateBack?: false;
  };
  onClose?: () => void;
  closeRoute?: RouteType;
  onRefChange?: (ref: React.MutableRefObject<HTMLDivElement | null>) => void;
};

export function PageWrapperTitle(props: PageWrapperTitleProps) {
  const { title, hideBackButton, onClose, closeRoute, onRefChange, ...gridProps } = props;

  const titleRef = useStateRef<HTMLDivElement>();

  const classes = useStyles();
  const history = useCustomHistory();
  const t = useTranslate();

  useEffect(() => {
    onRefChange && onRefChange(titleRef);
  }, [titleRef, onRefChange]);

  const isNotNullOrEmpty = (input?: string | null) => !_.isEmpty(input) && !_.isNull(input);

  const returnUrl = getReturnUrlParam();

  return (
    <Grid container {...gridProps} className={classes.titleWrapper} ref={titleRef}>
      <Grid item xs={2}>
        {!hideBackButton && (history.isGoBackAvailable || isNotNullOrEmpty(returnUrl)) && (
          <PageNavigationButton
            iconName="Icon_Back"
            onClick={() => {
              if (_.isNil(props.onBackButtonClick)) {
                history.goBack();
                return;
              }
              props.onBackButtonClick.callback();

              if (props.onBackButtonClick.shouldNavigateBack === false) return;

              history.goBack();
            }}
            additionalText={t("back")}
          />
        )}
      </Grid>
      <Grid item xs={8}>
        <BoldTypography className={classes.title}>{title}</BoldTypography>
      </Grid>
      <Grid item xs={2}>
        {(onClose || closeRoute) && (
          <Box ml="auto" width="14px">
            <PageNavigationButton
              iconName="Icon_Close"
              onClick={() => {
                if (onClose) {
                  onClose();
                  return;
                }
                if (closeRoute) history.navigate(props.closeRoute!.route, props.closeRoute!.params);
              }}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(3),
    borderRadius: theme.spacing(),
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
      position: "fixed",
      boxShadow: "0 6px 10px rgba(0,0,0,.1)",
      zIndex: theme.zIndex.appBar,
    },
  },
  title: {
    textAlign: "center",
    fontSize: theme.typography.h1.fontSize,
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.typography.h2.fontSize,
    },
  },
  additionalText: {
    cursor: "pointer",
    textDecoration: "underline",
    textTransform: "none",
    marginLeft: theme.spacing(),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  buttonWrapper: {
    width: "200px",
    [theme.breakpoints.down("xs")]: {
      width: "50px",
    },
  },
  hoverPointer: {
    cursor: "pointer",
  },
}));
