import React, { ComponentProps, useContext, useMemo, useState } from "react";
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  Value,
} from "@material-ui/lab";
import { Chip, TextField } from "@material-ui/core";
import { AutocompleteRenderOptionState } from "@material-ui/lab/Autocomplete/Autocomplete";
import RenderOptionHighlighter from "../GeoAutocomplete/RenderOptionHighlighter";
import { FormikContext } from "formik";
import _ from "lodash";
import { boolOrUndefined } from "../../utils/generics";

export interface Option<T> {
  key: number | string;
  value: T;
  displayValue: string;
}

type CustomTextFieldProps = Omit<
  ComponentProps<typeof TextField>,
  "label" | "error" | "helperText"
>;

export type FreeSoloConditionalValue<T, FreeSolo extends boolOrUndefined> = FreeSolo extends true
  ? string
  : Option<T>;

export type FormikAutocompleteProps<
  T,
  Multiple extends boolOrUndefined,
  DisableClearable extends boolOrUndefined,
  FreeSolo extends boolOrUndefined
> = Omit<
  AutocompleteProps<FreeSoloConditionalValue<T, FreeSolo>, Multiple, DisableClearable, FreeSolo>,
  "getOptionLabel" | "renderInput"
> & {
  name: string;
  label: ComponentProps<typeof TextField>["label"];
  defaultValueMapper?: (
    options: FreeSoloConditionalValue<T, FreeSolo>[]
  ) => Value<FreeSoloConditionalValue<T, FreeSolo>, Multiple, DisableClearable, FreeSolo>;
  highlightInput?: boolean;
  TextFieldProps?:
    | CustomTextFieldProps
    | ((params: AutocompleteRenderInputParams) => CustomTextFieldProps);
  onChipClick?: (
    value: FreeSoloConditionalValue<T, FreeSolo>,
    event?: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onRemove?: (
    removedValues:
      | Value<FreeSoloConditionalValue<T, FreeSolo>, Multiple, DisableClearable, FreeSolo>
      | FreeSoloConditionalValue<T, FreeSolo>,
    reason?: Extract<AutocompleteChangeReason, "clear" | "remove-option">
  ) => boolean | Promise<boolean>;
};

export function FormikAutocomplete<
  T,
  Multiple extends boolOrUndefined,
  DisableClearable extends boolOrUndefined,
  FreeSolo extends boolOrUndefined
>(props: FormikAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  type OptionValue = FreeSoloConditionalValue<T, FreeSolo>;

  const { name, options, label, highlightInput, TextFieldProps, ...autocompleteProps } = props;
  const formik = useContext(FormikContext);
  const formikValue = _.get(formik.values, props.name) as T | T[];

  const [selectedOptionsCount, setSelectedOptionsCount] = useState(0);

  const defaultValue = useMemo(getDefaultValue, []);
  return (
    <Autocomplete
      {...autocompleteProps}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
      getOptionLabel={getOptionLabel}
      options={options}
      onChange={async (event, value, reason, details) => {
        if (props.onChange !== undefined) props.onChange(event, value, reason, details);

        if ((reason === "clear" || reason === "remove-option") && props.onRemove !== undefined) {
          let removed:
            | Value<OptionValue, Multiple, DisableClearable, FreeSolo>
            | FreeSoloConditionalValue<T, FreeSolo>;
          if (details !== undefined) {
            removed = details.option as Value<OptionValue, Multiple, DisableClearable, FreeSolo>;
          } else if (props.multiple) {
            const removedValues = (formikValue as T[]).filter(
              (v) => !(value as OptionValue[]).map((el) => toOptionMapper(el).value).includes(v)
            );

            removed = options.filter(
              (o) => removedValues.find((rv) => rv === toOptionMapper(o).value) !== undefined
            ) as Value<OptionValue, Multiple, DisableClearable, FreeSolo>;
          } else {
            removed = options.find((o) => (formikValue as T) === toOptionMapper(o)?.value)!;
          }

          const didRemove = await props.onRemove(removed, reason);
          if (didRemove) handleOptionSelect(value);
          return;
        }

        handleOptionSelect(value);
      }}
      onInputChange={(event, value, reason) => {
        if (props.onInputChange !== undefined) props.onInputChange(event, value, reason);
        handleFreeSolo(value);
      }}
      renderInput={renderInput}
      renderOption={highlightInput ? highlightInputInOptions : autocompleteProps.renderOption}
      renderTags={renderTags}
      defaultValue={defaultValue}
      getOptionSelected={getOptionSelected}
    />
  );

  function getDefaultValue() {
    if (props.defaultValueMapper) {
      return props.defaultValueMapper(options);
    }

    if (props.defaultValue) {
      return props.defaultValue;
    }

    if (!_.isNil(formikValue)) {
      return baseDefaultValueMapper(options);
    }
  }

  function baseDefaultValueMapper(
    options: OptionValue[]
  ): Value<OptionValue, Multiple, DisableClearable, FreeSolo> {
    if (props.freeSolo) {
      return formikValue as unknown as Value<OptionValue, Multiple, DisableClearable, FreeSolo>;
    }

    if (props.multiple) {
      const ret = options.filter((o) =>
        (formikValue as T[]).includes(toOptionMapper(o).value)
      ) as Value<Option<T>, true, DisableClearable, false>;

      //@ts-ignore
      return ret;
    } else {
      const ret = options.find((o) => toOptionMapper(o).value === (formikValue as T)) as
        | Value<Option<T>, false, DisableClearable, false>
        | undefined;

      //@ts-ignore
      return ret;
    }
  }

  function handleOptionSelect<T>(val: Value<OptionValue, Multiple, DisableClearable, FreeSolo>) {
    if (props.multiple) {
      handleMultipleValues(val as Value<OptionValue, true, DisableClearable, FreeSolo>);
    } else {
      handleSingleValue(val as Value<OptionValue, false, DisableClearable, FreeSolo>);
    }
  }

  function handleMultipleValues(values: Value<OptionValue, true, DisableClearable, FreeSolo>) {
    if (values?.length === 0) {
      formik.setFieldValue(props.name, undefined);
    } else {
      formik.setFieldValue(
        props.name,
        values?.map((v) => (typeof v === "string" ? (v as string) : (v as Option<T>)?.value))
      );
    }

    const count = values?.length !== undefined ? values.length : 0;
    setSelectedOptionsCount(count);
  }

  function handleSingleValue(value: Value<OptionValue, false, DisableClearable, FreeSolo>) {
    const newValue = typeof value === "string" ? value : value?.value;
    formik.setFieldValue(props.name, newValue);
  }

  function handleFreeSolo(value: string) {
    if (!props.freeSolo) return;
    props.multiple ? handleMultipleFreeSolo(value) : handleSingleFreeSolo(value);
  }

  function handleMultipleFreeSolo(value: string) {
    const formikValue = formik.values[props.name];
    if (selectedOptionsCount === 0) {
      if (_.isEmpty(value)) formik.setFieldValue(props.name, []);
      else formik.setFieldValue(props.name, [value]);
      return;
    }
    const values = [...formikValue] as string[];
    if (value === "") values.pop();
    else values[selectedOptionsCount] = value;

    formik.setFieldValue(props.name, values);
  }

  function handleSingleFreeSolo(value: string) {
    _.isEmpty(value)
      ? formik.setFieldValue(props.name, undefined)
      : formik.setFieldValue(props.name, value);
  }

  function getOptionLabel(option: OptionValue) {
    const value = toOptionMapper(option);
    return value.displayValue ?? "";
  }

  function highlightInputInOptions(option: OptionValue, state: AutocompleteRenderOptionState) {
    const value = toOptionMapper(option)?.displayValue ?? "";
    return RenderOptionHighlighter(value, state);
  }

  function errorText() {
    return formik.errors[props.name];
  }

  function hasError() {
    return errorText() != null;
  }

  function renderTags(
    values: Value<OptionValue, true, DisableClearable, FreeSolo>,
    getTagProps: ({ index }: { index: number }) => {}
  ) {
    return values.map((v, index) => {
      const value = toOptionMapper(v as OptionValue);
      return (
        <Chip
          {...getTagProps({ index })}
          key={value.key}
          label={value.displayValue}
          onClick={
            props.onChipClick !== undefined
              ? (e) => {
                  if (props.onChipClick) {
                    props.onChipClick(value as OptionValue, e);
                  }
                }
              : undefined
          }
        />
      );
    });
  }

  function toOptionMapper(val: OptionValue): Option<T> {
    return {
      key: typeof val === "string" ? (val as string) : (val as Option<T>).key,
      //@ts-ignore
      value: typeof val === "string" ? (val as string) : (val as Option<T>).value,
      displayValue: typeof val === "string" ? (val as string) : (val as Option<T>).displayValue,
    };
  }

  function getOptionSelected(option: OptionValue, value: OptionValue) {
    const val = toOptionMapper(value);
    const opt = toOptionMapper(option);
    if (val?.key === undefined) return false;
    return val.key === opt.key;
  }

  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <TextField
        {...params}
        label={label}
        error={hasError()}
        helperText={hasError() ? errorText() : undefined}
        margin={"normal"} // default in AutoComplete "dense", but standard TextField default is "normal" - overridable with TextFieldProps
        {...(typeof TextFieldProps === "function" ? TextFieldProps(params) : TextFieldProps)}
      />
    );
  }
}
