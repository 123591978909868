/* tslint:disable */
/* eslint-disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type CategoriesCategoryGroup = 1 | 2 | 3;

export interface ControllersUsersControllerUserInfo {
  cultures?: string[] | null | null;
}

export type EnumsAddressType = 1 | 2;

export interface GetCategoryDataCategoryDataDto {
  id?: number;
  description?: string | null;
  categoryGroup?: CategoriesCategoryGroup;
}

export interface GetInstituteAddressGetInstituteAddressQueryResponse {
  id?: string;
  instituteId?: string;
  addressType?: EnumsAddressType;
  country?: string | null;
  state?: string | null;
  zip?: string | null;
  city?: string | null;
  street?: string | null;
  buildingNumber?: string | null;
}

export interface GetPortalUserByIdGetPortalUserByIdQueryResponse {
  id?: string | null;
  userName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  genderCode?: string | null;
  genderDescription?: string | null;
  isFourteenOrOlder?: boolean;
  academicDegree?: number | null;
  additionalAcademicDegree?: number | null;
  addresses?: GetPortalUserByIdGetPortalUserByIdQueryResponseAddressResponse[] | null | null;
  type?: GetPortalUserByIdUserType;
  institutes?: GetPortalUserByIdGetPortalUserByIdQueryResponsePortalUserInstituteResponse[] | null | null;
}

export interface GetPortalUserByIdGetPortalUserByIdQueryResponseAddressResponse {
  id?: string;
  addressType?: EnumsAddressType;
  country?: string | null;
  state?: string | null;
  zip?: string | null;
  city?: string | null;
  street?: string | null;
  buildingNumber?: string | null;
  buildingNumberAddition?: string | null;
  district?: string | null;
  recipientName?: string | null;
  recipientHandlerName?: string | null;
}

export interface GetPortalUserByIdGetPortalUserByIdQueryResponsePortalUserInstituteResponse {
  id?: string | null;
  fullname?: string | null;
  schoolCode?: string | null;
  mainAddress?: ResponsesInstituteResponseMainAddressResponse;
  emails?: string[] | null | null;
  hasValidTeacherApprovalCode?: boolean;
  mainAssignment?: boolean;
  authorizationCodeProvided?: boolean;
}

export type GetPortalUserByIdUserType = 0 | 1 | 2 | 3;

export interface GetPortalUserRegistrationInfoGetPortalUserRegistrationInfoResponse {
  role?: string | null;
  customerTypes?: string[] | null | null;
}

export interface GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponse {
  subjects?: GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponseSettingsResponseEntity[] | null | null;
  categories?: GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponseSettingsResponseEntity[] | null | null;
  occupationTitle?: GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponseSettingsResponseEntity;
  generalFunctions?:
    | GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponseSettingsResponseEntity[]
    | null
    | null;
  subjectCoordinatorFunctions?:
    | GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponseSettingsResponseEntity[]
    | null
    | null;
  studentTeacherFunction?: GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponseSettingsResponseEntity;
  emailMailingConsentSubscribed?: boolean;
  postMailingConsentSubscribed?: boolean;
}

export interface GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponseSettingsResponseEntity {
  id?: number;
  description?: string | null;
}

export interface GetTraitDataGetTraitDataResponse {
  id?: number;
  description?: string | null;
  hasChildren?: boolean;
  traitSubGroupId?: number | null;
}

export interface KeyValuePair2GuidString {
  key?: string;
  value?: string | null;
}

export interface KeyValuePair2Int32String {
  key?: number;
  value?: string | null;
}

export interface KeyValuePair2StringString {
  key?: string | null;
  value?: string | null;
}

export type MailingConsentMailingConsentTarget = 0 | 1;

export interface MvcProblemDetails {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
}

export interface RemoveTraitRemoveTraitsRequest {
  traitIds?: number[] | null | null;
}

export interface RequestsAddInstituteToPortalUserRequest {
  instituteId?: string;
  teacherVerification?: string | null;
  isMainInstitute?: boolean;
}

export interface RequestsAddressRequest {
  addressType?: EnumsAddressType;
  country?: string | null;
  state?: string | null;
  city?: string | null;
  zip?: string | null;
  street?: string | null;
  buildingNumber?: string | null;
  buildingNumberAddition?: string | null;
  district?: string | null;
  recipientName?: string | null;
  recipientHandlerName?: string | null;
}

export interface RequestsBasicPersonDataRequest {
  firstName?: string | null;
  lastName?: string | null;
  gender?: string | null;
  academicDegree?: number | null;
  additionalAcademicDegree?: number | null;
}

export interface RequestsFinishRegistrationRequest {
  basicPersonData?: RequestsBasicPersonDataRequest;
  institutes?: RequestsInstituteRequest[] | null | null;
  traitIds?: number[] | null | null;
  categoryIds?: number[] | null | null;
  mailingConsents?: RequestsMailingConsentsRequest;
  occupationTitleId?: number | null;
}

export interface RequestsInstituteRequest {
  instituteId?: string | null;
  verificationCode?: string | null;
  isMainAssignment?: boolean;
}

export interface RequestsMailingConsentsRequest {
  emailSubscribed?: boolean;
}

export interface RequestsPortalUserInstituteRequest {
  teacherVerification?: string | null;
  isMainInstitute?: boolean | null;
}

export interface RequestsUpdatePortalUserRequest {
  firstName?: string | null;
  lastName?: string | null;
  genderId?: string | null;
  academicDegree?: number | null;
  additionalAcademicDegree?: number | null;
}

export interface ResponsesInstituteResponse {
  id?: string | null;
  fullname?: string | null;
  schoolCode?: string | null;
  mainAddress?: ResponsesInstituteResponseMainAddressResponse;
  emails?: string[] | null | null;
  hasValidTeacherApprovalCode?: boolean;
}

export interface ResponsesInstituteResponseMainAddressResponse {
  addressType?: EnumsAddressType;
  country?: string | null;
  state?: string | null;
  zip?: string | null;
  city?: string | null;
  street?: string | null;
  buildingNumber?: string | null;
}

export type SharedEducationalInstituteCountry = 0 | 1 | 2 | 3 | 4;

export type SharedEducationalInstituteType = 101 | 115;

export interface UpdateOccupationTitleUpdateOccupationTitleRequest {
  occupationTitleId?: number | null;
}

export type ValueTuple2BooleanString = object;

type RequestParams = Omit<RequestInit, "body" | "method"> & {
  secure?: boolean;
};

type RequestQueryParamsType = Record<string | number, any>;

interface ApiConfig<SecurityDataType> {
  baseUrl?: string;
  baseApiParams?: RequestParams;
  securityWorker?: (securityData: SecurityDataType) => Promise<RequestParams>;
}

interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D | null;
  error: E | null;
}

enum BodyType {
  Json,
}

class HttpClient<SecurityDataType> {
  public baseUrl: string = "";
  private securityData: SecurityDataType = null as any;
  private securityWorker: null | ApiConfig<SecurityDataType>["securityWorker"] = null;

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType) => {
    this.securityData = data;
  };

  private addQueryParam(query: RequestQueryParamsType, key: string) {
    let queryParam = encodeURIComponent(key) + "=";
    if (Array.isArray(query[key])) {
      queryParam += query[key].reduce(
        (accumulator: string, currVal: string) =>
          (accumulator += `&${encodeURIComponent(key)}=${encodeURIComponent(currVal)}`),
      );
    } else {
      queryParam += query[key];
    }
    return queryParam;
  }

  protected addQueryParams(rawQuery?: RequestQueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys.length
      ? `?${keys
          .map((key) =>
            typeof query[key] === "object" && !Array.isArray(query[key])
              ? this.addQueryParams(query[key] as object).substring(1)
              : this.addQueryParam(query, key),
          )
          .join("&")}`
      : "";
  }

  private bodyFormatters: Record<BodyType, (input: any) => any> = {
    [BodyType.Json]: JSON.stringify,
  };

  private mergeRequestOptions(params: RequestParams, securityParams?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params,
      ...(securityParams || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params.headers || {}),
        ...((securityParams && securityParams.headers) || {}),
      },
    };
  }

  private safeParseResponse = <T = any, E = any>(response: Response): Promise<HttpResponse<T, E>> => {
    const r = response as HttpResponse<T, E>;
    r.data = null;
    r.error = null;

    return response
      .json()
      .then((data) => {
        if (r.ok) {
          r.data = data;
        } else {
          r.error = data;
        }
        return r;
      })
      .catch((e) => {
        r.error = e;
        return r;
      });
  };

  public request = async <T = any, E = any>(
    path: string,
    method: string,
    { secure, ...params }: RequestParams = {},
    body?: any,
    bodyType?: BodyType,
    secureByDefault?: boolean,
  ): Promise<HttpResponse<T>> => {
    const requestUrl = `${this.baseUrl}${path}`;
    const secureOptions =
      (secureByDefault || secure) && this.securityWorker ? await this.securityWorker(this.securityData) : {};
    const requestOptions = {
      ...this.mergeRequestOptions(params, secureOptions),
      method,
      body: body ? this.bodyFormatters[bodyType || BodyType.Json](body) : null,
    };

    return fetch(requestUrl, requestOptions).then(async (response) => {
      const data = await this.safeParseResponse<T, E>(response);
      if (!response.ok) throw data;
      return data;
    });
  };
}
/**
 * @title Veritas VLS API - Puma
 * @version PumaContext
 */
export class PumaContextApi<SecurityDataType = any> extends HttpClient<SecurityDataType> {
  autocomplete = {
    /**
     * @tags Autocomplete
     * @name autocompleteInstitutesList
     * @request GET:/api/autocomplete/institutes
     * @secure
     */
    autocompleteInstitutesList: (
      query?: { Name?: string; Skz?: string; City?: string; Street?: string; Zip?: string },
      params?: RequestParams,
    ) =>
      this.request<KeyValuePair2GuidString[], MvcProblemDetails>(
        `/api/autocomplete/institutes${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Autocomplete
     * @name autocompletePeopleList
     * @request GET:/api/autocomplete/people
     * @secure
     */
    autocompletePeopleList: (query?: { CustomerNumber?: number; Zip?: string }, params?: RequestParams) =>
      this.request<KeyValuePair2GuidString[], MvcProblemDetails>(
        `/api/autocomplete/people${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Autocomplete
     * @name autocompleteDepartmentsList
     * @request GET:/api/autocomplete/departments
     * @secure
     */
    autocompleteDepartmentsList: (params?: RequestParams) =>
      this.request<KeyValuePair2Int32String[], MvcProblemDetails>(
        `/api/autocomplete/departments`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Autocomplete
     * @name autocompleteAcademicDegreesList
     * @request GET:/api/autocomplete/academicDegrees
     * @secure
     */
    autocompleteAcademicDegreesList: (query?: { Additional?: boolean }, params?: RequestParams) =>
      this.request<KeyValuePair2Int32String[], MvcProblemDetails>(
        `/api/autocomplete/academicDegrees${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Autocomplete
     * @name autocompleteGendersList
     * @request GET:/api/autocomplete/genders
     * @secure
     */
    autocompleteGendersList: (params?: RequestParams) =>
      this.request<KeyValuePair2StringString[], MvcProblemDetails>(
        `/api/autocomplete/genders`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Autocomplete
     * @name autocompleteOccupationTitlesList
     * @request GET:/api/autocomplete/occupationTitles
     * @secure
     */
    autocompleteOccupationTitlesList: (params?: RequestParams) =>
      this.request<KeyValuePair2Int32String[], MvcProblemDetails>(
        `/api/autocomplete/occupationTitles`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Autocomplete
     * @name autocompleteCommunicationCodesList
     * @request GET:/api/autocomplete/communicationCodes
     * @secure
     */
    autocompleteCommunicationCodesList: (params?: RequestParams) =>
      this.request<KeyValuePair2Int32String[], MvcProblemDetails>(
        `/api/autocomplete/communicationCodes`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Autocomplete
     * @name autocompleteOrderInformationList
     * @request GET:/api/autocomplete/orderInformation
     * @secure
     */
    autocompleteOrderInformationList: (params?: RequestParams) =>
      this.request<KeyValuePair2Int32String[], MvcProblemDetails>(
        `/api/autocomplete/orderInformation`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Autocomplete
     * @name autocompleteSchoolTypesList
     * @request GET:/api/autocomplete/schoolTypes
     * @secure
     */
    autocompleteSchoolTypesList: (params?: RequestParams) =>
      this.request<KeyValuePair2Int32String[], MvcProblemDetails>(
        `/api/autocomplete/schoolTypes`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Autocomplete
     * @name autocompletePropertyGroupsList
     * @request GET:/api/autocomplete/propertyGroups
     * @secure
     */
    autocompletePropertyGroupsList: (params?: RequestParams) =>
      this.request<KeyValuePair2Int32String[], MvcProblemDetails>(
        `/api/autocomplete/propertyGroups`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Autocomplete
     * @name autocompleteCategoriesList
     * @request GET:/api/autocomplete/categories
     * @secure
     */
    autocompleteCategoriesList: (query?: { CategoryGroups?: CategoriesCategoryGroup[] }, params?: RequestParams) =>
      this.request<GetCategoryDataCategoryDataDto[], MvcProblemDetails>(
        `/api/autocomplete/categories${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Autocomplete
     * @name autocompleteDeactivationCommentsList
     * @request GET:/api/autocomplete/deactivationComments
     * @secure
     */
    autocompleteDeactivationCommentsList: (params?: RequestParams) =>
      this.request<KeyValuePair2Int32String[], MvcProblemDetails>(
        `/api/autocomplete/deactivationComments`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Autocomplete
     * @name autocompleteApprovalTypesList
     * @request GET:/api/autocomplete/approvalTypes
     * @secure
     */
    autocompleteApprovalTypesList: (params?: RequestParams) =>
      this.request<KeyValuePair2Int32String[], MvcProblemDetails>(
        `/api/autocomplete/approvalTypes`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Autocomplete
     * @name autocompleteSubscriptionTypesList
     * @request GET:/api/autocomplete/subscriptionTypes
     * @secure
     */
    autocompleteSubscriptionTypesList: (params?: RequestParams) =>
      this.request<KeyValuePair2Int32String[], MvcProblemDetails>(
        `/api/autocomplete/subscriptionTypes`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Autocomplete
     * @name autocompleteTitlesList
     * @request GET:/api/autocomplete/titles
     * @secure
     */
    autocompleteTitlesList: (query?: { Name?: string }, params?: RequestParams) =>
      this.request<KeyValuePair2Int32String[], MvcProblemDetails>(
        `/api/autocomplete/titles${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Autocomplete
     * @name autocompleteTraitsList
     * @request GET:/api/autocomplete/traits
     * @secure
     */
    autocompleteTraitsList: (query?: { TraitGroupName?: string; ParentId?: number }, params?: RequestParams) =>
      this.request<GetTraitDataGetTraitDataResponse[], MvcProblemDetails>(
        `/api/autocomplete/traits${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),
  };
  institutes = {
    /**
     * @tags Institutes
     * @name institutesAddressesDetail
     * @request GET:/api/institutes/{instituteId}/addresses/{addressId}
     * @secure
     */
    institutesAddressesDetail: (instituteId: string, addressId: string, params?: RequestParams) =>
      this.request<GetInstituteAddressGetInstituteAddressQueryResponse, MvcProblemDetails>(
        `/api/institutes/${instituteId}/addresses/${addressId}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Institutes
     * @name institutesBySchoolCodeList
     * @request GET:/api/institutes/bySchoolCode
     * @secure
     */
    institutesBySchoolCodeList: (query?: { SchoolCode?: string }, params?: RequestParams) =>
      this.request<ResponsesInstituteResponse, MvcProblemDetails>(
        `/api/institutes/bySchoolCode${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Institutes
     * @name institutesValidateTeacherApprovalCodeList
     * @request GET:/api/institutes/validateTeacherApprovalCode
     * @secure
     */
    institutesValidateTeacherApprovalCodeList: (
      query?: { TeacherApprovalCode?: string; InstituteId?: string },
      params?: RequestParams,
    ) =>
      this.request<boolean, MvcProblemDetails>(
        `/api/institutes/validateTeacherApprovalCode${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Institutes
     * @name institutesEducationalInstitutesList
     * @request GET:/api/institutes/educationalInstitutes
     * @secure
     */
    institutesEducationalInstitutesList: (
      query?: {
        EducationalInstituteCountry?: SharedEducationalInstituteCountry;
        Zip?: string;
        InstituteType?: SharedEducationalInstituteType;
      },
      params?: RequestParams,
    ) =>
      this.request<ResponsesInstituteResponse[], MvcProblemDetails>(
        `/api/institutes/educationalInstitutes${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Institutes
     * @name institutesDetail
     * @request GET:/api/institutes/{instituteId}
     * @secure
     */
    institutesDetail: (instituteId: string, params?: RequestParams) =>
      this.request<ResponsesInstituteResponse, MvcProblemDetails>(
        `/api/institutes/${instituteId}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Institutes
     * @name institutesAddressesCreate
     * @request POST:/api/institutes/{instituteId}/addresses
     * @secure
     */
    institutesAddressesCreate: (instituteId: string, data: RequestsAddressRequest, params?: RequestParams) =>
      this.request<any, MvcProblemDetails>(
        `/api/institutes/${instituteId}/addresses`,
        "POST",
        params,
        data,
        BodyType.Json,
        true,
      ),
  };
  portalUsers = {
    /**
     * @tags PortalUsers
     * @name portalUsersList
     * @request GET:/api/portalUsers
     * @secure
     */
    portalUsersList: (params?: RequestParams) =>
      this.request<GetPortalUserByIdGetPortalUserByIdQueryResponse, MvcProblemDetails>(
        `/api/portalUsers`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags PortalUsers
     * @name portalUsersUpdate
     * @request PUT:/api/portalUsers
     * @secure
     */
    portalUsersUpdate: (data: RequestsUpdatePortalUserRequest, params?: RequestParams) =>
      this.request<any, MvcProblemDetails>(`/api/portalUsers`, "PUT", params, data, BodyType.Json, true),

    /**
     * @tags PortalUsers
     * @name portalUsersIsRegisteredList
     * @request GET:/api/portalUsers/isRegistered
     * @secure
     */
    portalUsersIsRegisteredList: (params?: RequestParams) =>
      this.request<boolean, MvcProblemDetails>(
        `/api/portalUsers/isRegistered`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags PortalUsers
     * @name portalUsersRegistrationInfoList
     * @request GET:/api/portalUsers/registrationInfo
     * @secure
     */
    portalUsersRegistrationInfoList: (params?: RequestParams) =>
      this.request<GetPortalUserRegistrationInfoGetPortalUserRegistrationInfoResponse, MvcProblemDetails>(
        `/api/portalUsers/registrationInfo`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags PortalUsers
     * @name portalUsersSettingsAndNewsletterList
     * @request GET:/api/portalUsers/settingsAndNewsletter
     * @secure
     */
    portalUsersSettingsAndNewsletterList: (params?: RequestParams) =>
      this.request<GetSettingsAndNewsletterDataGetSettingsAndNewsletterDataResponse, MvcProblemDetails>(
        `/api/portalUsers/settingsAndNewsletter`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags PortalUsers
     * @name portalUsersGetByUserTypeList
     * @request GET:/api/portalUsers/getByUserType
     * @secure
     */
    portalUsersGetByUserTypeList: (query?: { UserType?: GetPortalUserByIdUserType }, params?: RequestParams) =>
      this.request<GetPortalUserByIdGetPortalUserByIdQueryResponse, MvcProblemDetails>(
        `/api/portalUsers/getByUserType${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags PortalUsers
     * @name portalUsersInstitutesCreate
     * @request POST:/api/portalUsers/institutes
     * @secure
     */
    portalUsersInstitutesCreate: (data: RequestsAddInstituteToPortalUserRequest, params?: RequestParams) =>
      this.request<any, MvcProblemDetails>(`/api/portalUsers/institutes`, "POST", params, data, BodyType.Json, true),

    /**
     * @tags PortalUsers
     * @name portalUsersAddressesCreate
     * @request POST:/api/portalUsers/addresses
     * @secure
     */
    portalUsersAddressesCreate: (data: RequestsAddressRequest, params?: RequestParams) =>
      this.request<any, MvcProblemDetails>(`/api/portalUsers/addresses`, "POST", params, data, BodyType.Json, true),

    /**
     * @tags PortalUsers
     * @name portalUsersAddTraitCreate
     * @request POST:/api/portalUsers/addTrait/{traitDataId}
     * @secure
     */
    portalUsersAddTraitCreate: (traitDataId: number, params?: RequestParams) =>
      this.request<any, MvcProblemDetails>(
        `/api/portalUsers/addTrait/${traitDataId}`,
        "POST",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags PortalUsers
     * @name portalUsersUpdateOccupationTitleUpdate
     * @request PUT:/api/portalUsers/updateOccupationTitle
     * @secure
     */
    portalUsersUpdateOccupationTitleUpdate: (
      data: UpdateOccupationTitleUpdateOccupationTitleRequest,
      params?: RequestParams,
    ) =>
      this.request<boolean, MvcProblemDetails>(
        `/api/portalUsers/updateOccupationTitle`,
        "PUT",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags PortalUsers
     * @name portalUsersInstitutesUpdate
     * @request PUT:/api/portalUsers/institutes/{instituteId}
     * @secure
     */
    portalUsersInstitutesUpdate: (
      instituteId: string,
      data: RequestsPortalUserInstituteRequest,
      params?: RequestParams,
    ) =>
      this.request<any, MvcProblemDetails>(
        `/api/portalUsers/institutes/${instituteId}`,
        "PUT",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags PortalUsers
     * @name portalUsersInstitutesDelete
     * @request DELETE:/api/portalUsers/institutes/{instituteId}
     * @secure
     */
    portalUsersInstitutesDelete: (instituteId: string, params?: RequestParams) =>
      this.request<any, MvcProblemDetails>(
        `/api/portalUsers/institutes/${instituteId}`,
        "DELETE",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags PortalUsers
     * @name portalUsersFinishRegistrationUpdate
     * @request PUT:/api/portalUsers/finishRegistration
     * @secure
     */
    portalUsersFinishRegistrationUpdate: (data: RequestsFinishRegistrationRequest, params?: RequestParams) =>
      this.request<any, MvcProblemDetails>(
        `/api/portalUsers/finishRegistration`,
        "PUT",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags PortalUsers
     * @name portalUsersAddressesUpdate
     * @request PUT:/api/portalUsers/addresses/{addressId}
     * @secure
     */
    portalUsersAddressesUpdate: (addressId: string, data: RequestsAddressRequest, params?: RequestParams) =>
      this.request<any, MvcProblemDetails>(
        `/api/portalUsers/addresses/${addressId}`,
        "PUT",
        params,
        data,
        BodyType.Json,
        true,
      ),

    /**
     * @tags PortalUsers
     * @name portalUsersAddressesDelete
     * @request DELETE:/api/portalUsers/addresses/{addressId}
     * @secure
     */
    portalUsersAddressesDelete: (addressId: string, params?: RequestParams) =>
      this.request<any, MvcProblemDetails>(
        `/api/portalUsers/addresses/${addressId}`,
        "DELETE",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags PortalUsers
     * @name portalUsersAddressesSetAsMainUpdate
     * @request PUT:/api/portalUsers/addresses/{addressId}/setAsMain
     * @secure
     */
    portalUsersAddressesSetAsMainUpdate: (addressId: string, params?: RequestParams) =>
      this.request<any, MvcProblemDetails>(
        `/api/portalUsers/addresses/${addressId}/setAsMain`,
        "PUT",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags PortalUsers
     * @name portalUsersCategoriesUpdate
     * @request PUT:/api/portalUsers/categories
     * @secure
     */
    portalUsersCategoriesUpdate: (query?: { CategoryIds?: number[] }, params?: RequestParams) =>
      this.request<any, MvcProblemDetails>(
        `/api/portalUsers/categories${this.addQueryParams(query)}`,
        "PUT",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags PortalUsers
     * @name portalUsersMailingConsentUpdate
     * @request PUT:/api/portalUsers/mailingConsent
     * @secure
     */
    portalUsersMailingConsentUpdate: (
      query?: { Target?: MailingConsentMailingConsentTarget; Subscribed?: boolean },
      params?: RequestParams,
    ) =>
      this.request<any, MvcProblemDetails>(
        `/api/portalUsers/mailingConsent${this.addQueryParams(query)}`,
        "PUT",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags PortalUsers
     * @name portalUsersRemoveTraitsDelete
     * @request DELETE:/api/portalUsers/removeTraits
     * @secure
     */
    portalUsersRemoveTraitsDelete: (data: RemoveTraitRemoveTraitsRequest, params?: RequestParams) =>
      this.request<any, MvcProblemDetails>(
        `/api/portalUsers/removeTraits`,
        "DELETE",
        params,
        data,
        BodyType.Json,
        true,
      ),
  };
  unsubscribe = {
    /**
     * @tags Unsubscription
     * @name unsubscribeCanUnsubscribeList
     * @request GET:/api/unsubscribe/canUnsubscribe
     * @secure
     */
    unsubscribeCanUnsubscribeList: (query?: { value?: string }, params?: RequestParams) =>
      this.request<ValueTuple2BooleanString, MvcProblemDetails>(
        `/api/unsubscribe/canUnsubscribe${this.addQueryParams(query)}`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),

    /**
     * @tags Unsubscription
     * @name unsubscribeCreate
     * @request POST:/api/unsubscribe
     * @secure
     */
    unsubscribeCreate: (query?: { value?: string }, params?: RequestParams) =>
      this.request<any, MvcProblemDetails>(
        `/api/unsubscribe${this.addQueryParams(query)}`,
        "POST",
        params,
        null,
        BodyType.Json,
        true,
      ),
  };
  iam = {
    /**
     * @tags Users
     * @name iamUsersVerifyList
     * @request GET:/api/iam/users/verify
     * @secure
     */
    iamUsersVerifyList: (params?: RequestParams) =>
      this.request<ControllersUsersControllerUserInfo, any>(
        `/api/iam/users/verify`,
        "GET",
        params,
        null,
        BodyType.Json,
        true,
      ),
  };
}

/**
 * React Hook to use.
 */

import * as React from "react";
import { AuthenticationContext } from "../auth";
import { UIStateContext } from "../contexts";

export function usePumaContextApi() {
  const auth = React.useContext(AuthenticationContext);
  const { currentCulture } = React.useContext(UIStateContext);
  const [api] = React.useState(
    new PumaContextApi({
      baseUrl: `${window.apiUrl}`,
      securityWorker: async () => {
        let headers: object = {
          "Accept-Language": currentCulture,
        };
        if (auth.getToken != null) {
          const token = await auth.getToken();
          headers = {
            ...headers,
            Authorization: `Bearer ${token.id_token || token.access_token}`,
          };
        }
        return { headers } as RequestParams;
      },
    }),
  );

  return api;
}
