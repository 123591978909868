import React from "react";
import ButtonWithMenu from "./components/ButtonWithMenu";
import { makeStyles, Typography } from "@material-ui/core";
import { TranslationFn, useTranslate } from "./utils/customHooks/useTranslate";
import { assertUnreachable } from "./utils";

export type TestingModeMenuProps = {
  viewState: TestingView;
  setViewState: (s: TestingView) => void;
};

export enum TestingView {
  Registration,
  Profile,
  Inactive,
}

const testingViewEnumArray = [TestingView.Registration, TestingView.Profile, TestingView.Inactive];

export function TestingViewMenu(props: TestingModeMenuProps) {
  const classes = useStyles();
  const { setViewState, viewState } = props;
  const t = useTranslate();

  return (
    <ButtonWithMenu
      title={translateViewState(viewState, t)}
      items={testingViewEnumArray.map((x) => (
        <Typography
          className={viewState === x ? classes.activeView : ""}
          onClick={() => setViewState(x)}
        >
          {translateViewState(x, t)}
        </Typography>
      ))}
      buttonProps={{
        variant: "contained",
        color: viewState === TestingView.Inactive ? "primary" : "secondary",
      }}
      menuProps={{
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        transformOrigin: { vertical: "top", horizontal: "center" },
        getContentAnchorEl: null,
      }}
    />
  );
}

function translateViewState(viewState: TestingView, t: TranslationFn) {
  switch (viewState) {
    case TestingView.Inactive:
      return t("inactive");
    case TestingView.Profile:
      return "Profile";
    case TestingView.Registration:
      return "Registration";
    default:
      assertUnreachable(viewState);
  }
}

const useStyles = makeStyles((theme) => ({
  activeView: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.dark,
  },
}));
