import { useRef, useState } from "react";
import _ from "lodash";

export function useRandomKeygen<T>(newValue: T) {
  let oldValue = useRef(newValue);
  const [key, setKey] = useState(runKeygen());

  if (!_.isEqual(oldValue.current, newValue)) {
    resetKey();
  }

  return { key, resetKey };

  function resetKey() {
    oldValue.current = newValue;
    setKey(runKeygen());
  }

  function runKeygen() {
    return Math.round(Math.random() * 1000000);
  }
}
